import React, { useEffect } from 'react';

import _sum from 'lodash/sum';
import { v4 as uuidV4 } from 'uuid';

import { GoogleMapsAddress } from '@travauxlib/shared/src/types/api/common/GoogleMapsAddress';
import { roundToTwoDecimals } from '@travauxlib/shared/src/utils/format';
import { perimetreQuantityComputer } from '@travauxlib/shared/src/utils/quantityComputers';

import { useEstimateurTracking } from 'features/Estimateur/hooks/useEstimateurTracking';
import { Answer, Estimation } from 'features/Estimateur/types';

import { FormValues } from './components/ConfigurationForm';
import { NewRoutes } from './routes';

import { Body } from '../../components/Body';
import { EstimateurEventAction } from '../../utils/constants';
import { useEstimateurSource, useIsEstimateurPartner } from '../../utils/paths';

type Props = {
  submitInitialConfiguration: (estimation: Estimation) => void;
  estimation?: Estimation;
  setUserAddress?: (address: GoogleMapsAddress) => void;
  isTrackingEnabled?: boolean;
};

export const EstimateurStarterComponent: React.FC<Props> = ({
  submitInitialConfiguration,
  setUserAddress,
  isTrackingEnabled,
  estimation,
}) => {
  const doTracking = useEstimateurTracking();
  const isEstimateurPartner = useIsEstimateurPartner();
  const source = useEstimateurSource();

  useEffect(() => {
    if (isTrackingEnabled) {
      doTracking(EstimateurEventAction.Started);
    }
  }, []);

  const handleSubmitConfiguration = (values: FormValues): void => {
    if (isTrackingEnabled) {
      doTracking(EstimateurEventAction.Result);
    }

    const locations = values.locations.map(location => {
      const perimetre = perimetreQuantityComputer(location);
      return {
        ...location,
        longueurMurs: perimetre,
        surfaceMurs: values.hauteurMur * perimetre,
      };
    });
    const surfaceTotale = roundToTwoDecimals(_sum(locations.map(l => l.surface)));

    let answers: Answer[] = [];
    if (estimation && estimation.answers) {
      answers = estimation.answers.reduce((acc, answer) => {
        if (answer.location) {
          if (locations.some(({ uuid }) => uuid === answer.location)) {
            return [...acc, answer];
          }
          return acc;
        }

        if (answer.locations) {
          const filteredLocations = answer.locations.filter(uuid =>
            locations.some(location => location.uuid === uuid),
          );
          if (filteredLocations.length) {
            return [
              ...acc,
              {
                ...answer,
                locations: filteredLocations,
              },
            ];
          }
          return acc;
        }
        if (answer.prestationsByLocation) {
          return [
            ...acc,
            {
              ...answer,
              prestationsByLocation: answer.prestationsByLocation.filter(({ uuid }) =>
                locations.some(location => location.uuid === uuid),
              ),
            },
          ];
        }
        return [...acc, answer];
      }, [] as Answer[]);
    }

    const { firstName, lastName, googleMapsAddress, email, phoneNumber, defaultGamme, hauteurMur } =
      values;

    submitInitialConfiguration({
      firstName,
      lastName,
      googleMapsAddress: estimation?.googleMapsAddress || googleMapsAddress,
      email,
      phoneNumber,
      uuid: estimation ? estimation.uuid : uuidV4(),
      context: {
        typeLogement: 'maison',
        locations,
        surfaceTotale,
        hauteurMur,
        defaultGamme: defaultGamme || 'confort',
        deletedLinesIdentifiers: estimation?.context.deletedLinesIdentifiers || [],
      },
      answers,
      source,
    });
  };

  return (
    <div className="mx-auto w-full">
      <Body className="container-sm w-full my-xl">
        <NewRoutes
          isEstimateurPartner={isEstimateurPartner}
          estimation={estimation}
          handleSubmitConfiguration={handleSubmitConfiguration}
          setUserAddress={setUserAddress}
        />
      </Body>
    </div>
  );
};

export const EstimateurStarter = EstimateurStarterComponent;
