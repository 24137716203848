import { useMutation } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { FournitureReference } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

export type ScrapFournitureInfoPayload = {
  url: string;
  projectUuid: string;
};

export const handleScrapFournitureInfo = async ({
  url,
  projectUuid,
}: ScrapFournitureInfoPayload): Promise<FournitureReference> => {
  const requestURL = `${APP_CONFIG.apiURL}/client/projects/${projectUuid}/scrap-fourniture`;

  return await request(requestURL, {
    method: 'POST',
    body: { url },
  });
};

export const useScrapFournitureInfo = (
  projectUuid: string,
): ((url: String) => Promise<FournitureReference>) => {
  const { mutateAsync: scrapFournitureInfo } = useMutation({
    mutationFn: (url: string) => handleScrapFournitureInfo({ url, projectUuid }),
    onError: () => {
      toast.error("Les informations pour cette fourniture n'ont pas pu être récupérées");
    },
  });
  return scrapFournitureInfo;
};
