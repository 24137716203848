import { v4 as uuidV4 } from 'uuid';

import { DevisLocation } from '@travauxlib/shared/src/types/api/domain/prodevisfactures/DevisLocation';

export const indexedLabel = (label: string, index: number): string => `${label} ${index}`;

export const getLocationIndex = (location: DevisLocation): null | number => {
  const match = location.label.match(/^(.+?) ?(\d+)?$/);
  if (!match) {
    return null;
  }
  if (location.typeLocation !== match[1]) {
    return null;
  }
  if (!match[2]) {
    return 1;
  }
  return parseInt(match[2], 10);
};

export const buildLabel = (defaultLabel: string, existingLabels: string[]): string => {
  if (
    !existingLabels.includes(defaultLabel) &&
    !existingLabels.includes(indexedLabel(defaultLabel, 1))
  ) {
    return defaultLabel;
  }

  let index = 2;
  let locationLabel = indexedLabel(defaultLabel, index);

  while (existingLabels.includes(locationLabel)) {
    index += 1;
    locationLabel = indexedLabel(defaultLabel, index);
  }
  return locationLabel;
};

export const addLocation = (
  location: DevisLocation,
  locations: DevisLocation[],
): DevisLocation[] => {
  const existingLocations = locations.map(({ label, ...rest }) => ({
    ...rest,
    label: label === location.label ? indexedLabel(label, 1) : label,
  }));

  const existingLabels = existingLocations.map(({ label }) => label);

  const lastLocationWithHauteur = existingLocations
    .filter(location => location.hauteurSousPlafond > 0)
    .pop();

  const newLocation = {
    ...location,
    typeLocation: location.typeLocation || location.label,
    uuid: uuidV4(),
    surface: 0,
    hauteurSousPlafond: lastLocationWithHauteur ? lastLocationWithHauteur.hauteurSousPlafond : 0,
    longueurMurs: 0,
    surfaceMurs: 0,
    label: buildLabel(location.label, existingLabels),
  };

  return [...existingLocations, newLocation];
};

export const deleteLocation = (
  deletedLocation: DevisLocation,
  locations: DevisLocation[],
): DevisLocation[] => {
  const deletedLocationIndex = getLocationIndex(deletedLocation);
  const otherLocations = locations.filter(({ uuid }) => uuid !== deletedLocation.uuid);
  if (!deletedLocationIndex) {
    return otherLocations;
  }

  const newLocations: DevisLocation[] = [];
  const locationsWithSameType = otherLocations.filter(
    ({ typeLocation }) => typeLocation === deletedLocation.typeLocation,
  );

  for (const location of locations) {
    if (location.uuid === deletedLocation.uuid) {
      continue;
    }
    const locationIndex = getLocationIndex(location);
    if (location.typeLocation !== deletedLocation.typeLocation || locationIndex === null) {
      newLocations.push(location);
      continue;
    }

    const newLocationLabel =
      locationsWithSameType.length === 1
        ? deletedLocation.typeLocation
        : indexedLabel(
            deletedLocation.typeLocation,
            deletedLocationIndex < locationIndex ? locationIndex - 1 : locationIndex,
          );
    newLocations.push({
      ...location,
      label: newLocationLabel,
    });
  }
  return newLocations;
};
