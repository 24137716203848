export enum Consent {
  AdStorage = 'ad_storage',
  AdUserData = 'ad_user_data',
  AdPersonalization = 'ad_personalization',
  AnalyticsStorage = 'analytics_storage',
  PersonalizationStorage = 'personalization_storage',
}

export enum ConsentValue {
  Granted = 'granted',
  Denied = 'denied',
}

export enum ConsentEvent {
  Consents = 'consents',
}
