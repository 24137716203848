import React from 'react';

import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { CheckboxField } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox/Field';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';
import { RadioButtonsListField } from '@travauxlib/shared/src/components/DesignSystem/components/RadioButtonsList/Field';
import { Gamme } from '@travauxlib/shared/src/types/api/common/Gamme';
import { required } from '@travauxlib/shared/src/utils/form';

import { CategoryName, QuestionProps } from 'features/Estimateur/types';

import { ItemAdder } from '../ItemAdder';
import { Title } from '../QuestionTitle';

type PorteEntree = {
  needsFrontDoorRenovation: string;
  blindage: string;
  nombreVantail?: string;
};

type Verriere = { largeur: number };

type FormValues = {
  answerType: CategoryName;
  gamme: Gamme;
  petitesFenetres: number;
  moyennesFenetres: number;
  grandesFenetres: number;
  voletsRoulantsFenetres: number;
  grandeHauteurAndPortesFenetres: number;
  voletsRoulantsPortesFenetres: number;
  baiesvitrees: number;
  voletsRoulantsBaiesVitrees: number;
  porteEntree: PorteEntree;
  portesInterieur: number;
  portesInterieurCoulissantes: number;
  verrieres: Verriere[];
};

type SimpleNumberInputProps = {
  label: string | React.ReactElement;
  name: string;
  className?: string;
};

const SimpleNumberInput: React.FC<SimpleNumberInputProps> = ({ label, name, className }) => (
  <div className={`-mx-md mb-md flex flex-wrap !items-center ${className}`}>
    <div className="w-full max-w-6/12 px-md sm-desktop:max-w-4/12">
      <NumberInputField label={label} name={name} id={name} validate={required} defaultValue={0} />
    </div>
  </div>
);

export const Fenetres = ({
  submitAnswer,
  initialValues,
}: QuestionProps<FormValues>): React.ReactElement => (
  <Form<FormValues>
    mutators={{ ...arrayMutators }}
    onSubmit={submitAnswer}
    initialValues={initialValues}
    initialValuesEqual={() => true}
  >
    {({ handleSubmit, valid, values, submitting }) => (
      <form onSubmit={handleSubmit}>
        <div className="mb-xl">
          <Title title="Fenêtres" />
          <div className="mb-[3.5rem]">
            <SimpleNumberInput
              label={
                <div>
                  <span className="mr-xs">Petites fenêtres</span>
                  <small>&lt; 90cm</small>
                </div>
              }
              name="petitesFenetres"
            />
            <SimpleNumberInput
              label={
                <div>
                  <span className="mr-xs">Moyennes fenêtres</span>
                  <small>≥ 90 cm &lt; 120cm</small>
                </div>
              }
              name="moyennesFenetres"
            />
            <SimpleNumberInput
              label={
                <div>
                  <span className="mr-xs">Grandes fenêtres</span>
                  <small>≥ 120cm</small>
                </div>
              }
              name="grandesFenetres"
            />
            <SimpleNumberInput
              label="Volets roulants pour fenêtres"
              name="voletsRoulantsFenetres"
              className="mb-[3.5rem]"
            />
            <SimpleNumberInput
              label="Fenêtres grande hauteur ou portes-fenêtres"
              name="grandeHauteurAndPortesFenetres"
            />
            <SimpleNumberInput
              label="Volets roulants pour portes-fenêtres"
              name="voletsRoulantsPortesFenetres"
              className="mb-[3.5rem]"
            />
            <SimpleNumberInput label="Baies vitrées" name="baiesvitrees" />
            <SimpleNumberInput
              label="Volets roulants pour baies vitrées"
              name="voletsRoulantsBaiesVitrees"
            />
          </div>
          <div className="mb-[3.5rem]">
            <h1>Portes</h1>
            <div className="mb-lg">
              <div className="mb-md text-b1">Faut-il remplacer la porte d'entrée ?</div>
              <div className="-mx-md mb-lg flex flex-wrap !items-center">
                <div className="w-full px-md">
                  <RadioButtonsListField
                    className="mb-md"
                    name="porteEntree.needsFrontDoorRenovation"
                    options={[
                      { label: 'Oui', value: 'yes' },
                      { label: 'Non', value: 'no' },
                    ]}
                    defaultValue="no"
                    inline
                  />
                </div>
                {values.porteEntree.needsFrontDoorRenovation === 'yes' && (
                  <>
                    <div className="w-full px-md">
                      <RadioButtonsListField
                        className="mb-md"
                        name="porteEntree.nombreVantail"
                        options={[
                          { label: 'Simple', value: 'simple' },
                          { label: 'Double', value: 'double' },
                        ]}
                        defaultValue="simple"
                        inline
                      />
                    </div>
                    <div className="w-full px-md">
                      <CheckboxField name="porteEntree.blindage" label="Blindée" />
                    </div>
                  </>
                )}
              </div>
            </div>
            <SimpleNumberInput label="Portes d'intérieur" name="portesInterieur" />
            <SimpleNumberInput
              label="Portes d'intérieur coulissantes"
              name="portesInterieurCoulissantes"
            />
          </div>
          <div className="mb-[3.5rem]">
            <h1>Verrières</h1>
            <ItemAdder<Verriere>
              name="verrieres"
              newItem={{ largeur: 1 }}
              label="Verrières à ajouter"
              deleteLabel="Ajouter une verrière"
            >
              {({ name, index, deleteButton }) => (
                <React.Fragment key={name}>
                  <div className="-mx-md mb-md flex flex-wrap !items-center">
                    <label
                      className="w-full max-w-6/12 px-md sm-desktop:max-w-3/12"
                      htmlFor={`${name}.largeur`}
                    >
                      Verrière {index + 1}
                    </label>
                    <div className="w-full max-w-5/12 px-md sm-desktop:max-w-4/12">
                      <NumberInputField
                        name={`${name}.largeur`}
                        id={`${name}.largeur`}
                        placeholder="Largeur de la verrière"
                        min={0}
                        validate={required}
                        suffix="m"
                        defaultValue={1}
                      />
                    </div>
                    <div className="w-full max-w-1/12 px-md">{deleteButton}</div>
                  </div>
                </React.Fragment>
              )}
            </ItemAdder>
          </div>
        </div>
        <div className="text-center">
          <Button type="submit" disabled={!valid || submitting} loading={submitting}>
            Valider
          </Button>
        </div>
      </form>
    )}
  </Form>
);
