import React from 'react';

import classNames from 'classnames';
import { useLocation } from 'react-router';

import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { DevisFactureCard } from '@travauxlib/shared/src/features/DevisDisplay/components/DevisFactureCard';
import { ColoredElement } from '@travauxlib/shared/src/features/DevisDisplay/components/Header';
import { DevisCustomization } from '@travauxlib/shared/src/types';
import { DevisView, ProContextView } from '@travauxlib/shared/src/types/api/client/DevisView';
import { getUrlParam } from '@travauxlib/shared/src/utils/urls';
import { devisOrProposition } from '@travauxlib/shared/src/utils/wording';

import { DevisSignedCard } from './DevisSignedCard';

type Props = {
  devis: DevisView;
  proContext: ProContextView;
  isAuthor?: boolean;
  customization?: DevisCustomization;
  isForYousign?: boolean;
  isPrint?: boolean;
};

export const Signature: React.FC<Props> = ({
  devis,
  proContext,
  isAuthor,
  customization,
  isForYousign,
  isPrint,
}) => {
  const {
    deal: { customerName, isHemea },
    customerSignature,
    company: {
      activatedElectronicSignature,
      name,
      firstNameRepresentantLegal,
      lastNameRepresentantLegal,
      isArchitecte,
    },
    signe,
    rejectionReason,
  } = proContext;
  const handWrittenSignature = getUrlParam(useLocation(), 'handWrittenSignature') === 'true';
  const title = `Signature ${isHemea ? 'électronique' : ''}`;
  const clientSignatureName = customerSignature || customerName;
  const enterpriseOwner = `${firstNameRepresentantLegal} ${lastNameRepresentantLegal}`;

  const isWithElectronicSignature =
    isHemea || (activatedElectronicSignature && !handWrittenSignature);
  // We simulate a signed devis when we generate the PDF for Yousign
  const isDevisSigned = signe || isForYousign;
  const isDevisSignableOnline =
    !rejectionReason && !isAuthor && !isDevisSigned && isWithElectronicSignature;

  return (
    <div className="break-inside-avoid" data-testid="signature-section">
      <ColoredElement
        className={classNames('mb-md text-h4 font-medium', customization?.titleFontStyle)}
        color={customization?.textColor}
      >
        {title}
      </ColoredElement>
      <DevisFactureCard className="mb-md !p-md">
        <div className="flex flex-col tablet:flex-row mb-lg">
          <div className="basis-full tablet:pr-lg tablet:border-r border-b tablet:border-b-0 pb-md tablet:pb-0">
            <strong className="mb-md">De la société {name}</strong>
            <div>{enterpriseOwner}</div>
          </div>
          <div className="basis-full tablet:pl-lg flex justify-between flex-col sm-desktop:flex-row md-desktop:flex-col lg-desktop:flex-row pt-md tablet:pt-0">
            <div className="mb-md sm-desktop:mb-0 md-desktop:mb-md lg-desktop:mb-0">
              <strong className="mb-md">Du client</strong>
              {isDevisSigned && <div>{clientSignatureName}</div>}
              {isDevisSignableOnline && (
                <>
                  <div className="mb-sm">
                    {devisOrProposition({ isArchitecte, capitalize: true })} à signer
                    électroniquement, une signature manuscrite n&apos;est pas acceptée
                  </div>
                  {/*Link to devis preview inside PDF*/}
                  {isPrint && (
                    <ButtonLink
                      className="!no-underline"
                      to={`/pro/devis/${devis.token}`}
                      variant="primary"
                    >
                      Signer {devisOrProposition({ isArchitecte, prefix: 'le' })}
                    </ButtonLink>
                  )}
                </>
              )}
            </div>
            {isDevisSigned && isWithElectronicSignature && (
              <DevisSignedCard proContext={proContext} isForYousign={!!isForYousign} />
            )}
          </div>
        </div>
        {isHemea && (
          <>
            <div className="!text-gray-600 !text-sm">
              Pour bénéficier des avantages hemea vous devez impérativement signer{' '}
              {devisOrProposition({ isArchitecte, prefix: 'ce' })} électroniquement via la
              plateforme hemea.
              <br />
              {isArchitecte ? 'Proposition reçue ' : 'Devis reçu '}
              avant l&apos;exécution des travaux
            </div>
          </>
        )}
      </DevisFactureCard>
    </div>
  );
};
