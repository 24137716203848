export enum Investissement {
  DOUBLE_ACTIVITE = 'doubleActivite',
  EQUILIBRE = 'equilibre',
  COMPLET = 'complet',
}

export enum Reseau {
  AUCUN = 'aucun',
  MOYEN = 'moyen',
  GRAND = 'grand',
}

export enum CommissionMLM {
  MLM0 = 'MLM0',
  MLM5 = 'MLM5',
  MLM15 = 'MLM15',
  MLM45 = 'MLM45',
}

export type EstimateurFormValues = {
  tempsInvesti: Investissement;
  reseauApporteur: Reseau;
  firstname: string;
  lastname: string;
  email: string;
  phoneNumber: string;
};
