import React, { useEffect } from 'react';

import classNames from 'classnames';
import { useParams } from 'react-router';

import ArrowLeft from '@travauxlib/shared/src/components/DesignSystem/assets/ArrowLeftFilled.svg?react';
import CheckCircle from '@travauxlib/shared/src/components/DesignSystem/assets/CheckCircle.svg?react';
import UnCheckCircle from '@travauxlib/shared/src/components/DesignSystem/assets/UnCheckCircle.svg?react';
import { AccordionItem } from '@travauxlib/shared/src/components/DesignSystem/components/Accordion';
import { IconButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';

import { useProject } from 'features/EspaceClient3/api/useProject';
import { EspaceClientTracking } from 'utils/tracking/EspaceClientTracking';

import { etapeConfigs } from './utils/constants';

export const Etapes: React.FC = () => {
  const { uuid } = useParams();
  const { project, isLoading } = useProject(uuid!);

  useEffect(() => {
    if (project) {
      EspaceClientTracking.onEspaceClientProjectStepViewed({
        'Project Uuid': project.uuid,
        'Project Name': project.title,
      });
    }
  }, [project]);

  if (isLoading) {
    return <Loader />;
  }

  const etapeAt = Object.keys(etapeConfigs).indexOf(project?.etape || 'no etape');

  return (
    <div className="flex flex-col gap-xs mx-md my-sm">
      <div className="flex items-center mb-xxs gap-xxs">
        <IconButtonLink to=".." title="Étapes du projet">
          <ArrowLeft />
        </IconButtonLink>
        <span className="text-h5 font-bold">Étapes du projet</span>
      </div>
      {Object.entries(etapeConfigs).map(([key, etape], index) => (
        <AccordionItem
          key={key}
          icon={
            index < etapeAt ? (
              <CheckCircle className="text-primary-400" data-testid="checked-step" />
            ) : (
              <UnCheckCircle
                className={classNames(index === etapeAt ? 'text-primary-400' : 'text-neutral-300')}
                data-testid="unchecked-step"
              />
            )
          }
          title={etape.title}
          defaultExpanded={index === etapeAt}
        >
          <ul className="text-b2 text-neutral-600 mb-0 pl-md">
            {etape.items.map(item => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </AccordionItem>
      ))}
    </div>
  );
};
