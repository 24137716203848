import React from 'react';

import classNames from 'classnames';

import { LayoutGrid } from '@travauxlib/shared/src/components/DesignSystem/components/Layout';
import { ImgLazy } from '@travauxlib/shared/src/components/Images/ImgLazy';
import { baseImgixUrl } from '@travauxlib/shared/src/utils/urls';

import { LegalFooter } from './LegalFooter';
import { ScrollToTop } from './ScrollToTop';

import { layoutGridPaddings, stickyTop } from '../utils/classNames';
import { contentHeightClassName, scrollContainerId } from '../utils/constants';

export const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <>
    <ScrollToTop />
    <div
      className={classNames(
        'p-md sm-desktop:pl-lg md-desktop:pl-xl bg-neutral-0 shadow-xs z-20',
        stickyTop,
      )}
    >
      <a href={APP_CONFIG.wwwURL}>
        <ImgLazy url={`${baseImgixUrl}/static/logo-hemea-black-v2.png`} height={32} alt="hemea" />
      </a>
    </div>
    <LayoutGrid
      className={classNames(
        'relative bg-neutral-100 overflow-auto',
        '!flex sm-desktop:!grid flex-col',
        'py-md sm-desktop:py-0 !mx-0',
        layoutGridPaddings,
        contentHeightClassName,
      )}
      id={scrollContainerId}
    >
      <>{children}</>
      <div className="block sm-desktop:hidden">
        <LegalFooter />
      </div>
    </LayoutGrid>
  </>
);
