import React, { useEffect } from 'react';

import { useNavigate, useSearchParams } from 'react-router';

import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';

import { useGetAccount } from 'api/useGetAccount';
import { Authentication } from 'features/Authentication/Loadable';

import { EspaceClient3Routes } from '../features/EspaceClient3/routes';
import { EstimateurClient } from '../features/Estimateur/features/Client';
import { useIsEstimateurClient } from '../features/Estimateur/utils/paths';

export const AuthenticatedRoutes: React.FC = () => {
  const navigate = useNavigate();
  const isEstimateurClient = useIsEstimateurClient();

  const { account, isLoading } = useGetAccount();

  const [params] = useSearchParams();

  const redirect = params.get('redirect');

  useEffect(() => {
    if (account && redirect) {
      navigate(redirect || '/');
    }
  }, [account, redirect]);

  if (isLoading) {
    return <Loader />;
  }

  if (!account) {
    return <Authentication />;
  }

  return isEstimateurClient ? <EstimateurClient /> : <EspaceClient3Routes />;
};
