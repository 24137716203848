import React from 'react';

import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router';

import ArrowLeft from '@travauxlib/shared/src/components/DesignSystem/assets/ArrowLeftFilled.svg?react';
import { IconButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import { useGetDevisFactures } from 'features/EspaceClient3/api/useGetDevisFactures';
import { MainDevisCardExtended } from 'features/EspaceClient3/components/MainDevis/MainDevisCardExtended';

import { CardWithLinks } from './CardWithLinks';

// TODO faudra virer la tapbar sur cette écran mais pas le header
export const Details: React.FC = () => {
  const { uuid, proCompanyUuid, proProjectUuid } = useParams<{
    uuid: string;
    proCompanyUuid: string;
    proProjectUuid: string;
  }>();
  const navigate = useNavigate();
  const { devisFactures, isLoading } = useGetDevisFactures(uuid!);

  if (isLoading) {
    return <Loader />;
  }

  const devisFacturesForCompany = devisFactures?.proCompanies.find(
    proCompany => proCompany.uuid === proCompanyUuid,
  );

  if (!devisFacturesForCompany) {
    navigate('..');
    return null;
  }

  const { title, mainDevis, otherDevis, factures } =
    devisFacturesForCompany.devisByProjectUuid[proProjectUuid!];
  const allDevis = [mainDevis, ...otherDevis];

  return (
    <div className="mx-md sm-desktop:mx-0">
      <div className="flex items-center gap-xxs mb-xs">
        <IconButtonLink to="..">
          <ArrowLeft />
        </IconButtonLink>
        <div className="text-h5 font-bold">{title}</div>
      </div>
      <MainDevisCardExtended
        className="mb-md rounded-md shadow-sm"
        title={title}
        devis={mainDevis}
        isArchitecte={devisFacturesForCompany.companyType === 'architecte'}
      />
      {factures.length > 0 && (
        <CardWithLinks
          className="mb-md"
          title="Factures"
          items={factures.map(facture => {
            const label = facture.paidAt
              ? `Acquittée le ${formatFrenchDate(facture.paidAt)}`
              : `Envoyée le ${formatFrenchDate(facture.sentAt)}`;

            return {
              title: facture.title,
              label,
              price: facture.prixTotalTTC,
              href: facture.detailsUrl,
              tag: facture.paidAt ? { label: 'Payée', variant: 'success' } : undefined,
            };
          })}
        />
      )}
      <CardWithLinks
        title="Historique des versions"
        items={allDevis
          .sort((devisBlock1, devisBlock2) =>
            dayjs(devisBlock2.sentAt).diff(dayjs(devisBlock1.sentAt)),
          )
          .map(devis => ({
            title: `Version ${devis.version}`,
            label: `Envoyé le ${formatFrenchDate(devis.sentAt)}`,
            price: devis.prixTotalTTC,
            to: `/pro/devis/${devis.token}`,
          }))}
      />
    </div>
  );
};
