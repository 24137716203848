import React from 'react';

import CrossSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/CrossSymbolFilled.svg?react';
import Gift from '@travauxlib/shared/src/components/DesignSystem/assets/Gift.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { RemiseType } from '@travauxlib/shared/src/types/api/domain/prodevisfactures/RemiseType';

type Props = {
  onDelete: () => void;
  label: string;
  value: number;
  typeRemise?: RemiseType;
};

export const RemisePrimeDisplay: React.FC<Props> = ({ onDelete, label, value, typeRemise }) => (
  <div className="flex items-center text-sm text-primary font-bold flex-wrap gap-xxs">
    <IconButton className="mr-xxs" onClick={onDelete} size="xs">
      <CrossSymbol />
    </IconButton>
    <Gift className="w-md h-md" />
    {typeRemise ? 'REMISE' : 'PRIME'} {label} -<EURCurrency amount={value} />{' '}
    {typeRemise === 'pourcentage' ? 'sur le HT' : 'sur le TTC'}
  </div>
);
