import { ChantierPvView } from '@travauxlib/shared/src/types/api/common/ChantierPvView';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

export const getPvDateLabel = (pv: ChantierPvView): string => {
  if (pv.rejectedAt) {
    return `Refusé le ${formatFrenchDate(pv.rejectedAt)}`;
  }
  if (pv.validatedAt) {
    return `Validé le ${formatFrenchDate(pv.validatedAt)}`;
  }
  return `Envoyé le ${formatFrenchDate(pv.createdAt)}`;
};
