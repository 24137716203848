import React, { useState } from 'react';

import classNames from 'classnames';
import { useLocalStorage } from 'react-use';

import Edit from '@travauxlib/shared/src/components/DesignSystem/assets/EditFilled.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { ImgLazy } from '@travauxlib/shared/src/components/Images/ImgLazy';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';
import { baseImgixUrl } from '@travauxlib/shared/src/utils/urls';

import { EstimateurForm } from './EstimateurForm';
import { EstimateurFormModal } from './EstimateurFormModal';
import { YearlyRevenue } from './YearlyRevenue/YearlyRevenue';

import { useCreateEstimationCDPL } from '../api/useCreateEstimationCDPL';
import { EstimateurFormValues } from '../types';

export const EstimateurCDPL: React.FC = () => {
  const [values, setValues] = useLocalStorage<EstimateurFormValues | undefined>('estimation-cdpl');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const isTabletOrMobile = useIsTabletOrMobile();
  const createEstimationCDPL = useCreateEstimationCDPL();

  const onSubmit = async (value: EstimateurFormValues): Promise<void> => {
    setValues(value);
    await createEstimationCDPL(value);
  };

  return (
    <div className="flex-col align-stretch h-full w-full">
      <EstimateurFormModal
        values={values}
        setValues={setValues}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <div
        className={classNames(
          'flex justify-between items-center gap-xs bg-neutral-0 shadow-xs py-md px-md sm-desktop:px-lg sticky top-0 z-30',
        )}
      >
        <a href={APP_CONFIG.wwwURL}>
          <ImgLazy url={`${baseImgixUrl}/static/logo-hemea-black-v2.png`} height={32} alt="hemea" />
        </a>
        <div className="flex gap-md">
          {values && (
            <Button leftIcon={<Edit />} onClick={() => setIsModalOpen(true)} variant="secondary">
              {isTabletOrMobile ? undefined : 'Modifier'}
            </Button>
          )}
        </div>
      </div>
      <div className="flex w-full bg-neutral-100 min-h-[calc(100%-5rem)]">
        <div className="flex w-full justify-center items-center">
          {!values && (
            <div className="p-md bg-neutral-0 shadow-sm w-[36rem] rounded-xs">
              <h2 className="font-bold mb-md">Estimateur de revenu</h2>
              <EstimateurForm onSubmit={onSubmit} />
            </div>
          )}
          {values && <YearlyRevenue {...values} />}
        </div>
      </div>
    </div>
  );
};
