import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import {
  AdminEventsProperties,
  AppFeatures,
  DocumentFinChantierProperties,
  TrackingEvents,
} from '@travauxlib/shared/src/utils/tracking';

class DocumentFinChantierClass extends Tracker<AppFeatures> {
  onDeclined(properties: {
    [AdminEventsProperties.ProjetUUID]: string;
    [DocumentFinChantierProperties.EndOfChantierDocumentType]: string;
  }): void {
    this.sendTracking({
      event: TrackingEvents.Declined,
      properties,
    });
  }
}

export const DocumentFinChantier = new DocumentFinChantierClass(AppFeatures.DocumentFinChantier);
