import { ProContextView } from '@travauxlib/shared/src/types/api/client/DevisView';
import { DevisCommentView } from '@travauxlib/shared/src/types/api/common/DevisCommentView';

type Result = {
  [K in DevisCommentView['senderType']]: string;
};

export const getSenderNamesFromDevis = (proContext: ProContextView): Result => {
  const {
    company: { name: proCompanyName },
    deal: { customerName, dealOwnerFullname },
  } = proContext;

  const senderNames: Result = {
    pro: proCompanyName,
    'deal-owner': dealOwnerFullname || 'hemea',
    client: customerName,
  };

  return senderNames;
};
