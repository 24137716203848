import React, { useEffect } from 'react';

import classNames from 'classnames';

import { SuiviView } from '@travauxlib/shared/src/types/api/client/SuiviView';

import { SuiviChantierContent } from 'features/SuiviChantier/components/SuiviChantierContent';

type Props = {
  suiviChantier: SuiviView;
};

export const SuiviChantierPrint: React.FC<Props> = props => {
  const suiviChantier = props.suiviChantier;

  useEffect(() => {
    window.status = 'ready';
  }, []);

  return (
    <div className={classNames('break-before-page')}>
      <SuiviChantierContent suiviChantier={suiviChantier} />
    </div>
  );
};
