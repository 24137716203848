import {
  sendEventToFacebookPixel as sendEventToFB,
  sendEventToTrackingModules as sendEvent,
  TrackingEndpoint,
  TrackingInfo,
} from '@travauxlib/shared/src/api/tracking';
import { UserTracking } from '@travauxlib/shared/src/api/UserTracking';
import { ClientAccountView } from '@travauxlib/shared/src/types/api/client/ClientAccountView';

import { EstimateurEvent } from 'features/Estimateur/utils/constants';
import { JourneyEvents, OnboardingEvents } from 'features/OnboardingSolide/types';

export enum AppEvents {
  calendlyButtonClicked = 'calendly',
}

const trackingUrl: TrackingEndpoint = `${APP_CONFIG.apiURL}/client/tracking`;

export const sendEventToFacebookPixel = ({
  event,
  userInfo,
}: Omit<TrackingInfo, 'endpointUrl'>): Promise<void> =>
  sendEventToFB({ event, endpointUrl: trackingUrl, userInfo });

export const sendEventToTrackingModules = ({
  event,
  userInfo,
  data,
}: Omit<TrackingInfo, 'endpointUrl' | 'event'> & {
  event: JourneyEvents | OnboardingEvents | EstimateurEvent | AppEvents;
}): void => sendEvent({ event, endpointUrl: trackingUrl, userInfo, data });

export const TrackingIdentify = (account: ClientAccountView): void => {
  const shouldDisableMixpanel = getShouldDisableMixpanel(account);

  if (shouldDisableMixpanel) {
    UserTracking.disable();
    return;
  }

  UserTracking.identify(account.uuid);
  UserTracking.people.set({
    $name: `${account.firstName} ${account.lastName}`,
    $email: account.email,
  });
};

const getShouldDisableMixpanel = (account: ClientAccountView): boolean => {
  const isHemeaEmployeeInProd = !!account?.hemeaEmployee && ENVIRONMENT === 'production';

  return !APP_CONFIG.mixpanel.enabled || !account || isHemeaEmployeeInProd;
};
