import React from 'react';

import classNames from 'classnames';

import { DevisFactureCard } from '@travauxlib/shared/src/features/DevisDisplay/components/DevisFactureCard';
import { safeFormat } from '@travauxlib/shared/src/utils/time';
import { devisOrProposition } from '@travauxlib/shared/src/utils/wording';

import ArrowRight from 'assets/arrow-right.svg?react';

type Props = {
  dateDebut: string;
  dateFin?: string;
  isHemeaDeal?: boolean;
  isArchitecte?: boolean;
  isRGE?: boolean;
};

export const Delais: React.FC<Props> = ({
  dateDebut,
  dateFin,
  isArchitecte = false,
  isHemeaDeal = false,
  isRGE,
}) => (
  <DevisFactureCard
    className={classNames(isRGE ? 'mb-md w-full sm-desktop:w-1/2' : 'w-full mb-md')}
    title="Délais contractuels"
  >
    <div className="flex flex-wrap -mx-md mb-sm">
      <div className={dateFin ? 'w-full px-md max-w-5/12' : 'w-full px-md'}>
        <small>Date de début prévue des travaux</small>
      </div>
      {!!dateFin && (
        <>
          <div className="w-full px-md max-w-2/12" />
          <div className="w-full px-md max-w-5/12 text-right">
            {' '}
            <small>Engagement contractuel de fin des travaux </small>
          </div>
        </>
      )}
    </div>
    <div className="text-h5 flex -mx-md mb-md">
      <div
        className={classNames(
          'whitespace-nowrap',
          dateFin ? 'w-full px-md max-w-5/12' : 'w-full px-md',
        )}
      >
        {safeFormat(dateDebut, 'DD MMM YYYY')}
      </div>
      {!!dateFin && (
        <>
          <div className="w-full px-md max-w-2/12 text-center">
            <ArrowRight className="!w-xs !h-xmd" />
          </div>
          <div className="!pl-0 w-full px-md max-2/12 text-right whitespace-nowrap">
            {safeFormat(dateFin, 'DD MMM YYYY')}
          </div>
        </>
      )}
    </div>
    {isHemeaDeal ? (
      <>
        <small className="text-gray-600">
          Les délais inscrits dans {devisOrProposition({ isArchitecte, prefix: 'le' })} sont
          engageants. La signature du contrat en ligne via hemea inclut des pénalités de retard.
        </small>
      </>
    ) : null}
  </DevisFactureCard>
);
