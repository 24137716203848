import React from 'react';

import classNames from 'classnames';

import ChevronRight from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronRightFilled.svg?react';
import Construction from '@travauxlib/shared/src/components/DesignSystem/assets/ConstructionFilled.svg?react';
import HemeaM from '@travauxlib/shared/src/components/DesignSystem/assets/HemeaM.svg?react';
import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { ItemLink } from '@travauxlib/shared/src/components/ItemLink';
import { FactureMinimalViewEC3 } from '@travauxlib/shared/src/types/api/client/FactureMinimalViewEC3';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

type Props = {
  factures: FactureMinimalViewEC3[];
};

export const LastFactures: React.FC<Props> = ({ factures }) => {
  const shouldShowAllFacturesButton = factures.length > 2;

  return (
    <>
      <div className="text-neutral-800 text-h5 font-bold py-sm mx-md sm-desktop:mx-0">
        Mes dernières factures
      </div>
      <div
        className={classNames('py-xxs bg-neutral-0 rounded-xxs shadow-xxs', {
          'pb-md': shouldShowAllFacturesButton,
        })}
      >
        {factures.slice(0, 3).map(facture => (
          <ItemLink
            key={facture.tokenOrId}
            title={facture.title}
            href={facture.detailsUrl}
            label={`Envoyée le ${formatFrenchDate(facture.sentAt)}`}
            price={facture.prixTotalTTC}
            leftIcon={facture.isHemea ? <HemeaM /> : <Construction />}
            rightIcon={<ChevronRight />}
            tag={facture.paidAt ? { label: 'Payé', variant: 'success' } : undefined}
          />
        ))}
        {shouldShowAllFacturesButton && (
          <span className="block mt-md text-right mx-md">
            <ButtonLink
              className="w-full sm-desktop:w-fit"
              variant="secondary"
              to="./factures"
              size="sm"
            >
              Voir toutes mes factures
            </ButtonLink>
          </span>
        )}
      </div>
    </>
  );
};
