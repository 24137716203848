import React from 'react';

import { Routes, Route, useParams } from 'react-router';

import { GoogleMapsAddress } from '@travauxlib/shared/src/types/api/common/GoogleMapsAddress';

import { Estimation } from 'features/Estimateur/types';

import { EstimationList } from '../features/EstimationList';
import { EstimateurStarter } from '../features/New';
import { EstimateurQuestions } from '../features/Questions';

type Props = {
  deleteEstimation: (estimationUuid: string) => Promise<unknown>;
  submitEstimation: (payload: {
    estimation: Estimation;
    preventRedirect?: boolean;
  }) => Promise<unknown>;
  getPrintEstimationUrl?: (uuid: string) => string;
  estimations: Estimation[];
  organizationName?: string;
  isClientEstimateur?: boolean;
  setUserAddress?: (userAddress: GoogleMapsAddress) => void;
};

const EstimateurStarterWrapper: React.FC<
  Omit<React.ComponentProps<typeof EstimateurStarter>, 'estimation'> & { estimations: Estimation[] }
> = ({ estimations, ...props }) => {
  const { uuid } = useParams();
  const estimation = estimations.find(estimation => estimation.uuid === uuid);
  return <EstimateurStarter estimation={estimation} {...props} />;
};

const EstimateurQuestionsWrapper: React.FC<
  Omit<React.ComponentProps<typeof EstimateurQuestions>, 'estimation'> & {
    estimations: Estimation[];
  }
> = ({ estimations, ...props }) => {
  const { uuid } = useParams();
  const estimation = estimations.find(estimation => estimation.uuid === uuid);
  if (!estimation) {
    return null;
  }
  return <EstimateurQuestions estimation={estimation} {...props} />;
};

export const EstimateurRoutes: React.FC<Props> = ({
  isClientEstimateur = false,
  organizationName,
  estimations,
  deleteEstimation,
  submitEstimation,
  getPrintEstimationUrl,
  setUserAddress,
}) => (
  <Routes>
    <Route
      path=""
      element={
        <EstimationList
          estimations={estimations}
          submitEstimation={estimation => submitEstimation({ estimation, preventRedirect: true })}
          deleteEstimation={deleteEstimation}
        />
      }
    />
    <Route
      path="new/*"
      element={
        <>
          <EstimateurStarter
            submitInitialConfiguration={estimation => submitEstimation({ estimation })}
            setUserAddress={setUserAddress}
            isTrackingEnabled
          />
        </>
      }
    />
    <Route
      path=":uuid/context/*"
      element={
        <EstimateurStarterWrapper
          estimations={estimations}
          submitInitialConfiguration={estimation =>
            submitEstimation({
              estimation,
            })
          }
          setUserAddress={setUserAddress}
        />
      }
    />
    <Route
      path=":uuid/*"
      element={
        <EstimateurQuestionsWrapper
          estimations={estimations}
          isClientEstimateur={isClientEstimateur}
          getPrintEstimationUrl={getPrintEstimationUrl}
          submitEstimation={estimation => submitEstimation({ estimation })}
          organizationName={organizationName}
        />
      }
    />
  </Routes>
);
