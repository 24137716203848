import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { FORM_ERROR } from 'final-form';
import { useNavigate } from 'react-router';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request, isForbidden, isUnauthorized } from '@travauxlib/shared/src/utils/request';

import { Profile, Estimation } from 'features/Estimateur/types';

export const ESTIMATEUR_PROFILE_KEY = 'ESTIMATEUR_PROFILE';

const handleLogout = (): Promise<void> =>
  request(`${APP_CONFIG.apiURL}/partners/estimateur/logout`, {
    method: 'POST',
  });

const handleLogin = async (body: {
  email: string;
  password: string;
}): Promise<Record<string, string> | undefined> => {
  try {
    await request(`${APP_CONFIG.apiURL}/partners/estimateur/login`, {
      method: 'POST',
      body,
    });
    return undefined;
  } catch (err) {
    let error = 'La connexion a échoué';
    if (err instanceof Error && isForbidden(err)) {
      error = "Votre compte n'est plus accessible";
    } else if (err instanceof Error && isUnauthorized(err)) {
      error = 'Email ou mot de passe incorrect.';
    }
    return { [FORM_ERROR]: error };
  }
};

const handleResetPassword = (body: {
  email: string;
  token: string;
  password: string;
}): Promise<void> => {
  const url = `${APP_CONFIG.apiURL}/partners/estimateur/reset-password`;
  return request(url, {
    method: 'POST',
    body,
  });
};

const handleForgotPassword = (body: { email: string }): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/partners/estimateur/forgot-password`;
  return request(requestURL, {
    method: 'POST',
    body,
  });
};

const handleDeleteEstimation = async (estimationUuid: string): Promise<string> => {
  const url = `${APP_CONFIG.apiURL}/partners/estimateur/estimation/${estimationUuid}`;

  await request(url, {
    method: 'DELETE',
  });
  return estimationUuid;
};

type SubmitEstimationPayload = {
  estimation: Estimation;
  preventRedirect: boolean;
};

const handleSubmitEstimation = async ({
  estimation,
  preventRedirect,
}: SubmitEstimationPayload): Promise<{
  estimation: Estimation;
  preventRedirect: boolean;
}> => {
  const url = `${APP_CONFIG.apiURL}/partners/estimateur/estimation`;

  const newEstimation = await request(url, {
    method: 'POST',
    body: { ...estimation, ...estimation.context },
  });
  return {
    estimation: newEstimation,
    preventRedirect,
  };
};

export const useEstimateur = (): {
  profile?: Profile;
  login: typeof handleLogin;
  logout: typeof handleLogout;
  resetPassword: typeof handleResetPassword;
  deleteEstimation: typeof handleDeleteEstimation;
  submitEstimation: typeof handleSubmitEstimation;
  forgotPassword: typeof handleForgotPassword;
  isLoading: boolean;
} => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: profile, isLoading } = useQuery<Profile>({
    queryKey: [ESTIMATEUR_PROFILE_KEY],
    queryFn: () => request(`${APP_CONFIG.apiURL}/partners/estimateur/account`),
    staleTime: Infinity,
    retry: (failureCount, error: Error) => {
      if (isUnauthorized(error)) {
        return false;
      }
      return failureCount < 3;
    },
  });

  const { mutateAsync: logout } = useMutation({
    mutationFn: handleLogout,
    onSuccess: () => {
      queryClient.setQueryData([ESTIMATEUR_PROFILE_KEY], null);
    },
  });

  const { mutateAsync: login } = useMutation({
    mutationFn: handleLogin,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ESTIMATEUR_PROFILE_KEY] });
    },
  });

  const { mutateAsync: resetPassword } = useMutation({
    mutationFn: handleResetPassword,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ESTIMATEUR_PROFILE_KEY] });
    },
  });

  const { mutateAsync: forgotPassword } = useMutation({
    mutationFn: handleForgotPassword,
    onError: () => {
      toast.error('Oups, il y a eu un problème ...');
    },
  });

  const { mutateAsync: deleteEstimation } = useMutation({
    mutationFn: handleDeleteEstimation,
    onSuccess: (estimationUuid: string) => {
      queryClient.setQueryData([ESTIMATEUR_PROFILE_KEY], {
        ...profile,
        estimations: profile?.estimations.filter(({ uuid }) => uuid !== estimationUuid),
      });
    },
  });

  const { mutateAsync: submitEstimation } = useMutation({
    mutationFn: handleSubmitEstimation,
    onError: (err: Error) => {
      if (isForbidden(err)) {
        queryClient.setQueryData([ESTIMATEUR_PROFILE_KEY], null);
        toast.error("Votre compte n'est plus accessible");
      }
    },
    onSuccess: ({ estimation, preventRedirect }) => {
      if (!preventRedirect) {
        const redirectUrl = `/estimateur/${estimation.uuid}/result`;
        navigate(redirectUrl);
      }
      queryClient.setQueryData([ESTIMATEUR_PROFILE_KEY], {
        ...profile,
        estimations: [
          estimation,
          ...(profile?.estimations.filter(({ uuid }) => uuid !== estimation.uuid) || []),
        ],
      });
    },
  });

  return {
    profile,
    isLoading,
    submitEstimation,
    deleteEstimation,
    login,
    logout,
    resetPassword,
    forgotPassword,
  };
};
