import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { ChantierPvType } from '@travauxlib/shared/src/types/api/common/ChantierPvView';
import { request, isConflict } from '@travauxlib/shared/src/utils/request';

import { PROJECTS_KEY } from 'features/EspaceClient3/api/useProjects';

type Payload = {
  chantierUuid: string;
  pvType: ChantierPvType;
  pvUuid: string;
  rejectionReason: string;
};

const handleRejectPv = ({ chantierUuid, ...body }: Payload): Promise<void> => {
  const requestUrl = `${APP_CONFIG.apiURL}/client/chantiers/${chantierUuid}/reject-pv`;

  return request(requestUrl, { method: 'POST', body });
};

export const useRejectPv = (): {
  rejectPv: typeof handleRejectPv;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();
  const { mutateAsync: rejectPv, isPending } = useMutation({
    mutationFn: handleRejectPv,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PROJECTS_KEY] });
    },
    onError: (err: Error) => {
      if (isConflict(err)) {
        toast.error('Un autre document a été uploadé!');
      } else {
        toast.error('Un problème est survenu, veuillez réessayer');
      }
    },
  });

  return { rejectPv, isLoading: isPending };
};
