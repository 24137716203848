import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import {
  AdminEventsProperties,
  AppFeatures,
  EspaceClientEventProperties,
  TrackingEvents,
} from '@travauxlib/shared/src/utils/tracking';

class AvancementChantierTrackingClass extends Tracker<AppFeatures> {
  onAvancementChantierValidated = (properties: {
    [EspaceClientEventProperties.ProjectUuid]: string;
    [EspaceClientEventProperties.ProjectName]?: string;
  }): void => {
    this.sendTracking({
      event: TrackingEvents.Validated,
      properties,
    });
  };

  onPlafondEnSavoirPlus(properties: { [AdminEventsProperties.ProjetUUID]: string }): void {
    this.sendTracking({
      event: TrackingEvents.Clicked,
      subFeature: 'PlafondEnSavoirPlus',
      properties,
    });
  }
}

export const AvancementChantierTracking = new AvancementChantierTrackingClass(
  AppFeatures.AvancementChantier,
);
