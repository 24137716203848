import React from 'react';

import capitalize from 'lodash/capitalize';

import Calendar from '@travauxlib/shared/src/components/DesignSystem/assets/Calendar.svg?react';
import CalendarCancel from '@travauxlib/shared/src/components/DesignSystem/assets/CalendarCancelFilled.svg?react';
import DotsMenu from '@travauxlib/shared/src/components/DesignSystem/assets/DotsMenu.svg?react';
import Edit from '@travauxlib/shared/src/components/DesignSystem/assets/EditFilled.svg?react';
import HelpCircle from '@travauxlib/shared/src/components/DesignSystem/assets/HelpCircle.svg?react';
import { ActionList } from '@travauxlib/shared/src/components/DesignSystem/components/ActionList';
import { Badge } from '@travauxlib/shared/src/components/DesignSystem/components/Badge';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { ListItem } from '@travauxlib/shared/src/components/DesignSystem/components/ListItem';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { WithTooltip } from '@travauxlib/shared/src/components/DesignSystem/components/WithTooltip';
import { formatFullFrenchDateTime } from '@travauxlib/shared/src/utils/time';

import { useGetAccount } from 'api/useGetAccount';
import { openCalendly } from 'utils/calendly';

import { useProjectMeetings } from '../api/useProjectMeetings';
import { Meeting } from '../types';

type Props = {
  projectUuid: string;
};

const MeetingEmptyState: React.FC = () => (
  <ListItem
    className="!text-b2 last:border-b-0"
    bodyClassName="!p-xxs"
    size="sm"
    state="filled"
    left={{
      type: 'jsx',
      children: (
        <div className="text-primary flex items-center">
          <div className="ml-sm" />
          <Calendar height={20} width={20} />
        </div>
      ),
    }}
    middle={{ title: <div className="font-medium">Aucun rendez-vous à venir</div> }}
  />
);

export const MeetingsSection: React.FC<Props> = ({ projectUuid }) => {
  const { meetings, persons, isLoading } = useProjectMeetings(projectUuid);
  const { account, isLoading: isAccountLoading } = useGetAccount();

  if (isLoading || isAccountLoading) {
    return <Loader size="sm" />;
  }

  if (!meetings?.length || !persons) {
    return <MeetingEmptyState />;
  }

  return (
    <>
      {meetings.map((meeting: Meeting) => {
        const isCalendlyMeeting = meeting.calendlyRescheduleUrl;

        return (
          <ListItem
            className="!text-b2 last:border-b-0"
            key={meeting.uuid}
            bodyClassName="!p-xxs"
            size="sm"
            state="filled"
            left={{
              type: 'jsx',
              children: (
                <div className="text-primary flex items-center">
                  <Badge value={1} variant="error" size="xs" className="mr-xxs" />
                  <Calendar height={20} width={20} />
                </div>
              ),
            }}
            middle={{
              title: (
                <div className="font-medium">
                  {persons[meeting.inviter]?.fullName ?? <>{meeting.inviter}</>}
                </div>
              ),
              label: capitalize(formatFullFrenchDateTime(meeting.startAt) ?? ''),
            }}
            right={
              isCalendlyMeeting
                ? {
                    type: 'jsx',
                    children: (
                      <ActionList
                        trigger={(toggle, referenceProps) => (
                          <IconButton onClick={toggle} {...referenceProps}>
                            <DotsMenu />
                          </IconButton>
                        )}
                        items={[
                          {
                            type: 'action',
                            icon: <Edit />,
                            label: 'Modifier mon rendez-vous',
                            onClick: () =>
                              openCalendly(
                                account!,
                                meeting.calendlyRescheduleUrl!,
                                'ec3-dashboard',
                              ),
                          },
                          {
                            type: 'action',
                            icon: <CalendarCancel />,
                            label: 'Annuler mon rendez-vous',
                            onClick: () =>
                              openCalendly(account!, meeting.calendlyCancelUrl!, 'ec3-dashboard'),
                          },
                        ]}
                      />
                    ),
                  }
                : {
                    type: 'jsx',
                    children: (
                      <WithTooltip
                        trigger={
                          <IconButton>
                            <HelpCircle />
                          </IconButton>
                        }
                        position="left"
                      >
                        <span>
                          Plus disponible ? Appelez-nous, pour vous proposer une autre date
                        </span>
                      </WithTooltip>
                    ),
                  }
            }
          />
        );
      })}
    </>
  );
};
