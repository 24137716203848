import React from 'react';

import { ManualAddress } from '@travauxlib/shared/src/types/api/common/ManualAddress';

type Props = {
  customerName: string;
  billingAddress: ManualAddress;
  chantierAddress?: ManualAddress;
};

export const DocumentDestinataire: React.FC<Props> = ({
  customerName,
  billingAddress,
  chantierAddress,
}) => (
  <div className="inline-block bg-lighter p-md">
    <p>A l'attention de :</p>
    <div className="font-bold">{customerName}</div>
    <div>
      {billingAddress.address} <br />
      {billingAddress.postalCode} {billingAddress.city}
    </div>
    <br />
    {chantierAddress && chantierAddress.address !== billingAddress.address && (
      <>
        <div className="font-bold">Adresse du chantier :</div>
        {chantierAddress.address} <br />
        {chantierAddress.postalCode} {chantierAddress.city}
      </>
    )}
  </div>
);
