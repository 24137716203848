import React, { useEffect, useState } from 'react';

import ChevronDown from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronDownFilled.svg?react';
import ChevronUp from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronUpFilled.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { Prestations } from '@travauxlib/shared/src/types';
import { RemiseType } from '@travauxlib/shared/src/types/api/domain/prodevisfactures/RemiseType';
import { roundToTwoDecimals } from '@travauxlib/shared/src/utils/format';

import { Marge } from '../Marge';
import { MontantsPaye } from '../MontantsPaye';
import { Primes } from '../Primes';
import { RemisePrimes } from '../RemisePrimes';
import { ResteAPayer } from '../ResteAPayer';
import { TotalHT } from '../TotalHT';
import { TotalTTC } from '../TotalTTC';
import { TotalTVA } from '../TotalTVA';

type Props = {
  activatedMarginCalculation: boolean;
  prestations: Omit<Prestations, 'title' | 'token'>;
  onChange?: (prestations: Partial<Prestations>) => void;
  displayMenu: (value: boolean) => void;
  isMenuDisplayed: boolean;
  hideDetailsTVA?: boolean;
  bottomCta?: React.ReactElement | null;
  isEstimateur?: boolean;
  isHemea?: boolean;
};

const CloseToolbarButton: React.FC<{ handleDisplay: () => void }> = ({ handleDisplay }) => (
  <div className="flex justify-end p-md bg-lighter">
    <IconButton data-testid="close-price-details" onClick={() => handleDisplay()}>
      <ChevronDown />
    </IconButton>
  </div>
);

export const MobileToolbar: React.FC<Props> = ({
  prestations,
  onChange,
  displayMenu,
  isMenuDisplayed,
  hideDetailsTVA,
  bottomCta = null,
  isEstimateur,
  activatedMarginCalculation,
  isHemea,
}) => {
  const {
    prixTotalFournitureHT,
    prixTotalHTAvantRemise,
    prixTotalHT,
    prixTotalTTC,
    prixTotalTTCAvantPrimes,
    primes,
    remises,
    montantsTVA,
    showFournitures,
    montantDejaPaye,
    montantRestantAPayer,
    montantMargeTotale,
    commissionRate,
    lots,
  } = prestations;

  const handleDisplay = (): void => {
    displayMenu(!isMenuDisplayed);
  };

  const [isCommissionHemeaVisible, setIsCommissionHemeaVisible] = useState(false);

  useEffect(() => {
    setIsCommissionHemeaVisible(false);
  }, [prixTotalHT]);

  return (
    <>
      {isMenuDisplayed ? (
        <div className="!flex !items-center !px-md">
          <div className="!flex-grow !pr-md">
            {isEstimateur ? (
              <TotalTTC totalTTC={prixTotalTTCAvantPrimes} />
            ) : (
              <TotalHT
                prixHTAvantRemise={
                  remises.type === RemiseType.Empty ? undefined : prixTotalHTAvantRemise
                }
                totalHT={prixTotalHT}
              />
            )}
          </div>
          <IconButton
            className="mb-xxs"
            data-testid="open-price-details"
            onClick={() => handleDisplay()}
          >
            <ChevronUp />
          </IconButton>
        </div>
      ) : (
        <div>
          {onChange && (
            <div className="border-b !p-md">
              <RemisePrimes prestations={prestations} onChange={onChange} />
            </div>
          )}
          <div className="!p-md border-b !bg-lighter">
            <TotalHT
              showFournitures={showFournitures}
              onChange={onChange}
              prixHTAvantRemise={
                remises.type === RemiseType.Empty ? undefined : prixTotalHTAvantRemise
              }
              totalHT={prixTotalHT}
              prixTotalFournitureHT={prixTotalFournitureHT}
              isEstimateur={isEstimateur}
            />
          </div>
          <div className="bg-lighter py-sm pb-xl overflow-auto">
            <div className="px-md">
              <div className="!mb-lg">
                <TotalTVA hideDetailsTVA={hideDetailsTVA} montantsTVA={montantsTVA} lots={lots} />
              </div>
              <div className="!mb-xs">
                <TotalTTC totalTTC={prixTotalTTCAvantPrimes} />
              </div>
              <Marge
                montantMargeTotale={montantMargeTotale}
                activatedMarginCalculation={activatedMarginCalculation}
                prixTotalHT={prixTotalHT}
              />

              <div className="!mb-md">
                {primes && primes.length !== 0 && (
                  <>
                    <div className="!mb-xs">
                      <Primes primes={primes} />
                    </div>
                    <ResteAPayer amount={prixTotalTTC} />
                  </>
                )}
              </div>
              {montantDejaPaye !== undefined && montantRestantAPayer !== undefined && onChange && (
                <MontantsPaye
                  onChange={onChange}
                  montantDejaPaye={montantDejaPaye}
                  montantRestantAPayer={montantRestantAPayer}
                />
              )}
            </div>
          </div>
          <div>
            {isHemea && !!commissionRate && (
              <div className="bg-lighter p-md border-t border-solid border-gray-300">
                <div className="mb-xxs flex justify-between">
                  <div className="font-bold text-b1">Taux de commission hemea</div>
                  <div>{commissionRate} %</div>
                </div>
                <div className="flex justify-between text-sm">
                  <div>
                    <Link
                      data-testid="display-commission-ht"
                      variant="primary"
                      size="sm"
                      onClick={() =>
                        setIsCommissionHemeaVisible(
                          isCommissionHemeaVisible => !isCommissionHemeaVisible,
                        )
                      }
                    >
                      {isCommissionHemeaVisible
                        ? 'Masquer la commission HT'
                        : 'Afficher la commission HT'}
                    </Link>
                  </div>
                  {isCommissionHemeaVisible && (
                    <div data-testid="commission-hemea-amount">
                      {roundToTwoDecimals((prixTotalHT * commissionRate) / 100)} €
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          {bottomCta}
          <CloseToolbarButton handleDisplay={handleDisplay} />
        </div>
      )}
    </>
  );
};
