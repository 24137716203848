import React, { useState } from 'react';

import Edit from '@travauxlib/shared/src/components/DesignSystem/assets/EditFilled.svg?react';
import Gift from '@travauxlib/shared/src/components/DesignSystem/assets/Gift.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { LotIcon } from '@travauxlib/shared/src/features/DevisDisplay/components/LotIcon';
import { SuiviLotContainer } from '@travauxlib/shared/src/features/SuiviChantier/components/SuiviLotContainer';
import { LigneStatus, SuiviLigneStatus } from '@travauxlib/shared/src/types';

import { AvancementInfo } from './AvancementInfo';
import { ValidateAvancementClient } from './ValidateAvancementClient';

import { SuiviLot } from '../../types';

type Props = {
  suiviLots: SuiviLot[];
};

export const SuiviClientByLots: React.FC<Props> = ({ suiviLots }) => {
  const [editedLigne, setEditedLigne] = useState<string | undefined>(undefined);

  return (
    <div className="flex flex-col sm-desktop:gap-lg gap-md mb-md sm-desktop:mb-3xl">
      {suiviLots.map(lot => (
        <SuiviLotContainer
          key={lot.uuid}
          label={lot.label}
          Icon={() => <LotIcon lotLabel={lot.label} />}
          lignes={lot.lignes}
          validateActionOrTag={ligne => (
            <ValidateAvancementClient
              ligne={ligne}
              namePrefix={`${ligne.lotUuid}.lignes[${ligne.indexInLot}]`}
              isEditing={editedLigne}
              setIsEditing={setEditedLigne}
            />
          )}
          ligneContent={ligne => (
            <>
              {ligne.status === LigneStatus.Free && (
                <Tag label="Offert" variant="success" size="sm" icon={<Gift />} />
              )}
              <AvancementInfo
                ligne={ligne}
                namePrefix={`${ligne.lotUuid}.lignes[${ligne.indexInLot}]`}
              />
              {ligne.suiviStatus === SuiviLigneStatus.Rejected && (
                <Button
                  className="mt-xs"
                  onClick={() => setEditedLigne(ligne.uuid)}
                  leftIcon={<Edit />}
                  variant="tertiary"
                  fullwidth
                  size="sm"
                >
                  Modifier
                </Button>
              )}
            </>
          )}
        />
      ))}
    </div>
  );
};
