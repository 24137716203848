import React, { useCallback } from 'react';

import classNames from 'classnames';
import { Link, useLocation } from 'react-router';

import { Badge } from '@travauxlib/shared/src/components/DesignSystem/components/Badge';
import { layoutGridNegatedLeftMargins } from '@travauxlib/shared/src/components/DesignSystem/components/Layout';
import { BadgeCountView } from '@travauxlib/shared/src/types/api/client/BadgeCountView';

import { NavigationItem, getNavigationItems } from '../utils';

type Props = { projectUuid: string; badges: BadgeCountView };

const SideBarItem: React.FC<NavigationItem> = ({ Icon, label, path, isActive, badgeCount }) => (
  <Link
    className={classNames(
      'flex items-center gap-xxs px-md py-sm text-neutral-800 rounded-sm !no-underline',
      'hover:bg-neutral-100 hover:text-neutral-800 active:bg-neutral-200 active:text-neutral-900',
      {
        '!text-primary-400 bg-neutral-100': isActive,
      },
    )}
    to={path}
  >
    <Icon className="w-lg h-lg" />
    <span className="text-b1 font-bold grow">{label}</span>
    {badgeCount > 0 && <Badge className="ml-xxs" size="sm" variant="error" value={badgeCount} />}
  </Link>
);

export const SideBar: React.FC<Props> = ({ projectUuid, badges }) => {
  const location = useLocation();
  const navigationItems = useCallback(
    () => getNavigationItems(projectUuid, location.pathname, badges),
    [projectUuid, location.pathname, badges],
  );

  return (
    <div
      className={classNames(
        layoutGridNegatedLeftMargins,
        'hidden flex-col gap-xxs mr-0 p-md bg-neutral-0 max-h-screen sticky top-3xl z-20 min-w-[15.5rem] h-[calc(100dvh-4rem)]',
        'sm-desktop:flex sm-desktop:col-span-3 sm-desktop:mr-lg',
        'md-desktop:col-span-2 md-desktop:mr-0',
        'lg-desktop:col-span-1',
      )}
    >
      {navigationItems().map(navigationItem => (
        <SideBarItem key={navigationItem.label} {...navigationItem} />
      ))}
    </div>
  );
};
