import React from 'react';

import { useParams } from 'react-router';

import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { SuiviView } from '@travauxlib/shared/src/types/api/client/SuiviView';

import { NotFound } from 'features/NotFoundPage';

import { useSuiviChantier } from '../api/useSuiviChantier';

export const WithSuiviChantier = <P extends object>(
  Component: React.ComponentType<P & { suiviChantier: SuiviView }>,
): React.ComponentType<P> => {
  const Wrapper = (props: P): React.ReactElement => {
    const { token } = useParams();
    const { isLoading, suiviChantier } = useSuiviChantier(token!);
    if (isLoading) {
      return <Loader />;
    }
    if (!suiviChantier) {
      return <NotFound />;
    }
    return <Component {...props} suiviChantier={suiviChantier} />;
  };
  return Wrapper;
};
