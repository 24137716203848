import React from 'react';

import { Navigate, Route, Routes } from 'react-router';

import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';

import { CreateEditReference } from 'features/EspaceClient3/features/Fournitures/components/CreateEditReference';
import { Guides } from 'features/EspaceClient3/features/Guides';
import { WithFacture } from 'features/Factures/hocs/WithFacture';
import { FactureShow } from 'features/Factures/Loadable';

import { BetterNavigate } from '../components/BetterNavigate';
import { ChipsNavigation } from '../components/ChipsNavigation';
import { ClientCustomerSatisfactionPage } from '../components/ClientCustomerSatisfactionPage';
import { EmptyDocuments } from '../components/EmptyDocuments';
import { Layout } from '../components/Layout';
import { RedirectSuiviChantier } from '../components/RedirectSuiviChantier';
import { RedirectToLatestProject } from '../components/RedirectToLatestProject';
import { EmptyChantier } from '../features/Chantier/components/EmptyChantier';
import { DeleteAccount } from '../features/DeleteAccount/Loadable';
import { DevisFactures } from '../features/DevisFactures';
import { AllDevis } from '../features/DevisFactures/components/AllDevis';
import { AllFactures } from '../features/DevisFactures/components/AllFactures';
import { Details } from '../features/DevisFactures/components/Details';
import { Etapes } from '../features/Etapes';
import { Pv } from '../features/FinDeChantier/components/Pv';
import { Fournitures } from '../features/Fournitures';
import { Index } from '../features/Index';
import { Paiement } from '../features/Paiement';
import { EmptyPayment } from '../features/Paiement/components/EmptyPayment';
import { SuiviChantierClient } from '../features/SuiviChantier';
import { SuiviChantierHistory } from '../features/SuiviChantier/features/ReadOnly/Loadable';
import { SuiviChantierRecap } from '../features/SuiviChantierRecap';
import { routeCustomerSatisfaction, routePaiements } from '../utils';

const FacturesWithData = WithFacture(FactureShow);
// TODO tout mettre dans app/routes pour que ça soit plus centralisé?
export const EspaceClient3Routes: React.FC = () => (
  <Routes>
    <Route path="projets/:uuid" element={<Layout />}>
      <Route
        path="redirect/suivi-chantier/:suiviChantierUuid"
        element={<RedirectSuiviChantier />}
      />
      <Route index element={<Index />} />
      <Route path="etapes" element={<Etapes />} />
      <Route
        path={routePaiements}
        element={
          <ChipsNavigation
            entityType="paiements"
            entityUrlGetter="chantierUuid"
            buildNavigationUrl={entityUUid => `${entityUUid}`}
            emptyState={<EmptyPayment />}
          />
        }
      >
        <Route path="" element={<Loader />} />
        <Route path=":chantierUuid" element={<Paiement />} />
      </Route>
      <Route
        path="chantiers"
        element={
          <ChipsNavigation
            entityType="suivi-chantier"
            entityUrlGetter="chantierUuid"
            buildNavigationUrl={entityUuid => `${entityUuid}/suivi-chantier-recap`}
            emptyState={<EmptyChantier />}
            hideForPaths={['suivi-chantier/', 'suivi-chantier-history/', 'fournitures']}
          />
        }
      >
        <Route path="" element={<Loader />} />
        <Route path=":chantierUuid">
          <Route path="suivi-chantier-recap/:lotLabel?" element={<SuiviChantierRecap />} />
          <Route path="fournitures" element={<Fournitures />} />
          <Route path="fournitures/:fournitureUuid/reference" element={<CreateEditReference />} />
          <Route
            path="suivi-chantier-history/:suiviChantierUuid"
            element={<SuiviChantierHistory />}
          />
          <Route path="suivi-chantier/:suiviChantierUuid" element={<SuiviChantierClient />} />
        </Route>
      </Route>
      <Route
        path="documents"
        element={
          <ChipsNavigation
            entityType="devis-factures"
            entityUrlGetter="proCompanyUuid"
            buildNavigationUrl={entityUuid => `entreprises/${entityUuid}`}
            emptyState={<EmptyDocuments />}
          />
        }
      >
        <Route path="" element={<Loader />} />
        {/* TODO définir diff entre Tous mes devis & Mes anciens devis */}
        <Route path="all" element="Tous mes devis du moment? ceux envoyés + signés?" />
        <Route path="history" element="Mes anciens devis" />
        <Route path="entreprises/:proCompanyUuid">
          <Route path="" element={<DevisFactures />} />
          <Route path="devis" element={<AllDevis />} />
          <Route path="projets-pro/:proProjectUuid" element={<Details />} />
          <Route path="factures">
            <Route path="" element={<AllFactures />} />
            <Route path=":token" element={<FacturesWithData />} />
          </Route>
        </Route>
        <Route path=":documentType" element="voir les photos" />
      </Route>
      <Route path="documents/entreprises/:proCompanyUuid/pvs/:pvType" element={<Pv />} />
      <Route path="guides">
        <Route path="" element={<Guides.Index />} />
        <Route path=":guide" element={<Guides.Guide />} />
      </Route>
      <Route path={routeCustomerSatisfaction} element={<ClientCustomerSatisfactionPage />} />
    </Route>
    <Route path="delete-account" element={<DeleteAccount />} />
    <Route path="*" element={<RedirectToLatestProject />} />
    {/* Legacy routes redirect */}
    <Route path="espace-client">
      <Route path="projets/:uuid">
        <Route
          index
          element={<BetterNavigate buildRedirectUrl={({ uuid }) => `/projets/${uuid}`} />}
        />
        <Route
          path="documents"
          element={<BetterNavigate buildRedirectUrl={({ uuid }) => `/projets/${uuid}/documents`} />}
        />
        <Route
          path="devis-factures"
          element={<BetterNavigate buildRedirectUrl={({ uuid }) => `/projets/${uuid}/documents`} />}
        />
        <Route
          path="chantiers/:chantierUuid"
          element={
            <BetterNavigate
              buildRedirectUrl={({ uuid, chantierUuid }) =>
                `/projets/${uuid}/paiements/${chantierUuid}`
              }
            />
          }
        />
        <Route
          path="chantiers/:chantierUuid/suivi-chantier/:suiviChantierUuid/history"
          element={
            <BetterNavigate
              buildRedirectUrl={({ uuid, chantierUuid, suiviChantierUuid }) =>
                `/projets/${uuid}/chantiers/${chantierUuid}/suivi-chantier-history/${suiviChantierUuid}`
              }
            />
          }
        />
        <Route
          path="chantiers/:chantierUuid/suivi-chantier/:suiviChantierUuid"
          element={
            <BetterNavigate
              buildRedirectUrl={({ uuid, chantierUuid, suiviChantierUuid }) =>
                `/projets/${uuid}/chantiers/${chantierUuid}/suivi-chantier/${suiviChantierUuid}`
              }
            />
          }
        />
        <Route
          path="chantiers/:chantierUuid/suivi-chantier-summary"
          element={
            <BetterNavigate
              buildRedirectUrl={({ uuid, chantierUuid }) =>
                `/projets/${uuid}/chantiers/${chantierUuid}/suivi-chantier-recap`
              }
            />
          }
        />
        <Route
          path="chantiers/:chantierUuid/suivi-chantier-summary/:lotUuid?"
          element={
            <BetterNavigate
              buildRedirectUrl={({ uuid, chantierUuid, lotUuid }) =>
                `/projets/${uuid}/chantiers/${chantierUuid}/suivi-chantier-recap/${lotUuid}`
              }
            />
          }
        />
      </Route>
      <Route path="guides" element={<Navigate to="projects" />} />
      <Route index element={<Navigate to="/projets" replace />} />
    </Route>
  </Routes>
);
