import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import {
  AppFeatures,
  AppListeFournituresSubFeatures,
  EspaceClientEventProperties,
  ListeFournituresEventProperties,
  TrackingEvents,
} from '@travauxlib/shared/src/utils/tracking';

class ListeFournituresTrackingClass extends Tracker<AppFeatures, AppListeFournituresSubFeatures> {
  onFournituresListOpened = (properties: {
    [EspaceClientEventProperties.ProjectUuid]: string;
  }): void => {
    this.sendTracking({
      event: TrackingEvents.Opened,
      properties,
    });
  };

  onFournituresListReferenceAdded = (properties: {
    [EspaceClientEventProperties.ProjectUuid]: string;
    [ListeFournituresEventProperties.IsFromScrap]: boolean;
  }): void => {
    this.sendTracking({
      event: TrackingEvents.Added,
      subFeature: AppListeFournituresSubFeatures.Reference,
      properties,
    });
  };

  onFournituresListReferenceEdited = (properties: {
    [EspaceClientEventProperties.ProjectUuid]: string;
    [ListeFournituresEventProperties.IsFromScrap]: boolean;
  }): void => {
    this.sendTracking({
      event: TrackingEvents.Edited,
      subFeature: AppListeFournituresSubFeatures.Reference,
      properties,
    });
  };
}

export const ListeFournituresTracking = new ListeFournituresTrackingClass(
  AppFeatures.ListeFournitures,
);
