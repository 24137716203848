import React from 'react';

import { SuiviView } from '@travauxlib/shared/src/types/api/client/SuiviView';
import { getCompletionFromLignes } from '@travauxlib/shared/src/utils/getCompletionFromLignes';

import { SuiviChantierContentHeader } from './SuiviChantierContentHeader';
import { SuiviLotClient } from './SuiviLotClient';

export type Props = {
  suiviChantier: SuiviView;
};

export const SuiviChantierContent: React.FC<Props> = ({ suiviChantier }) => {
  const completion = getCompletionFromLignes(suiviChantier.lots.flatMap(lot => lot.lignes));
  return (
    <div className="px-md sm-desktop:px-xl w-full sm-desktop:pt-[3.5rem] py-lg">
      <SuiviChantierContentHeader
        completionPercentage={completion.percentage}
        suiviChantier={suiviChantier}
      />
      <div className="text-h4 pb-md">Détails des prestations</div>
      <div className="mb-lg">
        {suiviChantier.lots.map((lot, lotIndex) => (
          <SuiviLotClient lot={lot} lotIndex={lotIndex} key={lot.label} />
        ))}
      </div>
    </div>
  );
};
