import { DevisView } from '@travauxlib/shared/src/types/api/client/DevisView';
import { TypeSuivi } from '@travauxlib/shared/src/types/api/common/TypeSuivi';

export const computePrixWithPrestation =
  (devis: DevisView, typeSuivi: TypeSuivi) =>
  (prix: number): number => {
    switch (typeSuivi) {
      case TypeSuivi.TravauxPlanner:
        return devis.modalitesHemea.prixTravauxPlannerTTC + prix;
      case TypeSuivi.TravauxPlannerWithService:
        return (
          devis.modalitesHemea.prixTravauxPlannerTTC +
          devis.modalitesHemea.prixServiceHemeaTTC +
          prix
        );
      case TypeSuivi.ServiceHemea:
        return devis.modalitesHemea.prixServiceHemeaTTC + prix;
      default:
        return prix;
    }
  };
