import React, { useEffect } from 'react';

import { useParams } from 'react-router';

import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { MontantGeleCard } from '@travauxlib/shared/src/features/SuiviChantier/components/MontantGeleCard';

import { useProject } from 'features/EspaceClient3/api/useProject';
import { EspaceClientTracking } from 'utils/tracking/EspaceClientTracking';

import { ChantierSolde } from './components/ChantierSolde';
import { Events } from './components/Events';
import { History } from './components/History';
import { LiberationFondsEnCoursCard } from './components/LiberationFondsEnCoursCard';
import { SuiviFinancier } from './components/SuiviFinancier';

export const Paiement: React.FC = () => {
  const { uuid, chantierUuid } = useParams<{ uuid: string; chantierUuid: string }>();
  const { project, isLoading } = useProject(uuid!);
  useEffect(() => {
    if (project) {
      EspaceClientTracking.onEspaceClientPaymentViewed({
        'Project Uuid': project.uuid,
        'Project Name': project.title,
        Empty: false,
      });
    }
  }, [project]);

  if (isLoading) {
    return <Loader />;
  }

  const { chantiers } = project!;

  const chantier = chantiers.find(chantier => chantier.uuid === chantierUuid)!;

  const { appelDePaiementValidatedAmount } = chantier;

  return (
    <div className="flex flex-col gap-md mt-md">
      <Events chantier={chantier} />
      <ChantierSolde chantier={chantier} />
      {(chantier.receptionChantierThreshold || chantier.leveeReservesThreshold) && (
        <div className="mx-md sm-desktop:mx-0">
          <MontantGeleCard
            montantGele={chantier.montantGele}
            pv={chantier.pv}
            currentThreshold={chantier.currentThreshold}
            pourcentageAvancementGlobal={chantier.pourcentageAvancementGlobal}
            receptionChantierThreshold={chantier.receptionChantierThreshold}
            leveeReservesThreshold={chantier.leveeReservesThreshold}
          />
        </div>
      )}
      {appelDePaiementValidatedAmount > 0 && (
        <LiberationFondsEnCoursCard
          appelDePaiementValidatedAmount={appelDePaiementValidatedAmount}
        />
      )}
      <SuiviFinancier chantier={chantier} />
      <History
        transactions={chantier.transactions}
        buildNavigationUrl={suiviChantierUuid =>
          `/projets/${uuid}/chantiers/${chantierUuid}/suivi-chantier-history/${suiviChantierUuid}`
        }
      />
    </div>
  );
};
