import TagManager from 'react-gtm-module';

import { request } from '@travauxlib/shared/src/utils/request';

export type FacebookPixelUserInfo = {
  email?: string;
  firstName?: string;
  lastName?: string;
};

export const sendEventToFacebookPixel = ({
  event,
  endpointUrl,
  userInfo,
}: TrackingInfo): Promise<void> =>
  request(endpointUrl, { method: 'POST', body: { event, ...userInfo } });

export const sendEventToGTM = (event: string, data?: object): void =>
  TagManager.dataLayer({
    dataLayer: {
      event: event,
      ...data,
    },
  });

export type TrackingEndpoint = `${string}/${'client' | 'pro'}/tracking`;
type TrackingData = {
  category: string;
  action?: string;
  label?: string;
};
export type TrackingInfo = {
  event: string;
  endpointUrl: TrackingEndpoint;
  userInfo?: FacebookPixelUserInfo;
  data?: TrackingData;
};

export const sendEventToTrackingModules = ({
  event,
  endpointUrl,
  userInfo,
  data,
}: TrackingInfo): void => {
  sendEventToGTM(event, data);
  sendEventToFacebookPixel({ event, endpointUrl, userInfo });
};
