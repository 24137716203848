import React from 'react';

import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd';

import { DevisLocation } from '@travauxlib/shared/src/types/api/domain/prodevisfactures/DevisLocation';
import { changeItemIndex } from '@travauxlib/shared/src/utils/listMethods';

import { LocationRow } from './LocationRow';

type Props = {
  locations: DevisLocation[];
  onChange: (locations: DevisLocation[]) => void;
  onDeleteLocation: (deletedLocations: DevisLocation) => void;
};
export function LocationsList({
  locations,
  onChange,
  onDeleteLocation,
}: Props): React.ReactElement {
  const handleDrop = ({ source, destination }: DropResult): void => {
    if (destination && destination.index !== source.index) {
      const newLocations = changeItemIndex(locations, source.index, destination.index);
      onChange(newLocations);
    }
  };

  const handleEditLocation = (editedLocation: DevisLocation): void => {
    const newLocations = locations.map(location =>
      editedLocation.uuid === location.uuid ? editedLocation : location,
    );
    onChange(newLocations);
  };

  return (
    <>
      {locations.length === 0 ? (
        <div className="!flex !h-full !items-center !justify-center !flex-col !italic">
          <span className="!mb-xxs">Cliquez sur une localisation</span>
          <span>pour commencer</span>
        </div>
      ) : (
        <DragDropContext onDragEnd={handleDrop}>
          <Droppable
            droppableId="droppable"
            isDropDisabled={false}
            isCombineEnabled={false}
            ignoreContainerClipping={false}
          >
            {provided => (
              <div ref={provided.innerRef}>
                {locations.map((location, index) => (
                  <Draggable index={index} key={location.uuid} draggableId={location.uuid}>
                    {(provided, { isDragging }) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <LocationRow
                          location={location}
                          onDeleteLocation={onDeleteLocation}
                          onEditLocation={handleEditLocation}
                          dragHandleProps={provided.dragHandleProps}
                          isDragging={isDragging}
                          lastRow={locations.length === index + 1}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  );
}
