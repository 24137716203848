import React, { useEffect, useState } from 'react';

import _findLast from 'lodash/findLast';
import _isEmpty from 'lodash/isEmpty';
import { Form, FormSpy } from 'react-final-form';
import { useLocation, useNavigate, useSearchParams } from 'react-router';

import { Helmet } from '@travauxlib/shared/src/components/Helmet';
import { useIsTrackingAuthorized } from '@travauxlib/shared/src/utils/consents/ConsentsContext';

import { useCreateLead } from './api/useCreateLead';
import { ConnectedAsHemeaEmployeeAlertMessage } from './components/ConnectedAsHemeaEmployeeAlertMessage';
import { useProjectStartedLegacyTracking } from './hooks/useProjectStartedLegacyTracking';
import { OnboardingRoutes } from './routes';
import { FormValues } from './types';
import {
  ONBOARDING_STORAGE_KEY,
  onboardingAccountPath,
  onboardingProjectSavedPath,
} from './utils/constants';
import { projectSteps } from './utils/steps/projectSteps';
import { sendLegacyAnswerEventToGTM } from './utils/tracking';

import { useGetAccount } from '../../api/useGetAccount';
import { storage } from '../../utils/storage';

export const OnboardingSolide: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [affiliateToken] = useState(searchParams.get('affiliateToken') || undefined);
  const [isNavigatingToPastQuestion, setIsNavigatingToPastQuestion] = useState<boolean>(false);
  const { account } = useGetAccount();
  const storedOnboarding = storage.get(ONBOARDING_STORAGE_KEY);
  useProjectStartedLegacyTracking(_isEmpty(storedOnboarding));
  const navigate = useNavigate();
  const location = useLocation();
  const createLead = useCreateLead();
  const isTrackingAuthorized = useIsTrackingAuthorized();

  const isAtProjectSavedStep = location.pathname.includes(onboardingProjectSavedPath);

  useEffect(() => {
    if (isAtProjectSavedStep) {
      return;
    }

    const firstStep = projectSteps()[0];
    const isAtFirstStep = location.pathname.includes(firstStep.id);

    if (_isEmpty(storedOnboarding)) {
      if (!isAtFirstStep) {
        navigate(
          { pathname: `${firstStep.id}`, search: searchParams.toString() },
          { replace: true },
        );
      }
      return;
    }

    const lastStepCompleted = _findLast(projectSteps(storedOnboarding), step =>
      Object.keys(storedOnboarding).includes(step.id),
    );
    const hasAnsweredAllQuestions =
      lastStepCompleted?.id === projectSteps(storedOnboarding).at(-1)?.id;

    if (lastStepCompleted) {
      navigate(
        {
          pathname: hasAnsweredAllQuestions
            ? `${onboardingAccountPath}`
            : `${lastStepCompleted.id}`,
          search: searchParams.toString(),
        },
        { replace: true },
      );
    }
  }, []);

  return (
    <>
      {isTrackingAuthorized && (
        <Helmet>
          <script src="https://www.googleoptimize.com/optimize.js?id=GTM-PJ577D7" />
        </Helmet>
      )}
      <Form<FormValues>
        onSubmit={async values => {
          await createLead({ ...values, affiliateToken });
          sendLegacyAnswerEventToGTM('account');
          storage.remove(ONBOARDING_STORAGE_KEY);
        }}
        initialValues={storedOnboarding}
        initialValuesEqual={() => true}
      >
        {({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit} className="h-full bg-neutral-100">
            {account?.hemeaEmployee && <ConnectedAsHemeaEmployeeAlertMessage />}
            <OnboardingRoutes
              values={values}
              setIsNavigatingToPastQuestion={setIsNavigatingToPastQuestion}
              isNavigatingToPastQuestion={isNavigatingToPastQuestion}
            />
            <FormSpy<FormValues & { cgu: boolean }>
              subscription={{ values: true }}
              onChange={({
                values: {
                  firstName,
                  lastName,
                  phoneNumber,
                  email,
                  cgu,
                  optin,
                  ...notSensitiveData
                },
              }) => {
                if (_isEmpty(values)) {
                  return;
                }
                storage.set(ONBOARDING_STORAGE_KEY, notSensitiveData);
              }}
            />
          </form>
        )}
      </Form>
    </>
  );
};
