import { useEffect } from 'react';

import { useSearchParams } from 'react-router';

import { useGetAccount } from 'api/useGetAccount';
import { Project } from 'features/EspaceClient3/types';
import { openCalendly } from 'utils/calendly';

export const useOpenCalendlyWatcher = (project?: Project): void => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { account } = useGetAccount();
  const shouldOpenCalendly = searchParams.get('openCalendly') !== null;

  return useEffect(() => {
    if (project?.calendlyUrl && account && shouldOpenCalendly) {
      openCalendly(account, project.calendlyUrl, 'ec3-action');
      setSearchParams('', { replace: true });
    }
  }, [shouldOpenCalendly, project, account]);
};
