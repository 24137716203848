import React from 'react';

import { Field } from 'react-final-form';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { NumberInput } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput';
import { DevisLocation } from '@travauxlib/shared/src/types/api/domain/prodevisfactures/DevisLocation';
import { roundToTwoDecimals } from '@travauxlib/shared/src/utils/format';

type Props = {
  valid: boolean;
};

export const EnterSurfaceForLocations: React.FC<Props> = ({ valid }) => (
  <>
    <div>
      <h3 className="font-bold mb-sm">Renseignez la surface de vos pièces</h3>
      <Field
        name="locations"
        render={({
          input: { onChange, value: locations = [] },
        }: {
          input: {
            onChange: (newValue: DevisLocation[]) => void;
            value: DevisLocation[];
          };
        }) => (
          <div>
            {locations.map(location => (
              <div
                key={location.uuid}
                className="!bg-white !flex !flex-col sm-desktop:!flex-row !mb-sm items-stretch !p-xs !rounded"
              >
                <div className="!flex-grow !text-center sm-desktop:!mr-sm !mb-xs sm-desktop:!mb-0 leading-[2rem]">
                  <div className="!text-left !h-full">{location.label}</div>
                </div>
                <div className="flex-shrink !mb-sm sm-desktop:!mb-0">
                  <NumberInput
                    id={location.label}
                    label="Surface"
                    data-testid="location-input"
                    min={0}
                    value={location.surface}
                    suffix="m²"
                    onChange={(surface = 0) =>
                      onChange(
                        locations.map(l =>
                          l.uuid === location.uuid ? { ...location, surface } : l,
                        ),
                      )
                    }
                  />
                </div>
              </div>
            ))}
            <div className="text-right pr-lg">
              <span className="mr-xs">Total</span>
              <strong>
                {roundToTwoDecimals(locations.reduce((acc, location) => acc + location.surface, 0))}{' '}
                m²
              </strong>
            </div>
          </div>
        )}
      />
      <div className="text-right mt-xl">
        <Button type="submit" disabled={!valid}>
          Valider
        </Button>
      </div>
    </div>
  </>
);
