import React from 'react';

import AutorenewCircleFilled from '@travauxlib/shared/src/components/DesignSystem/assets/AutorenewCircleFilled.svg?react';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { ColoredCircle } from '@travauxlib/shared/src/components/DesignSystem/components/ColoredCircle';
import { SuiviLigne } from '@travauxlib/shared/src/features/SuiviChantier/types';

import { hasLigneChanged } from '../utils';

type Props = {
  ligne: SuiviLigne;
  index: number;
  validateActionOrTag?: React.ReactNode;
  children: React.ReactNode;
  shouldDisplayChangedIndicators?: boolean;
};

export const SuiviLigneContainer: React.FC<Props> = ({
  ligne,
  index,
  validateActionOrTag,
  children,
  shouldDisplayChangedIndicators,
}) => (
  <Card
    className="!rounded-none sm-desktop:rounded-xs sm-desktop:border-0 border-b last:border-0"
    bodyClassNames="flex"
    role="section"
    aria-label={ligne.designation}
  >
    <ColoredCircle className="shrink-0 font-bold mr-xxs relative" size="xs" variant="beige">
      <>
        {index}
        {shouldDisplayChangedIndicators && hasLigneChanged(ligne) && (
          <div className="absolute w-sm h-sm text-info -top-xxs -right-xxs flex">
            <AutorenewCircleFilled />
          </div>
        )}
      </>
    </ColoredCircle>
    <div className="grow">
      <div className="flex justify-between items-start mb-xxs gap-xs">
        <div className="text-b1 font-medium">{ligne.designation}</div>
        {validateActionOrTag}
      </div>
      {children}
    </div>
  </Card>
);
