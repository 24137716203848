import React from 'react';

import { useField, useForm } from 'react-final-form';

import RightChevron from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronRightFilled.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { TextareaField } from '@travauxlib/shared/src/components/DesignSystem/components/Textarea/Field';

import { DEFAULT_ROW_NUMBER } from 'features/OnboardingSolide/utils/constants';

type Props = {
  id: string;
  label: string;
  setNextStep: () => void;
  multiLine?: boolean;
  isSkippable?: boolean;
};

export const InputQuestion: React.FC<Props> = ({
  id,
  label,
  setNextStep,
  multiLine,
  isSkippable,
}) => {
  const form = useForm();
  const inputField = useField(id);
  const inputValue = inputField.input.value;

  const onNext = (): void => {
    if (!inputValue) {
      form.change(id, '');
    }

    return isSkippable ? setNextStep() : inputValue && setNextStep();
  };

  return (
    <div className="flex flex-col">
      {multiLine ? (
        <TextareaField
          rows={DEFAULT_ROW_NUMBER}
          className="mb-xl"
          name={id}
          id={id}
          label={label}
          helperText={isSkippable ? 'Optionnel' : ''}
          initialValue=""
        />
      ) : (
        <InputField
          className="mb-xl"
          name={id}
          id={id}
          label={label}
          helperText={isSkippable ? 'Optionnel' : ''}
          initialValue=""
          onKeyPress={event => {
            if (event.key === 'Enter') {
              event.preventDefault();
              return onNext();
            }
          }}
        />
      )}
      <div className="self-end">
        {isSkippable && (
          <Button className="mr-md" variant="secondary" onClick={onNext}>
            Passer
          </Button>
        )}
        <Button
          rightIcon={<RightChevron />}
          onClick={onNext}
          disabled={!isSkippable && !inputValue}
        >
          Suivant
        </Button>
      </div>
    </div>
  );
};
