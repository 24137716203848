import React from 'react';

import classNames from 'classnames';
import _groupBy from 'lodash/groupBy';
import { Form } from 'react-final-form';
import { useNavigate, useParams } from 'react-router';

import { Badge } from '@travauxlib/shared/src/components/DesignSystem/components/Badge';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { LotIcon } from '@travauxlib/shared/src/features/DevisDisplay/components/LotIcon';
import { SuiviClientSummaryByDevis } from '@travauxlib/shared/src/features/SuiviChantier/components/ClientDisplay/SuiviClientSummaryByDevis';
import { Header } from '@travauxlib/shared/src/features/SuiviChantier/components/Header';
import { SummaryLotRow } from '@travauxlib/shared/src/features/SuiviChantier/features/Suivi/components/MainSuivi/SummaryLotRow';
import { useSuiviInitialValues } from '@travauxlib/shared/src/features/SuiviChantier/hooks/useSuiviInitialValues';
import {
  getAvancementForLots,
  parseFormDataToGetLots,
} from '@travauxlib/shared/src/features/SuiviChantier/utils';

import { useBadges } from 'features/EspaceClient3/api/useBadges';
import { useFournitures } from 'features/EspaceClient3/features/Fournitures/api/useFournitures';
import { GoToFournituresListCard } from 'features/EspaceClient3/features/Fournitures/components/GoToFournituresListCard';

import { useSuiviChantierInitState } from '../api/useSuiviChantierState';

type Props = { chantierUuid: string; projectUuid: string };

export const MainRecap: React.FC<Props> = ({ chantierUuid, projectUuid }) => {
  const navigate = useNavigate();
  const { lotLabel } = useParams<{ lotLabel: string | undefined }>();
  const { fournitures } = useFournitures(projectUuid);
  const {
    badges: { chantiers: fournitureBadgeCount },
  } = useBadges(projectUuid);

  const { suiviChantierInitState, isLoading: isSuiviChantierInitStateLoading } =
    useSuiviChantierInitState(chantierUuid);

  const initialValues = useSuiviInitialValues({
    devisList: suiviChantierInitState?.devisList,
    withIsModifyingAnotherLigne: true,
    withPreviousCycle: true,
  });

  if (isSuiviChantierInitStateLoading) {
    return <Loader />;
  }

  const lots = parseFormDataToGetLots(initialValues);
  const groupedLotsByLabel = _groupBy(lots ?? [], 'label');
  const foundLots = lotLabel ? groupedLotsByLabel[decodeURIComponent(lotLabel)] : undefined;
  const title = lotLabel ? 'État d’avancement' : 'Détails de l’avancement';
  if (!lots) {
    return <Loader />;
  }

  return (
    <>
      {lotLabel && (
        <div className="top-0 w-full absolute left-0 z-50">
          <Header
            onClick={() => navigate('..', { relative: 'path' })}
            bigIcon={<LotIcon className="w-lg" lotLabel={lotLabel} />}
            title="Vous consultez"
            subTitle={lotLabel ?? ''}
            pourcentageAvancement={lots ? getAvancementForLots(Object.values(lots)) : 0}
          />
        </div>
      )}
      {!lotLabel && fournitures.length > 0 && (
        <>
          <div className="flex items-center gap-xxs text-h5 sm-desktop:text-h4 font-bold text-neutral-800 mb-sm mx-md sm-desktop:mx-0 mt-md sm-desktop:mt-0">
            Fournitures
            {fournitureBadgeCount > 0 && (
              <Badge value={fournitureBadgeCount} variant="error" size="sm" />
            )}
          </div>
          <GoToFournituresListCard />
        </>
      )}

      <div className="bg-neutral-100">
        <div className="overflow-auto mx-0">
          <div className="m-md sm-desktop:my-sm sm-desktop:mx-0">
            <div className="text-h5 sm-desktop:text-h4 font-bold text-neutral-800">{title}</div>
            <div
              className={classNames('text-h5 sm-desktop:text-h4 font-medium text-neutral-700', {
                hidden: !lotLabel,
              })}
            >
              Consultez l’état d’avancement des prestations
            </div>
          </div>
          <Form onSubmit={() => {}}>
            {() =>
              foundLots ? (
                /* Technique de sioux des temps anciens pour adapter les breakpoints. Jeune padawan ne supprime pas */
                <div className="grid grid-cols-9">
                  <div className="col-span-full sm-desktop:col-span-6 md-desktop:col-span-full">
                    <SuiviClientSummaryByDevis suiviLots={foundLots} />
                  </div>
                </div>
              ) : (
                <div>
                  {Object.entries(groupedLotsByLabel).map(([lotLabel, lots]) => (
                    <SummaryLotRow
                      key={lotLabel}
                      label={lotLabel}
                      pourcentageAvancement={getAvancementForLots(lots)}
                      onClick={() => navigate(encodeURIComponent(lotLabel))}
                      devisNumbers={lots.map(lot => lot.devisNumber)}
                    />
                  ))}
                </div>
              )
            }
          </Form>
        </div>
      </div>
    </>
  );
};
