import React from 'react';

import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import { FacturePrestationsSummary } from 'components/DevisFactures/FacturePrestationsSummary';
import { DocumentHeaderWithPro } from 'components/DocumentHeader/DocumentHeaderWithPro';
import { Facture, CDPLContext } from 'types';

import { FactureLayout } from './FactureLayout';

type Props = {
  facture: Facture;
  cdplContext: CDPLContext;
  isPrint?: boolean;
};

export const AdminFactureShow: React.FC<Props> = ({ facture, cdplContext, isPrint }) => {
  const { numero, dateEmission } = facture;

  const { cdplInformation, email, mobilePhoneNumber, fullName } = cdplContext.person;

  return (
    <FactureLayout
      facture={facture}
      header={
        cdplInformation ? (
          <DocumentHeaderWithPro
            companyInfo={
              {
                ...cdplInformation,
                email,
                phoneNumber: mobilePhoneNumber!,
                name: fullName,
              } as any
            }
            destinataire={{
              customerName: 'hemea',
              billingAddress: {
                address: '7 rue Broca',
                city: 'Paris',
                postalCode: '75005',
              },
            }}
            proCompanyCertificationsRGE={[]}
            title={numero && `Facture n°${numero}`}
            dateBlock={<span>En date du {formatFrenchDate(dateEmission)}</span>}
          />
        ) : null
      }
      customerName="hemea"
      proCompanyName={fullName}
      isPrint={isPrint}
    >
      <div className="break-inside-avoid !pt-xl">
        <FacturePrestationsSummary facture={facture} linkedFactures={[]} />
      </div>
    </FactureLayout>
  );
};
