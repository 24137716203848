import React, { useState } from 'react';

import classNames from 'classnames';

import DrawFilled from '@travauxlib/shared/src/components/DesignSystem/assets/DrawFilled.svg?react';
import Project from '@travauxlib/shared/src/components/DesignSystem/assets/Project.svg?react';
import { ColoredCircle } from '@travauxlib/shared/src/components/DesignSystem/components/ColoredCircle';
import { RadioButtonsList } from '@travauxlib/shared/src/components/DesignSystem/components/RadioButtonsList';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';

import { CommissionMLM } from 'features/EstimateurCDPL/types';
import { calculateTotalRevenue } from 'features/EstimateurCDPL/utils/calculateTotalRevenue';
import { YearResult } from 'features/EstimateurCDPL/utils/constants';

type Props = {
  year: number;
  result: YearResult;
};

const years = ['Première', 'Deuxième', 'Troisième'];

export const YearlyRevenueItem: React.FC<Props> = ({ year, result }) => {
  const [mlm, setMlm] = useState<CommissionMLM>(CommissionMLM.MLM5);

  const options = Object.keys(CommissionMLM).map((key: CommissionMLM, index) => ({
    label: index === 0 ? 'Aucun' : `${index * 5} Filleuls`,
    value: key,
  }));

  return (
    <div className="flex-grow flex flex-col bg-neutral-0 px-sm sm-desktop:px-lg pb-md pt-xxl shadow-sm rounded-sm animate-jump-in animate-duration-[450ms] relative">
      <div
        className={classNames(
          'px-xs sm-desktop:rounded-tl font-[Recoleta] rounded-br-xxs text-b1 text-primary mb-xxs w-fit h-lg bg-beige-50 absolute left-0 top-0',
        )}
      >
        {`${years[year]} année`}{' '}
      </div>

      <div className="text-neutral-200 text-h4 sm-desktop:text-h3 text-neutral-900 mb-xs font-[Recoleta]">
        Chiffre d'affaire chantier
      </div>
      <div className="flex flex-col gap-lg mb-xxl">
        <div className="flex items-center rounded-md">
          <ColoredCircle variant="beige" size="lg" className="mr-xs">
            <DrawFilled />
          </ColoredCircle>
          <div>
            <div className="text-b1 text-primary font-[RecoletaAlt]">Commission chantier</div>
            <div className="text-b2 font-bold">
              <EURCurrency amount={result.commissionSignature} />
            </div>{' '}
          </div>
        </div>
        <div className="flex items-center rounded-md">
          <ColoredCircle variant="beige" size="lg" className="mr-xs">
            <Project />
          </ColoredCircle>
          <div>
            <div className="text-b1 text-primary font-[RecoletaAlt]">Suivi chantier</div>
            <div className="text-b2 font-bold ">
              <EURCurrency amount={result.commissionTP} />
            </div>{' '}
          </div>
        </div>
      </div>
      <div className="text-neutral-200 text-h4 sm-desktop:text-h3 text-neutral-900 mb-xs font-[Recoleta]">
        Réseau MLM
      </div>
      <div className="flex gap-md mb-[2rem] sm-desktop:mb-[3rem]">
        <RadioButtonsList value={mlm} onChange={setMlm} options={options} />
      </div>
      <div className="mt-auto text-h1 sm-desktop:text-xl">
        <EURCurrency
          className="font-[Recoleta] bg-[linear-gradient(#E0CBB8,_#E0CBB8)] bg-no-repeat bg-[left_0rem_top_2.6rem] sm-desktop:bg-[left_0rem_top_3.6rem] bg-[length:100%_0.75rem] sm-desktop:bg-[length:100%_1rem]"
          amount={calculateTotalRevenue(result, mlm)}
        />
      </div>
    </div>
  );
};
