import React from 'react';

import ChantierPicto from '@travauxlib/shared/src/components/DesignSystem/assets/ChantierPicto.svg?react';
import FolderPicto from '@travauxlib/shared/src/components/DesignSystem/assets/FolderPicto.svg?react';
import { NonAdressableReasonSlug } from '@travauxlib/shared/src/types/api/client/ProjectView';

export const nonAddressableExplanations: {
  [K in NonAdressableReasonSlug | 'default']?: {
    illustration: React.ReactNode;
    title: string;
    description: React.ReactNode;
  };
} = {
  hors_zone: {
    illustration: <ChantierPicto />,
    title: 'Désolé, hemea n’est pas encore disponible dans votre région !',
    description: (
      <>
        Nous sommes navrés de ne pas pouvoir vous accompagner dans votre projet et nous espérons
        pouvoir bientôt opérer dans votre région.
        <br />
        En attendant, n’hésitez pas à parcourir notre site pour y retrouver tous nos guides travaux
        et des exemples de réalisations.
      </>
    ),
  },
  score_incompatible: {
    illustration: <FolderPicto />,
    title: 'Nous traitons votre demande',
    description: (
      <>
        Vous venez de compléter une demande d'accompagnement pour votre projet de rénovation.
        <br />
        Nous vous remercions d'avoir fait appel à hemea pour ce projet. Un chef de projet travaux va
        étudier votre demande et vous contactera dans les plus brefs délais.
      </>
    ),
  },
  default: {
    illustration: <ChantierPicto />,
    title: 'Nous ne pouvons malheureusement pas vous accompagner dans votre projet',
    description: (
      <>
        Vous venez de compléter une demande d'accompagnement sur votre projet travaux. Nous sommes
        malheureusement dans l'incapacité de vous accompagner sur votre projet du fait de ses
        caractéristiques. Nous vous souhaitons de mener à bien votre projet dans les meilleures
        conditions.
      </>
    ),
  },
};
