import { useMutation, useQueryClient } from '@tanstack/react-query';

import { TypeSuivi } from '@travauxlib/shared/src/types/api/common/TypeSuivi';
import { request } from '@travauxlib/shared/src/utils/request';

import { DEVIS_KEY } from './useDevisPro';

export type Payload = {
  token: string;
  typeSuivi: TypeSuivi;
};

const handleSetDefaultTypeSuivi = async ({ token, typeSuivi }: Payload): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/devis-pro/${token}/default-type-suivi`;
  await request(requestURL, {
    method: 'POST',
    body: JSON.stringify(typeSuivi),
  });
};

export const useSetDefaultTypeSuivi = (): {
  setDefaultTypeSuivi: typeof handleSetDefaultTypeSuivi;
} => {
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation({
    mutationFn: handleSetDefaultTypeSuivi,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [DEVIS_KEY] });
    },
  });
  return { setDefaultTypeSuivi: mutateAsync };
};
