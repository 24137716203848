import React from 'react';

import DeleteFilled from '@travauxlib/shared/src/components/DesignSystem/assets/DeleteFilled.svg?react';
import Edit from '@travauxlib/shared/src/components/DesignSystem/assets/EditFilled.svg?react';
import { ButtonGroup } from '@travauxlib/shared/src/components/DesignSystem/components/ButtonGroup';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { IconButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { Gamme } from '@travauxlib/shared/src/types/api/common/Gamme';
import { gammeOptions } from '@travauxlib/shared/src/utils/constants';

import { categories } from 'features/Estimateur/features/Questions/utils/categories';
import { Answer } from 'features/Estimateur/types';
import { newEstimationBasePath } from 'features/Estimateur/utils/paths';

type Props = {
  answers: Answer[];
  submitAnswer: (answer: Answer, index?: number) => Promise<unknown>;
  deleteAnswer: (answerIndex: number) => Promise<unknown>;
};

export const PrestationsList: React.FC<Props> = ({ answers, submitAnswer, deleteAnswer }) => (
  <div className="!mb-sm">
    {answers.map((answer, index) => {
      const category = categories.find(({ value }) => value === answer.answerType);
      if (!category) {
        return null;
      }
      const Icon = category.icon;
      return (
        <div className="!mb-xl" key={index}>
          <div className="!flex !justify-between !mb-xxs !items-center">
            <div className="!flex !items-center !mr-sm !flex-shrink-0">
              <Icon width="1.3rem" height="1.3rem" className="!mr-sm" />
              {category.label}
            </div>
            <div className="!flex !flex-shrink-0">
              <IconButtonLink
                to={`../${category.value}/${newEstimationBasePath}/${index}`}
                size="sm"
              >
                <Edit />
              </IconButtonLink>
              <IconButton
                data-testid="delete"
                tabIndex={0}
                onKeyPress={() => deleteAnswer(index)}
                onClick={() => deleteAnswer(index)}
                size="sm"
              >
                <DeleteFilled />
              </IconButton>
            </div>
          </div>
          {answer.gamme && (
            <ButtonGroup
              fullwidth
              onChange={value => value && submitAnswer({ ...answer, gamme: value as Gamme }, index)}
              options={gammeOptions}
              value={answer.gamme}
            />
          )}
        </div>
      );
    })}
  </div>
);
