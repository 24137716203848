import { useQuery } from '@tanstack/react-query';

import { LeadView, DealOwnerView } from '@travauxlib/shared/src/types/api/client/LeadView';
import { request } from '@travauxlib/shared/src/utils/request';

export const useLastLeadCalendlyAndOwner = (): {
  isLoading: boolean;
  dealOwner?: DealOwnerView;
} => {
  const { data, isLoading } = useQuery<LeadView>({
    queryKey: ['lastLead'],
    queryFn: () => request(`${APP_CONFIG.apiURL}/client/lead/last`),
  });

  return {
    isLoading,
    dealOwner: data?.dealOwner,
  };
};
