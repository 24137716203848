import { CommissionMLM, Investissement, Reseau } from '../types';

export const revenueComissionMLM = {
  [CommissionMLM.MLM0]: 0,
  [CommissionMLM.MLM5]: 25625,
  [CommissionMLM.MLM15]: 44375,
  [CommissionMLM.MLM45]: 71375,
};

type Mapping = {
  [key in Investissement]: {
    [key in Reseau]: Result;
  };
};

export type Result = {
  firstYear: YearResult;
  secondYear: YearResult;
  thirdYear: YearResult;
};

export type YearResult = {
  commissionSignature: number;
  commissionTP: number;
};

export const mapping: Mapping = {
  [Investissement.DOUBLE_ACTIVITE]: {
    [Reseau.AUCUN]: {
      firstYear: {
        commissionSignature: 16695,
        commissionTP: 2772,
      },
      secondYear: {
        commissionSignature: 42266,
        commissionTP: 6732,
      },
      thirdYear: {
        commissionSignature: 62100,
        commissionTP: 9504,
      },
    },
    [Reseau.MOYEN]: {
      firstYear: {
        commissionSignature: 23850,
        commissionTP: 3960,
      },
      secondYear: {
        commissionSignature: 42266,
        commissionTP: 6732,
      },
      thirdYear: {
        commissionSignature: 62100,
        commissionTP: 9504,
      },
    },
    [Reseau.GRAND]: {
      firstYear: {
        commissionSignature: 29813,
        commissionTP: 4950,
      },
      secondYear: {
        commissionSignature: 46493,
        commissionTP: 7405.2,
      },
      thirdYear: {
        commissionSignature: 62100,
        commissionTP: 9504,
      },
    },
  },
  [Investissement.EQUILIBRE]: {
    [Reseau.AUCUN]: {
      firstYear: {
        commissionSignature: 27825,
        commissionTP: 4620,
      },
      secondYear: {
        commissionSignature: 70444,
        commissionTP: 11220,
      },
      thirdYear: {
        commissionSignature: 103500,
        commissionTP: 15840,
      },
    },
    [Reseau.MOYEN]: {
      firstYear: {
        commissionSignature: 39750,
        commissionTP: 6600,
      },
      secondYear: {
        commissionSignature: 70444,
        commissionTP: 11220,
      },
      thirdYear: {
        commissionSignature: 103500,
        commissionTP: 15840,
      },
    },
    [Reseau.GRAND]: {
      firstYear: {
        commissionSignature: 49688,
        commissionTP: 8250,
      },
      secondYear: {
        commissionSignature: 77488,
        commissionTP: 12342,
      },
      thirdYear: {
        commissionSignature: 103500,
        commissionTP: 15840,
      },
    },
  },
  [Investissement.COMPLET]: {
    [Reseau.AUCUN]: {
      firstYear: {
        commissionSignature: 30608,
        commissionTP: 5082,
      },
      secondYear: {
        commissionSignature: 91577,
        commissionTP: 14586,
      },
      thirdYear: {
        commissionSignature: 124200,
        commissionTP: 19008,
      },
    },
    [Reseau.MOYEN]: {
      firstYear: {
        commissionSignature: 43725,
        commissionTP: 7260,
      },
      secondYear: {
        commissionSignature: 91577,
        commissionTP: 14586,
      },
      thirdYear: {
        commissionSignature: 124200,
        commissionTP: 19008,
      },
    },
    [Reseau.GRAND]: {
      firstYear: {
        commissionSignature: 54656,
        commissionTP: 9075,
      },
      secondYear: {
        commissionSignature: 100735,
        commissionTP: 16044,
      },
      thirdYear: {
        commissionSignature: 124200,
        commissionTP: 19008,
      },
    },
  },
};

export const tempsInvestiLabels = {
  [Investissement.DOUBLE_ACTIVITE]: 'Je souhaite compléter mon activité',
  [Investissement.EQUILIBRE]: "Je souhaite changer d'activité",
  [Investissement.COMPLET]: 'Je serai investi à 100%',
};

export const reseauLabel = {
  [Reseau.AUCUN]: "Je n'ai pas de réseau",
  [Reseau.MOYEN]: "j'ai un réseau de moins de 10 apporteurs d'affaires",
  [Reseau.GRAND]: "j'ai un réseau de plus de 10 apporteurs d'affaires",
};
