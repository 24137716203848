import React from 'react';

import classNames from 'classnames';

import RightChevron from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronRightFilled.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { Dropdown } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown';
import { Input } from '@travauxlib/shared/src/components/DesignSystem/components/Input';
import { RadioButton } from '@travauxlib/shared/src/components/DesignSystem/components/RadioButton';

import { OnboardingSelectOption, SelectFormattedAnswer } from 'features/OnboardingSolide/types';

type Props = {
  option: OnboardingSelectOption;
  setNextStep: () => void;
  onChange: (value: SelectFormattedAnswer | string) => void;
  selectedOption: string;
  otherAnswer: string;
};

export const SelectOption: React.FC<Props> = ({
  option,
  setNextStep,
  onChange,
  selectedOption,
  otherAnswer,
}) => {
  const { subOptions, label, subLabel, value, isOtherAnswer } = option;

  const isSubOptionSelected = subOptions?.some(subOption => subOption.value === selectedOption);
  const isOptionSelected = value === selectedOption || isSubOptionSelected;
  const isMultiStepQuestion = subOptions || isOtherAnswer;

  const handleChange = (): void => {
    onChange(value);

    if (!isMultiStepQuestion) {
      setNextStep();
    }
  };

  return (
    <div className="py-md border-b last:border-0 flex flex-col">
      <RadioButton
        className={classNames({ '!mb-md': isOptionSelected && isMultiStepQuestion })}
        label={label}
        checked={isOptionSelected ?? false}
        onClick={handleChange}
        // Needed because ReactZgeg complains about it
        onChange={() => {}}
      />
      {isOptionSelected && subOptions && subLabel && (
        <Dropdown
          className="mb-xl"
          label={subLabel}
          id={subLabel}
          options={subOptions}
          value={otherAnswer}
          onChange={selectedValue => onChange({ selectedOption, otherAnswer: selectedValue || '' })}
          autoFocus
          onKeyPress={event => {
            if (event.key === 'Enter') {
              event.preventDefault();
            }
          }}
        />
      )}
      {isOptionSelected && isOtherAnswer && subLabel && (
        <Input
          className="mb-xl"
          label={subLabel}
          id={subLabel}
          value={otherAnswer}
          onChange={value => onChange({ selectedOption, otherAnswer: value || '' })}
          onKeyPress={event => {
            if (event.key === 'Enter') {
              event.preventDefault();
              if (otherAnswer) {
                onChange(otherAnswer);
                setNextStep();
              }
            }
          }}
          autoFocus
        />
      )}
      {isOptionSelected && isMultiStepQuestion && (
        <Button
          className="self-end"
          rightIcon={<RightChevron />}
          onClick={() => {
            if (otherAnswer) {
              onChange(otherAnswer);
            }
            setNextStep();
          }}
          disabled={!otherAnswer}
        >
          Suivant
        </Button>
      )}
    </div>
  );
};
