import React from 'react';

import { Config, FormApi } from 'final-form';
import { Form } from 'react-final-form';
import { useNavigate, useParams } from 'react-router';

import ArrowLeft from '@travauxlib/shared/src/components/DesignSystem/assets/ArrowLeftFilled.svg?react';
import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links/ButtonLink';
import { Gamme } from '@travauxlib/shared/src/types/api/common/Gamme';
import { GoogleMapsAddress } from '@travauxlib/shared/src/types/api/common/GoogleMapsAddress';
import { DevisLocation } from '@travauxlib/shared/src/types/api/domain/prodevisfactures/DevisLocation';

import { Estimation } from 'features/Estimateur/types';

import { EnterSurfaceForLocations } from './EnterSurfaceForLocations';
import { PartnerProjectInfo } from './PartnerProjectInfo';
import { SelectLocations } from './SelectLocations';
import { SelectTypeDeBien } from './SelectTypeDeBien';

import { distributeSurfaceAcrossLocations } from '../utils';
import { LOCATION_SURFACE_STEP, steps } from '../utils/constants';

export type FormValues = {
  firstName: string;
  lastName: string;
  googleMapsAddress?: Estimation['googleMapsAddress'];
  phoneNumber?: string;
  email?: string;
  locations: DevisLocation[];
  defaultGamme: Gamme;
  typeLogement?: string;
  surfaceTotale?: number;
  hauteurMur: number;
};

type Props = {
  estimation?: Estimation;
  setUserAddress?: (address: GoogleMapsAddress) => void;
  onSubmit: (values: FormValues) => void;
};

export const ConfigurationForm: React.FC<Props> = ({ estimation, setUserAddress, onSubmit }) => {
  const navigate = useNavigate();
  const { step } = useParams();
  const pageIndex = steps.indexOf(step as (typeof steps)[number]);
  const goToNextStep = (): void => {
    navigate(`../${steps[pageIndex + 1]}`);
  };
  const previousStep =
    pageIndex === LOCATION_SURFACE_STEP + 1 ? steps[pageIndex - 2] : steps[pageIndex - 1];

  const initialValues: Partial<FormValues> = estimation
    ? {
        ...estimation,
        ...estimation.context,
      }
    : {
        firstName: '',
        lastName: '',
        locations: [],
        hauteurMur: 2.7,
      };

  const onFormSubmit: Config<FormValues>['onSubmit'] = (
    values: FormValues,
    form: FormApi<FormValues>,
  ) => {
    if (pageIndex === steps.length - 1) {
      return onSubmit(values);
    }

    const surfacesNotDistributed = values.locations.every(location => location.surface === 0);
    if (pageIndex === LOCATION_SURFACE_STEP - 1 && surfacesNotDistributed) {
      form.change(
        'locations',
        distributeSurfaceAcrossLocations(values.locations, values.surfaceTotale),
      );
    }

    return goToNextStep();
  };

  return (
    <Form<FormValues>
      // disable reinitialize
      initialValuesEqual={() => true}
      initialValues={initialValues}
      subscription={{ valid: true }}
      onSubmit={onFormSubmit}
    >
      {({ handleSubmit, valid }) => (
        <form onSubmit={handleSubmit}>
          <>
            {pageIndex > 1 && (
              <ButtonLink
                className="mb-md"
                variant="tertiary"
                to={`../${previousStep}`}
                leftIcon={<ArrowLeft />}
              >
                Retour
              </ButtonLink>
            )}
            {step === 'infos' && <PartnerProjectInfo valid={valid} />}
            {step === 'typeDeBien' && (
              <SelectTypeDeBien
                valid={valid}
                formattedAddress={estimation?.googleMapsAddress?.formatted_address}
                setUserAddress={setUserAddress}
              />
            )}
            {step === 'localisations' && <SelectLocations valid={valid} />}
            {step === 'surface' && <EnterSurfaceForLocations valid={valid} />}
          </>
        </form>
      )}
    </Form>
  );
};
