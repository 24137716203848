import React from 'react';

import { Navigate, useParams } from 'react-router';

import { GuidePage } from './components/GuidePage';
import { GuidesSection } from './components/GuidesSection';
import { GuidesTags } from './types';
import { guidesConfigs } from './utils/constants';
import { guidesBaseUrl } from './utils/urls';

const GuidePageWrapper: React.FC = () => {
  const { guide, uuid } = useParams();
  const guideConfig = guidesConfigs[guide as GuidesTags];

  return guideConfig ? (
    <GuidePage {...guideConfig.pageConfig} />
  ) : (
    <Navigate replace to={guidesBaseUrl(uuid!)} />
  );
};

export const Guides = {
  Index: GuidesSection,
  Guide: GuidePageWrapper,
};
