import { DevisView } from '@travauxlib/shared/src/types/api/client/DevisView';

export const getSignatureRequestBaseUrl = (token: string): string =>
  `${APP_CONFIG.apiURL}/devis-pro/${token}/signature-request`;

export const buildSignatureSuccessUrl = (token?: string): string =>
  `/pro/devis/${token ? token : ':token'}/success`;

export const getDevisTitle = (devis: DevisView, isArchitecte: boolean): string | undefined => {
  if (devis.isPreChiffrage) {
    return 'Pré-chiffrage';
  }
  if (!devis.numero) {
    return undefined;
  }
  const prefix = isArchitecte ? "Proposition d'honoraires" : 'Devis';
  return `${prefix} n°${devis.numero}`;
};

export const revisionOnboardingStepsDesktop = [
  {
    element: '.revision-button',
    intro: 'Vous souhaitez ajouter des commentaires au devis ? Activez le mode commentaire.',
    title: 'Activation du mode commentaire',
    position: 'top',
  },
  {
    element: '#ligne-devis',
    title: 'Cliquez sur une ligne',
    intro:
      'Vous souhaitez commenter le devis ? Cliquez directement sur la ligne concernée pour ajouter votre commentaire',
    position: 'top',
  },
  {
    element: '.onboarding-ligne-comment',
    title: 'Rédigez votre commentaire',
    intro:
      "Cette prestation ne correspond pas à vos attentes ? Ajoutez un commentaire pour toute demande de modification ou d'information.",
    position: 'bottom',
  },
  {
    element: '#ligne-devis-1',
    title: 'Cliquez sur une autre ligne',
    intro:
      'Vous avez des commentaires supplémentaires à ajouter ? Cliquez sur chaque prestation que vous souhaitez commenter.',
    position: 'top',
  },
  {
    element: '.validate-comments',
    title: 'Validez vos commentaires',
    intro:
      "Vous avez terminé de relire et de commenter le devis ? N'oubliez pas de valider vos commentaires pour les envoyer à l'entreprise.",
    position: 'top',
  },
];

export const revisionOnboardingStepsMobile = [
  {
    element: '.mobile-revision-button',
    intro: 'Vous souhaitez ajouter des commentaires au devis ? Activez le mode commentaire.',
    title: 'Activation du mode commentaire',
    position: 'top',
  },
  {
    element: '#ligne-devis',
    title: 'Cliquez sur une ligne',
    intro:
      'Vous souhaitez commenter le devis ? Cliquez directement sur la ligne concernée pour ajouter votre commentaire',
    position: 'top',
  },
  {
    element: '.mobile-add-comment',
    title: 'Rédigez votre commentaire',
    intro:
      "Cette prestation ne correspond pas à vos attentes ? Ajoutez un commentaire pour toute demande de modification ou d'information.",
    position: 'bottom',
  },
  {
    element: '#ligne-devis-1',
    title: 'Cliquez sur une autre ligne',
    intro:
      'Vous avez des commentaires supplémentaires à ajouter ? Cliquez sur chaque prestation que vous souhaitez commenter.',
    position: 'top',
  },
  {
    element: '.validate-comments',
    title: 'Validez vos commentaires',
    intro:
      "Vous avez terminé de relire et de commenter le devis ? N'oubliez pas de valider vos commentaires pour les envoyer à l'entreprise.",
    position: 'top',
  },
];
