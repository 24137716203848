import React from 'react';

import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { DevisCustomization } from '@travauxlib/shared/src/types';
import { ProDevisFactureLotView } from '@travauxlib/shared/src/types/api/common/ProDevisFactureLotView';

type Props = {
  lots: ProDevisFactureLotView[];
  customization?: DevisCustomization;
};

export const LotRecap: React.FC<Props> = ({ lots, customization }) => (
  <div className="mb-md">
    {lots.map((lot, indexLot) => (
      <div key={lot.label} className="flex justify-between">
        <div>
          <span className="mr-sm text-neutral-700">{indexLot + 1}.0</span>
          <span className={customization?.bodyFontStyle || ''}>{lot.label}</span>
        </div>
        <span className="whitespace-nowrap">
          <EURCurrency amount={lot.prixTotalHT} /> HT
        </span>
      </div>
    ))}
  </div>
);
