import React from 'react';

import { Checkbox } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { DevisFactureCard } from '@travauxlib/shared/src/features/DevisDisplay/components/DevisFactureCard';
import { DevisView } from '@travauxlib/shared/src/types/api/client/DevisView';
import { TypeSuivi } from '@travauxlib/shared/src/types/api/common/TypeSuivi';
import { typeSuiviWordings } from '@travauxlib/shared/src/utils/constants';

import { useCurrentDevis } from '../../CurrentDevisContext';

type Props = {
  devis: DevisView;
  isReadOnly: boolean;
};

const items = [
  "Visites d'accompagnement de chantier (1 visite chaque semaine), Travaux Planner dédié (expert technique intégré aux équipes hemea)",
  "Compte-rendu détaillé de l'avancement du chantier après chaque visite",
  'Support premium : réponse à toutes vos questions sous 24h',
  "100% des paiements sécurisés en ligne avec libération des fonds à l'avancement du chantier (après validation conjointe du client et du Travaux Planner)",
];

export const LegacyServicesOptionnels: React.FC<Props> = ({ devis, isReadOnly }) => {
  const { typeSuivi, setTypeSuivi } = useCurrentDevis();
  const { prixTravauxPlannerTTC, tauxTravauxPlanner } = devis.modalitesHemea;
  const travauxPlannerModalite = TypeSuivi.TravauxPlanner;

  return (
    <div className="break-inside-avoid mb-xl" data-testid="services-optionnels-hemea">
      <div className="text-h4 font-medium mb-md">
        Services optionnels : pour encore plus de confort
      </div>
      <DevisFactureCard className="mb-md">
        <div className="flex flex-wrap -mx-md items-start mb-md">
          <div className="flex items-start w-full px-md sm-desktop:max-w-6/12 mb-0">
            <Checkbox
              id="typeSuivi"
              label={typeSuiviWordings[travauxPlannerModalite].commercialLabel}
              checked={typeSuivi === travauxPlannerModalite}
              onChange={(checked: boolean) =>
                !isReadOnly &&
                (checked
                  ? setTypeSuivi(travauxPlannerModalite)
                  : setTypeSuivi(TypeSuivi.CustomerCare))
              }
              disabled={isReadOnly}
            />
          </div>
          <div className="w-full px-md tablet:max-w-6/12 sm-desktop:max-w-2/12 text-right">
            <strong>En option</strong>
          </div>
          <div className="w-full px-md tablet:max-w-6/12 sm-desktop:max-w-4/12 text-right whitespace-nowrap">
            <strong>
              <EURCurrency amount={prixTravauxPlannerTTC} /> TTC
              <br />
              {tauxTravauxPlanner && (
                <small>({tauxTravauxPlanner.toLocaleString('fr')}% du montant du chantier)</small>
              )}
            </strong>
          </div>
        </div>
        <ul className="text-neutral-700 text-b2 pl-md mb-0">
          {items.map(item => (
            <li key={item} className="mb-xxs last:mb-0">
              {item}
            </li>
          ))}
        </ul>
      </DevisFactureCard>
    </div>
  );
};
