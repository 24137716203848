import React from 'react';

import InfoCircleFilled from '@travauxlib/shared/src/components/DesignSystem/assets/InfoCircleFilled.svg?react';
import StateChecked from '@travauxlib/shared/src/components/DesignSystem/assets/StateChecked.svg?react';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';

import { StructureCard } from 'components/Card';

import { QuestionsReassurance } from './QuestionsReassurance';

import { FormValues, StepConfig } from '../types';
import { getFormattedAnswers } from '../utils/getFormattedAnswer';

type Props = {
  steps: StepConfig[];
  formValues: FormValues;
  stepId: string;
  setIsNavigatingToPastQuestion: (shouldUpdateFormValues: boolean) => void;
};

export const AnswersNavigation: React.FC<Props> = ({
  steps,
  formValues,
  stepId,
  setIsNavigatingToPastQuestion,
}) => {
  const [answeredSteps, formattedAnswers] = getFormattedAnswers(steps, formValues, stepId);

  return (
    <>
      <div className="text-h4 text-neutral-800 my-md sm-desktop:mt-0 font-bold">Votre projet</div>
      <StructureCard className="-mx-md sm-desktop:mx-0 mb-md sm-desktop:mb-0">
        {answeredSteps.length === 0 ? (
          <div className="flex text-info-700">
            <InfoCircleFilled className="w-lg h-lg mr-xs flex-shrink-0" />
            <div className="text-b1 font-medium">Retrouvez ici vos réponses choisies</div>
          </div>
        ) : (
          answeredSteps.map((answeredStep: any, index) => (
            <div className="flex items-center mb-md last:mb-0" key={answeredStep.id}>
              <StateChecked className="text-primary mr-xs w-lg h-lg flex-shrink-0" />
              <Link
                className="!text-neutral text-b2"
                to={`../${answeredStep.id}`}
                onClick={() => setIsNavigatingToPastQuestion?.(true)}
              >
                {formattedAnswers[index]}
              </Link>
            </div>
          ))
        )}
      </StructureCard>
      <div className="block sm-desktop:hidden">
        <QuestionsReassurance />
      </div>
    </>
  );
};
