import React from 'react';

import { DraggableProvidedDragHandleProps } from '@hello-pangea/dnd';

import SwitchIndeterminateFilled from '@travauxlib/shared/src/components/DesignSystem/assets/SwitchIndeterminateFilled.svg?react';

export type DragProps = {
  isDragging?: boolean;
  className?: string;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
};

export const DragWidget: React.FC<DragProps> = ({
  className = '',
  dragHandleProps,
  isDragging,
}) => {
  const colorClassName = isDragging ? 'visible text-neutral-900' : 'text-neutral-600';

  return (
    <div className={`${className} ${colorClassName}`} {...dragHandleProps}>
      <SwitchIndeterminateFilled className="size-xmd" />
    </div>
  );
};
