import React from 'react';

import classNames from 'classnames';
import { useNavigate } from 'react-router';

import ChevronRight from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronRightFilled.svg?react';
import { Badge } from '@travauxlib/shared/src/components/DesignSystem/components/Badge';
import { ListItem } from '@travauxlib/shared/src/components/DesignSystem/components/ListItem';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { ClientActionType } from '@travauxlib/shared/src/types';
import { actionIconMapper } from '@travauxlib/shared/src/utils/actionIconMapper';

import { Project } from 'features/EspaceClient3/types';
import { EspaceClientTracking } from 'utils/tracking/EspaceClientTracking';

import { EmptyAction } from './EmptyAction';

import { useActions } from '../api/useActions';

type Props = {
  project: Pick<Project, 'uuid' | 'title'>;
};

export const ActionsSection: React.FC<Props> = ({ project }) => {
  const navigate = useNavigate();
  const { actions, isLoading } = useActions(project.uuid);

  if (isLoading) {
    return (
      <div className="my-xl flex justify-center">
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <div className="flex items-center gap-xxs my-sm mx-md sm-desktop:mx-0 text-h5 font-bold">
        Mes actions à réaliser
        {actions.length > 0 && <Badge value={actions.length} size="sm" variant="error" />}
      </div>
      {actions.length > 0 ? (
        <div className="py-xxs bg-neutral-0 shadow-xxs rounded-none sm-desktop:rounded-xxs">
          {actions.map(action => {
            const shouldTruncateTitle = [
              ClientActionType.SeeProDevis,
              ClientActionType.SeeProDevisTs,
              ClientActionType.SeeArchiDevis,
              ClientActionType.SeeArchiDevisTs,
            ].includes(action.actionType);

            return (
              <ListItem
                className="last:border-0"
                onChange={() => {
                  EspaceClientTracking.onEspaceClientActionClicked({
                    'Action Title': action.title,
                    'Action Type': action.actionType,
                    'Project Name': project.title,
                    'Project Uuid': project.uuid,
                  });
                  navigate(action.to);
                }}
                key={action.to}
                size="sm"
                state="filled"
                shouldTruncate={shouldTruncateTitle}
                left={{
                  type: 'iconBackground',
                  icon: actionIconMapper[action.actionType],
                  variant: 'beige',
                }}
                middle={{
                  title: (
                    <>
                      {action.secondaryTitle && (
                        <div className="text-b2 font-medium truncate">{action.secondaryTitle}</div>
                      )}
                      <div
                        className={classNames('text-b2 font-normal', {
                          'text-neutral-700': !!action.secondaryTitle,
                        })}
                      >
                        {action.title}
                      </div>
                    </>
                  ),
                }}
                right={{ type: 'icon', icon: <ChevronRight /> }}
              />
            );
          })}
        </div>
      ) : (
        <EmptyAction />
      )}
    </div>
  );
};
