import React from 'react';

import { Route, Routes } from 'react-router';

import { EstimateurCDPL } from '../components/EstimateurCDPL';

type Props = {};

export const EstimateurCDPLRoutes: React.FC<Props> = ({}) => (
  <Routes>
    <Route path="" element={<EstimateurCDPL />} />
  </Routes>
);
