import React from 'react';

import { baseImgixUrl } from '@travauxlib/shared/src/utils/urls';

import { Separator } from 'components/Separator';

type Props = {
  isHemea?: boolean;
  title?: string;
};

export const Title: React.FC<Props> = ({ title, isHemea }) => (
  <div className="flex items-stretch justify-end">
    <div className="mt-xxs font-bold align-middle">{title}</div>
    {title && isHemea && <Separator />}
    {isHemea && (
      <img
        className="h-lg"
        data-testid="logo-hemea"
        width="87"
        src={`${baseImgixUrl}/static/logo-hemea-black-v2.png?h=48`}
        srcSet={`${baseImgixUrl}/static/logo-hemea-black-v2.png?h=48 2x`}
        alt="hemea"
      />
    )}
  </div>
);
