import React from 'react';

import { Navigate, Route, Routes } from 'react-router';

import { Helmet } from '@travauxlib/shared/src/components/Helmet';

import { BonCommande } from 'features/BonCommande/Loadable';
import { DevisPro } from 'features/DevisPro/Loadable';
import { ForgotPassword } from 'features/Estimateur/features/ForgotPassword/Loadable';
import { EstimateurPublic } from 'features/Estimateur/features/Public/Loadable';
import { EstimateurCreditDuNord } from 'features/Estimateur/features/WhiteLabelPartners/components/CreditDuNord';
import { EstimateurHosman } from 'features/Estimateur/features/WhiteLabelPartners/components/Hosman';
import { Estimateur } from 'features/Estimateur/Loadable';
import { estimateurClientPath, estimateurPartnerPath } from 'features/Estimateur/utils/paths';
import { EstimateurCDPLRoutes } from 'features/EstimateurCDPL';
import { FacturePayment } from 'features/Factures/components/FacturePayment';
import { WithFacture } from 'features/Factures/hocs/WithFacture';
import { FactureShow } from 'features/Factures/Loadable';
import { OnboardingSolide } from 'features/OnboardingSolide';
import { RedirectToNewOnboarding } from 'features/OnboardingSolide/components/RedirectToNewOnboarding';
import { Partner } from 'features/Partner';
import { PasswordReset } from 'features/PasswordReset/Loadable';
import { PasswordResetEstimateur } from 'features/PasswordResetEstimateur/Loadable';
import { Print } from 'features/Print';
import { RequestPasswordReset } from 'features/RequestPasswordReset/Loadable';
import { SuiviChantier } from 'features/SuiviChantier';
import { deleteAccountLegacyUrl, loginUrl } from 'utils/urls';

import { AuthenticatedRoutes } from './authenticated';

const FacturesWithData = WithFacture(FactureShow);
const FacturePaymentWithData = WithFacture(FacturePayment);

export const MainRoutes: React.FC = () => (
  <Routes>
    <Route path="print" element={<Print.Index />}>
      <Route path="pro/bon-commande/:token" element={<Print.BonCommande />} />
      <Route path="pro/suivi-chantier/:token" element={<Print.SuiviChantier />} />
      <Route path="factures/:token" element={<Print.Facture />} />
      <Route path="pro/devis/:token/by-locations" element={<Print.DevisPro byLocations />} />
      <Route path="pro/devis/:token" element={<Print.DevisPro />} />
      <Route path="estimateur/client/:uuid" element={<Print.Estimateur isClient />} />
      <Route path="estimateur/:uuid" element={<Print.Estimateur />} />
    </Route>
    <Route
      path={deleteAccountLegacyUrl}
      element={<Navigate replace to="/espace-client/delete-account" />}
    />
    <Route path="estimateur-cdpl/" element={<EstimateurCDPLRoutes />} />
    <Route path="onboarding">
      <Route path="project" element={<OnboardingSolide />}>
        <Route path="*" element={<OnboardingSolide />} />
      </Route>
      <Route path="" element={<Navigate replace to="project" />} />
    </Route>
    <Route path="onboarding/questions/*" element={<RedirectToNewOnboarding />} />
    <Route path={loginUrl} element={<AuthenticatedRoutes />} />
    <Route path={estimateurClientPath} element={<AuthenticatedRoutes />} />
    <Route path="reset-password" element={<PasswordReset />} />
    <Route path="request-password-reset" element={<RequestPasswordReset />} />
    <Route path="estimateur/reset-password" element={<PasswordResetEstimateur />} />
    <Route path="partner/:affiliateToken/*" element={<Partner.Index />}>
      <Route path="" element={<Partner.HomePartner />} />
      <Route path="send-lead" element={<Partner.PartnerSendLeadForm />} />
      <Route path="*" element={<Navigate replace to="" />} />
    </Route>
    <Route path="partner/home" element={<Partner.RedirectToNewForm />} />
    <Route path="onboarding/questions/partner/*" element={<Partner.RedirectToNewForm />} />
    <Route path="estimateur/forgot-password" element={<ForgotPassword />} />
    <Route path="credit-du-nord/estimateur">
      <Route
        index
        path="*"
        element={
          <>
            <Helmet>
              <title>Estimateur travaux - Crédit du Nord</title>
            </Helmet>
            <EstimateurCreditDuNord />
          </>
        }
      />
    </Route>
    <Route path="hosman/estimateur">
      <Route
        index
        path="*"
        element={
          <>
            <Helmet>
              <title>Estimateur travaux - Hosman</title>
            </Helmet>
            <EstimateurHosman />
          </>
        }
      />
    </Route>
    <Route path="estimateur/public">
      <Route
        index
        path="*"
        element={
          <>
            <Helmet>
              <title>Estimateur travaux - hemea</title>
            </Helmet>
            <EstimateurPublic />
          </>
        }
      />
    </Route>
    <Route
      path={estimateurPartnerPath}
      element={
        <>
          <Helmet>
            <title>Estimateur travaux - hemea</title>
          </Helmet>
          <Estimateur />
        </>
      }
    />
    <Route path="factures/:token/payment" element={<FacturePaymentWithData />} />
    <Route path="factures/:token" element={<FacturesWithData />} />
    <Route path="pro/devis/:token/*" element={<DevisPro />} />
    <Route path="pro/bon-commande/:token" element={<BonCommande />} />
    <Route path="pro/suivi-chantier/:token" element={<SuiviChantier />} />
    <Route path="*" element={<AuthenticatedRoutes />} />
  </Routes>
);
