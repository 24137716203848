import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { CommissionMLM, Investissement, Reseau } from '../types';
import { calculateTotalRevenue } from '../utils/calculateTotalRevenue';
import { mapping, reseauLabel, tempsInvestiLabels } from '../utils/constants';

export type FormValues = {
  firstname: string;
  lastname: string;
  email: string;
  reseauApporteur: Reseau;
  tempsInvesti: Investissement;
};

export type CreateEstimationCDPLView = {
  firstname: string;
  lastname: string;
  email: string;
  reseauApporteur: string;
  tempsInvesti: string;
  firstYear: number;
  secondYear: number;
  thirdYear: number;
};

const createEstimationCDPL = (body: FormValues): Promise<void> => {
  const revenue = mapping[body.tempsInvesti][body.reseauApporteur];

  const formattedBody: CreateEstimationCDPLView = {
    ...body,
    tempsInvesti: tempsInvestiLabels[body.tempsInvesti],
    reseauApporteur: reseauLabel[body.reseauApporteur],
    firstYear: calculateTotalRevenue(revenue.firstYear, CommissionMLM.MLM5),
    secondYear: calculateTotalRevenue(revenue.secondYear, CommissionMLM.MLM5),
    thirdYear: calculateTotalRevenue(revenue.thirdYear, CommissionMLM.MLM5),
  };
  return request(`${APP_CONFIG.apiURL}/cdpl/estimation`, { method: 'POST', body: formattedBody });
};

export const useCreateEstimationCDPL = (): UseMutateAsyncFunction<
  void,
  unknown,
  FormValues,
  unknown
> => {
  const { mutateAsync } = useMutation({
    mutationFn: createEstimationCDPL,
  });

  return mutateAsync;
};
