import { ProDevisFactureLigneView } from '@travauxlib/shared/src/types/api/common/ProDevisFactureLotView';

import { getMontantsTVA, getTotalTVAFromMontants } from './getMontantsTVA';
import { getPrixTotalFromLots } from './getPrixTotalHTFromLots';

import { Devis, GenericFacture, MontantTVA } from '../../types';

export type FactureSoldeTotals = {
  totalTTC: number;
  totalHT: number;
  montantsTVA: MontantTVA[];
  totalTravauxHT: number;
  montantRemise: number;
  totalHTAvantRemise: number;
};

export const getFactureSoldeTotals = (
  factureSolde: Omit<GenericFacture, 'status'>,
  totalDejaFactureHT: number,
  totalDejaFactureTTC: number,
  totalTVADejaFacture: MontantTVA[],
  devis: Pick<Devis, 'prixTotalTTC' | 'prixTotalHT' | 'prixTotalHTAvantRemise'>,
): FactureSoldeTotals | undefined => {
  if (!factureSolde.isCloture) {
    return undefined;
  }

  const montantRemise = devis.prixTotalHTAvantRemise - devis.prixTotalHT;
  const paidLines: ProDevisFactureLigneView[] = factureSolde.lots.flatMap(lot =>
    lot.items.filter(
      item => item.type === 'ligne' && item.status !== 'option' && item.status !== 'free',
    ),
  ) as ProDevisFactureLigneView[];

  const totalTravauxHT = getPrixTotalFromLots(factureSolde.lots);
  const totalHTAvantRemise = totalTravauxHT - totalDejaFactureHT;
  const totalHT = totalHTAvantRemise - montantRemise;
  const percentageDiff = (100 * (totalTravauxHT - totalHT)) / totalTravauxHT;

  const montantsTVA = getMontantsTVA(paidLines, percentageDiff);

  const totalTTC = totalHT + getTotalTVAFromMontants(montantsTVA);

  return {
    totalTravauxHT,
    totalHT,
    totalTTC,
    montantsTVA,
    totalHTAvantRemise,
    montantRemise,
  };
};
