import Euro from '@travauxlib/shared/src/components/DesignSystem/assets/EuroFilled.svg?react';
import Handshake from '@travauxlib/shared/src/components/DesignSystem/assets/Handshake.svg?react';
import Lock from '@travauxlib/shared/src/components/DesignSystem/assets/Lock.svg?react';

import { Props as ReassuranceItemProps } from 'features/EspaceClient3/components/ReassuranceCard/ReassuranceItem';

export const paymentReassurance: ReassuranceItemProps[] = [
  {
    icon: <Lock />,
    title: 'Compte sécurisé',
    description:
      "Vous déposez les fonds sur ce compte et vous seul pouvez libérer les fonds au profit de l'entrepreneur",
  },
  {
    icon: <Handshake />,
    title: "Paiement à l'avancement",
    description:
      "Vous évaluez l'avancement de chantier et vous ne libérez que les fonds nécessaires",
  },
  {
    icon: <Euro />,
    title: 'Paiements fluides',
    description:
      "Les fonds débloqués arrivent sur le compte de l'entrepreneur le lendemain sans induire de retard sur le chantier",
  },
];
