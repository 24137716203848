import React from 'react';

import Check from '@travauxlib/shared/src/components/DesignSystem/assets/CheckSymbolFilled.svg?react';
import { AnimatedCircleProgress } from '@travauxlib/shared/src/components/DesignSystem/components/CircularProgress/AnimatedCircleProgress';
import { BrandVariant } from '@travauxlib/shared/src/types/designSystem';

type Props = {
  value: number;
  variant?: BrandVariant | 'info' | 'success';
};

export const SuiviChantierCircleProgress: React.FC<Props> = ({ value, variant }) => (
  <div>
    <AnimatedCircleProgress
      value={value || 0}
      size="sm"
      variant={variant ?? (value === 100 ? 'success' : 'primary')}
      icon={
        value === 100 ? (
          <span className="animate-pulse duration-500 ease-in finite">
            <Check />
          </span>
        ) : undefined
      }
    />
  </div>
);
