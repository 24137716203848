import { useQuery } from '@tanstack/react-query';

import { ClientAccountView } from '@travauxlib/shared/src/types/api/client/ClientAccountView';
import { request } from '@travauxlib/shared/src/utils/request';

import { AUTH_KEY } from './constants';

import { TrackingIdentify } from '../utils/tracking';

export const useGetAccount = (): {
  account?: ClientAccountView;
  isLoading: boolean;
  isAuthenticated: boolean;
} => {
  const { data: account, isLoading } = useQuery<ClientAccountView>({
    queryKey: [AUTH_KEY],
    queryFn: async () => {
      const requestURL = `${APP_CONFIG.apiURL}/client/account`;
      const account = await request(requestURL);
      TrackingIdentify(account);
      return account;
    },
    staleTime: Infinity,
  });

  return {
    account,
    isLoading,
    isAuthenticated: !!account,
  };
};
