import { MontantTVA, SuiviLigneStatus } from '@travauxlib/shared/src/types';
import { ProDevisFactureLigneView } from '@travauxlib/shared/src/types/api/common/ProDevisFactureLotView';
import { ProDevisFactureLotView } from '@travauxlib/shared/src/types/api/common/ProDevisFactureLotView';
import { ProDevisFactureRemises } from '@travauxlib/shared/src/types/api/domain/prodevisfactures/ProDevisFactureRemises';

export interface SuiviLigne extends Omit<ProDevisFactureLigneView, 'hasFournitures'> {
  previousStepPourcentageAvancement: number;
  previousCyclePourcentageAvancement: number;
  previousStepHasFournitures: boolean;
  previousCycleHasFournitures: boolean;
  proPreviousStepPourcentageAvancement?: number;
  proPreviousStepHasFournitures?: boolean;
  pourcentageAvancement: number;
  commentaire?: string;
  hasChanged: boolean;
  indexInLot: number;
  hasFournitures: boolean;
  prixFournituresHT: number;
  prixHTWithoutFournitures: number;
  suiviStatus?: SuiviLigneStatus;
  isModifyingAnotherLigne?: boolean;
  order?: number;
}

export interface SuiviLot extends Omit<ProDevisFactureLotView, 'items'> {
  lignes: SuiviLigne[];
  devisNumber: number;
  devisToken: string;
}

export type SuiviDevis = {
  remises: ProDevisFactureRemises;
  token: string;
  lots: SuiviLot[];
  signedAt: string;
};

export type SuiviChantierPro = {
  customerName: string;
  montantDejaPaye: number;
  montantRemise: number;
  devisList: SuiviDevis[];
  totalMontantTVA: number;
  receptionChantierThreshold?: number;
  leveeReservesThreshold?: number;
  hasReserves: boolean;
  isReceptionChantierPvValidated: boolean;
  isLeveeReservesPvValidated: boolean;
};

export type FormData = Record<string, SuiviLot>;

export type SuiviChantierClient = {
  devisList: SuiviDevis[];
  montantDejaPaye: number;
  montantRemise: number;
  proCompanyName: string;
  hasTPIntervenant: boolean;
  totalMontantTVA: number;
  receptionChantierThreshold?: number;
  leveeReservesThreshold?: number;
  hasReserves: boolean;
  isReceptionChantierPvValidated: boolean;
  isLeveeReservesPvValidated: boolean;
};

export type ConflictStatus = 'agreed' | 'rejected';

export type HistoryConflictStatus = ConflictStatus | 'pending' | 'disputed' | 'canceled';

export enum SuiviChantierIntervenant {
  Pro = 'pro',
  TP = 'tp',
  Client = 'client',
}

export type SuiviChantierComputed = {
  montantsTVA: MontantTVA[];
  totalTVA: number;
  montantAvancementGlobalTTC: number;
  montantALibererTTC: number;
  montantRemise: number;
  montantDejaPaye: number;
  montantGele: number;
  atLeastOneLineHasChanged: boolean;
};

export type SuiviChantierEtapeHistory = {
  uuid: string;
  cycleUUID: string;
  montantALiberer: number;
  pourcentage: number;
  intervenant: SuiviChantierIntervenant;
  createdAt: string;
};

export type SuiviChantierRO = {
  pro: SuiviDevis[];
  tp: SuiviDevis[];
  client: SuiviDevis[];
  history: SuiviChantierCycleHistory;
};

export type SuiviChantierCycleHistory = {
  uuid: string;
  etapes: {
    pro: SuiviChantierEtapeHistory;
    client?: SuiviChantierEtapeHistory;
    tp?: SuiviChantierEtapeHistory;
  };
  createdAt: string;
  status: HistoryConflictStatus;
  montantDemande: number;
  montantPercu?: number;
};
