import { GoogleMapsAddress } from '@travauxlib/shared/src/types/api/common/GoogleMapsAddress';

import { useIsLocationAddressable } from '../api/useIsLocationAddressable';
import { useIsEstimateurPublic, useWhiteLabelPartner } from '../utils/paths';

export const useShouldShowRecallButton = (googleMapsAddress?: GoogleMapsAddress): boolean => {
  const isEstimateurPublic = useIsEstimateurPublic();
  const isEstimateurWhiteLabelPartner = !!useWhiteLabelPartner();

  const isLocationAddressable = !!useIsLocationAddressable(googleMapsAddress);

  return isLocationAddressable && (isEstimateurPublic || isEstimateurWhiteLabelPartner);
};
