import { useMutation, useQueryClient } from '@tanstack/react-query';
import jsCookie from 'js-cookie';
import { useNavigate } from 'react-router';

import { sendEventToGTM } from '@travauxlib/shared/src/api/tracking';
import { toast } from '@travauxlib/shared/src/components/Notifications';
import { ClientAccountView } from '@travauxlib/shared/src/types/api/client/ClientAccountView';
import { Cookie } from '@travauxlib/shared/src/utils/cookies/constants';
import { request } from '@travauxlib/shared/src/utils/request';

import { AUTH_KEY } from 'api/constants';
import {
  ONBOARDING_STORAGE_KEY,
  onboardingProjectSavedPath,
} from 'features/OnboardingSolide/utils/constants';
import { storage } from 'utils/storage';

import { LocalisationAnswer, OnboardingEvents } from '../types';

export type CreateLeadPayload = {
  localisation: LocalisationAnswer;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  optin: boolean;
  need: string;
  typeProjet: string;
  dateTravaux: string;
  besoinArchitecte: string;
  critereSelection: string;
  budget: string;
  description?: string;
  typeBien: string;
  stadeAchat: string;
  surfaceTotale: number;
  typeRenovationEnergetique?: string[];
  affiliateToken?: string;
};

export type APIResponse = {
  existingAccountEmail?: string;
  createdClientAccount?: ClientAccountView;
  calendlyGlobal?: string;
  leadToken: string;
};

export const readConversionPageFromCookies = (): string => {
  const websiteLastPathCookieValue = jsCookie.get(Cookie.WebsiteLastPathCookie);

  return websiteLastPathCookieValue
    ? `${APP_CONFIG.wwwURL}${websiteLastPathCookieValue}`
    : document.referrer;
};
export const buildAppRedirectTarget = (
  clientAccount?: ClientAccountView,
  existingAccountEmail?: string,
): string =>
  existingAccountEmail
    ? `login?existingAccountEmail=${existingAccountEmail}`
    : `reset-password?email=${clientAccount?.email}&token=${clientAccount?.passwordConfirmationToken}&isNewClient`;

const handleCreateLead = async (payload: CreateLeadPayload): Promise<APIResponse> =>
  request(`${APP_CONFIG.apiURL}/client/lead/v2`, {
    method: 'POST',
    body: {
      ...payload,
      googleMapsAddress: payload.localisation.value,
      conversionPage: readConversionPageFromCookies(),
    },
  });

export const useCreateLead = (): typeof handleCreateLead => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutateAsync: createLead } = useMutation<APIResponse, any, CreateLeadPayload>({
    mutationFn: handleCreateLead,
    onSuccess: ({ existingAccountEmail, createdClientAccount, calendlyGlobal, leadToken }) => {
      const appRedirectTarget = buildAppRedirectTarget(createdClientAccount, existingAccountEmail);

      if (createdClientAccount) {
        queryClient.setQueryData([AUTH_KEY], createdClientAccount);
        sendEventToGTM(OnboardingEvents.createdAccountEvent, createdClientAccount);
      }

      if (calendlyGlobal) {
        return navigate(
          `/onboarding/project/${onboardingProjectSavedPath}?token=${leadToken}&appRedirect=${encodeURIComponent(
            appRedirectTarget,
          )}`,
        );
      } else {
        return navigate(`/${appRedirectTarget}`);
      }
    },
    onError: () => {
      toast.error("Une erreur est survenue pendant l'enregistrement de vos réponses.", {
        href: '/onboarding',
        actionLabel: 'Réinitialiser',
        toastOptions: {
          onClick: () => {
            storage.remove(ONBOARDING_STORAGE_KEY);
          },
        },
      });
    },
  });

  return createLead;
};
