import { ProCertificationRGEType } from '@travauxlib/shared/src/types/api/domain/procompany/ProCertificationRGEType';

export const certifications: Array<{ name: string; image: string }> = [
  {
    name: 'Handibat',
    image: 'handibat.jpg',
  },
  {
    name: 'Maître Artisan',
    image: 'maitre-artisan.png',
  },
  {
    name: 'Professionnel du Gaz',
    image: 'pge.png',
  },
  {
    name: 'QSE',
    image: 'qse.png',
  },
  {
    name: 'Qualibat',
    image: 'qualibat.png',
  },
  {
    name: 'Quali Eau',
    image: 'quali-eau.jpg',
  },
  {
    name: 'Qualif Elec',
    image: 'qualif-elec.png',
  },
  {
    name: 'Qualité Artisan',
    image: 'qualité-artisan.png',
  },
  {
    name: 'Adhérent FFB',
    image: 'ffb.png',
  },
  {
    name: 'CAPEB',
    image: 'capeb.png',
  },
];

export const certificationsRGE: Array<{
  name: string;
  image: string;
  type: ProCertificationRGEType;
}> = [
  {
    name: 'RGE Quali Forage',
    image: 'quali-forage.png',
    type: 'qualiforageRGE',
  },
  {
    name: 'Qualibat RGE',
    image: 'qualibat-rge.jpg',
    type: 'qualibatRGE',
  },
  {
    name: 'Qualibois RGE',
    image: 'qualibois-rge.png',
    type: 'qualiboisRGE',
  },
  {
    name: 'Qualigaz RGE',
    image: 'qualigaz-rge.jpg',
    type: 'qualigazRGE',
  },
  {
    name: 'Qualipac RGE',
    image: 'qualipac-rge.jpg',
    type: 'qualipacRGE',
  },
  {
    name: 'Qualipv RGE',
    image: 'qualipv-rge.png',
    type: 'qualipvRGE',
  },
  {
    name: 'Qualisol RGE',
    image: 'qualisol-rge.png',
    type: 'qualisolRGE',
  },
  {
    name: 'RGE Chauffage',
    image: 'rge-chauffage.png',
    type: 'RGEChauffage',
  },
  {
    name: 'RGE Eco Artisan',
    image: 'rg-eco-artisan.png',
    type: 'RGEEcoArtisan',
  },
];
