import { useMutation } from '@tanstack/react-query';

import { GoogleMapsAddress } from '@travauxlib/shared/src/types/api/common/GoogleMapsAddress';
import { request } from '@travauxlib/shared/src/utils/request';

const checkIsLocationAddressable = (
  googleMapsAddress?: GoogleMapsAddress,
): Promise<{ ok: boolean }> => {
  const requestUrl = `${APP_CONFIG.apiURL}/lead/addressability/check`;

  return request(requestUrl, {
    method: 'POST',
    body: {
      googleMapsAddress,
      addressableProduct: 'leads_hemea',
    },
  });
};

export const useIsLocationAddressable = (): typeof checkIsLocationAddressable => {
  const { mutateAsync } = useMutation({ mutationFn: checkIsLocationAddressable });

  return mutateAsync;
};
