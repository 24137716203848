import React from 'react';

import classNames from 'classnames';

import CheckSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/CheckSymbolFilled.svg?react';
import CrossSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/CrossSymbolFilled.svg?react';
import {
  IconButton,
  IconSizes,
} from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';

const buttonClassNameByPosition = {
  left: 'active:bg-error-100 hover:bg-error-50 active:text-error-900 hover:text-error-900 !rounded-l-xxs !rounded-r-none !border !border-r-0',
  right:
    'active:bg-success-100 hover:bg-success-50 active:text-success-800 hover:text-success-800 !rounded-r-xxs !rounded-l-none !border',
};

const disabledClassName = '!text-neutral-300 !bg-neutral-100';

export type Props = {
  id?: string;
  value?: boolean;
  onChange: (newValue: boolean) => void;
  size?: IconSizes;
  disabled?: 'left' | 'right' | 'full';
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
};

export const IconButtonGroup: React.FC<Props> = ({
  id,
  value,
  onChange,
  size = 'md',
  disabled,
  leftIcon,
  rightIcon,
}) => {
  const isLeftDisabled = disabled === 'left' || disabled === 'full';
  const isRightDisabled = disabled === 'right' || disabled === 'full';

  return (
    <div data-testid={id || 'icon-button-group'} className="flex">
      <IconButton
        className={classNames(
          buttonClassNameByPosition['left'],
          isLeftDisabled
            ? disabledClassName
            : value === false
              ? '!text-neutral-0 !bg-error-600 pointer-events-none'
              : 'bg-neutral-0 !text-error-900',
        )}
        onClick={() => onChange(false)}
        size={size}
        disabled={isLeftDisabled}
        aria-label={`${id || 'icon-button'}-left`}
      >
        {leftIcon || <CrossSymbol />}
      </IconButton>
      <IconButton
        className={classNames(
          buttonClassNameByPosition['right'],
          isRightDisabled
            ? disabledClassName
            : value
              ? '!text-neutral-0 !bg-success-600 pointer-events-none'
              : 'bg-neutral-0 text-success-800',
        )}
        onClick={() => onChange(true)}
        size={size}
        disabled={isRightDisabled}
        aria-label={`${id || 'icon-button'}-right`}
      >
        {rightIcon || <CheckSymbol />}
      </IconButton>
    </div>
  );
};
