import React, { useEffect } from 'react';

import classNames from 'classnames';
import { useForm } from 'react-final-form';
import { useNavigate, useNavigationType, useParams } from 'react-router';

import ChevronLeft from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronLeftFilled.svg?react';
import InfoCircleFilled from '@travauxlib/shared/src/components/DesignSystem/assets/InfoCircleFilled.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { ProgressBar } from '@travauxlib/shared/src/components/DesignSystem/components/ProgressBar';

import { StructureCard } from 'components/Card';

import { QuestionSelector } from '../features/QuestionSelector';
import { FormValues, StepConfig } from '../types';
import { getPreviousAnswers } from '../utils/getPreviousAnswers';
import { sendLegacyAnswerEventToGTM } from '../utils/tracking';

type Props = {
  steps: StepConfig[];
  getNextStep: any;
  setIsNavigatingToPastQuestion?: (shouldUpdateFormValues: boolean) => void;
  isNavigatingToPastQuestion: boolean;
};

export const Questions: React.FC<Props> = ({
  steps,
  getNextStep,
  setIsNavigatingToPastQuestion,
  isNavigatingToPastQuestion,
}) => {
  const form = useForm<FormValues>();
  const navigate = useNavigate();
  const navigationType = useNavigationType();

  const { stepId } = useParams<{ stepId: string }>();
  const currentStep = steps.find(step => step.id === stepId);
  const currentIndex = steps.findIndex(step => step.id === stepId);
  const isFirstStep = currentIndex === 0;
  const previousStep = steps[currentIndex - 1];

  useEffect(() => {
    if (isNavigatingToPastQuestion || navigationType === 'POP') {
      const previousAndCurrentAnswers = getPreviousAnswers(
        steps,
        form.getState().values,
        currentIndex,
      );

      form.restart(previousAndCurrentAnswers);
      setIsNavigatingToPastQuestion?.(false);
    }
  }, [stepId]);

  if (!currentStep?.type) {
    return null;
  }

  return (
    <>
      <div className="text-b2 font-bold mb-xxs">
        Question {currentIndex + 1} sur {steps.length}
      </div>
      <ProgressBar
        className={classNames('mb-xl sm-desktop:mb-lg', { 'sm-desktop:!mb-3xl': isFirstStep })}
        value={currentIndex + 1}
        min={1}
        max={steps.length}
      />
      <Button
        className={classNames('w-fit mb-xs', { hidden: isFirstStep })}
        leftIcon={<ChevronLeft />}
        variant="tertiary"
        onClick={() => {
          setIsNavigatingToPastQuestion?.(true);
          navigate(`../${previousStep.id}`);
        }}
        size="sm"
      >
        Question précédente
      </Button>
      <div className="text-h4 font-bold mb-md">{currentStep.title}</div>
      <StructureCard className="-mx-md sm-desktop:mx-0 overflow-visible sm-desktop:mb-md">
        {currentStep.helperText && (
          <div className="flex text-info-700 mb-md">
            <InfoCircleFilled className="w-lg h-lg mr-xs flex-shrink-0" />
            <div className="text-b1 font-medium">{currentStep.helperText}</div>
          </div>
        )}
        <QuestionSelector
          step={currentStep}
          setNextStep={() => {
            const values = form.getState().values;
            const nextStep = getNextStep(values, currentIndex);

            sendLegacyAnswerEventToGTM(currentStep);

            navigate(`../${nextStep.id}`);
          }}
        />
      </StructureCard>
    </>
  );
};
