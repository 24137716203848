import React from 'react';

import { useParams } from 'react-router';

import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';

import { NotFound } from 'features/NotFoundPage';
import { Facture } from 'types';

import { useFacture } from '../api/useFacture';

export const WithFacture = <P extends object>(
  Component: React.ComponentType<P & { facture: Facture; isPrint?: boolean }>,
): React.ComponentType<P> => {
  const Wrapper = (props: P): React.ReactElement => {
    const { token } = useParams();
    const { isLoading, facture } = useFacture(token!);
    if (isLoading) {
      return <Loader />;
    }
    if (!facture) {
      return <NotFound />;
    }
    return <Component {...props} facture={facture} />;
  };
  return Wrapper;
};
