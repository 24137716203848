import { useQuery } from '@tanstack/react-query';

import { LeadView } from '@travauxlib/shared/src/types/api/client/LeadView';
import { request } from '@travauxlib/shared/src/utils/request';

type APIResponse = {
  lead: LeadView;
  calendlyGlobal?: string;
} | null;

const LEAD_PUBLIC_KEY = 'LEAD_PUBLIC';

export const useLeadPublic = (
  token: string | null,
): { isLoading: boolean; lead?: LeadView; isError: boolean; calendlyGlobal?: string } => {
  const { data, isLoading, isError } = useQuery<APIResponse>({
    queryKey: [LEAD_PUBLIC_KEY, token],
    queryFn: () => {
      if (token) {
        return request(`${APP_CONFIG.apiURL}/client/lead/public/${token}`);
      } else {
        return null;
      }
    },
  });
  if (!token) {
    return { isLoading: false, isError: true };
  }

  return {
    isLoading,
    lead: data?.lead,
    isError,
    calendlyGlobal: data?.calendlyGlobal,
  };
};
