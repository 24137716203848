import React from 'react';

import Architecture from '@travauxlib/shared/src/components/DesignSystem/assets/Architecture.svg?react';
import ChevronRight from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronRightFilled.svg?react';
import ProjectIcon from '@travauxlib/shared/src/components/DesignSystem/assets/Project.svg?react';
import Quote from '@travauxlib/shared/src/components/DesignSystem/assets/Quote.svg?react';
import { ColoredCircle } from '@travauxlib/shared/src/components/DesignSystem/components/ColoredCircle';
import { IconButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { ProjectStep } from '@travauxlib/shared/src/types/api/common/ProjectStep';

const preChantierAndConsultationStep = {
  icon: <Quote />,
  title: 'Consultation entreprises',
};

const configurationByProjectStep: { [k in ProjectStep]?: any } = {
  [ProjectStep.EtudeProject]: {
    icon: <ProjectIcon />,
    title: 'Étude du projet',
  },
  [ProjectStep.EtudeArchi]: {
    icon: <Architecture />,
    title: 'Conception architecte',
  },
  // TODO: Vu avec Eng et Jalil, l'avant chantier n'a que peu d'interet de presentation mais peut presenter encore un
  // TODO: interet dans le backend, il est donc retiré de la presentation mais conservé dans le back avant qu'on decide
  // TODO: Quoi en faire de maniere sereine
  [ProjectStep.Pro]: preChantierAndConsultationStep,
  [ProjectStep.Devis]: preChantierAndConsultationStep,
  [ProjectStep.PreChantier]: preChantierAndConsultationStep,
};

type ProjectInfoProps = {
  projectStep: ProjectStep;
  projectShortAddress?: string;
};

export const ProjectInfo: React.FC<ProjectInfoProps> = ({ projectStep, projectShortAddress }) => (
  <div className="flex justify-between items-center">
    <div className="flex">
      <div className="p-xxs">
        <ColoredCircle variant="secondary" size="lg">
          {configurationByProjectStep[projectStep]?.icon ?? <></>}
        </ColoredCircle>
      </div>
      <div className="p-xxs flex flex-col justify-center">
        <div className="text-b1 font-bold">
          {configurationByProjectStep[projectStep]?.title ?? <>&nbsp;</>}
        </div>
        <div className="mt-xxs text-b2 text-neutral-600">{projectShortAddress ?? <>&nbsp;</>}</div>
      </div>
    </div>
    <IconButtonLink to="etapes">
      <ChevronRight />
    </IconButtonLink>
  </div>
);
