import React from 'react';

import classNames from 'classnames';

import { DevisLocation } from '@travauxlib/shared/src/types/api/domain/prodevisfactures/DevisLocation';
import { locationTypes } from '@travauxlib/shared/src/utils/locations';
import { addLocation, deleteLocation } from '@travauxlib/shared/src/utils/modifyLocations';

import { LocationAdder } from './LocationAdder';
import { LocationsList } from './LocationsList';

type Props = {
  locations: DevisLocation[];
  onChange: (newLocations: DevisLocation[]) => void;
  onDeleteLocation: (newLocations: DevisLocation[]) => void;
  locationCanBeDisabled?: boolean;
};

export const LocationsSelector: React.FC<Props> = ({
  locations,
  onChange,
  onDeleteLocation,
  locationCanBeDisabled,
}) => {
  const handleAddLocation = (newLocation: DevisLocation): void =>
    onChange(addLocation(newLocation, locations));

  const handleDeleteLocation = (deletedLocation: DevisLocation): void =>
    onDeleteLocation(deleteLocation(deletedLocation!, locations));

  return (
    <div className="flex flex-wrap -mx-md relative overflow-hidden">
      <div
        className="w-full px-md sm-desktop:max-w-6/12  sm-desktop:pr-xl py-sm sm-desktop:py-0 scrollbar overflow-auto border-b sm-desktop:border-0 max-h-[12rem] sm-desktop:max-h-[22rem]"
        id="locations-selector"
      >
        {locationTypes.map(locationType => (
          <LocationAdder
            key={locationType.label}
            locationType={locationType}
            locations={locations}
            onAdd={handleAddLocation}
            onDelete={handleDeleteLocation}
            disableReason={
              (locationCanBeDisabled && locationType.getDisabledReason?.(locations)) || undefined
            }
          />
        ))}
      </div>
      <div className="w-full px-md py-sm sm-desktop:py-0 sm-desktop:max-w-6/12 overflow-auto max-h-[12rem] sm-desktop:max-h-[22rem]">
        <div
          className={classNames('sm-desktop:px-lg min-h-full sm-desktop:border-l', {
            'h-full': locations.length === 0,
          })}
          id="rooms-scrollable"
        >
          <LocationsList
            locations={locations}
            onChange={onChange}
            onDeleteLocation={handleDeleteLocation}
          />
        </div>
      </div>
    </div>
  );
};
