import React from 'react';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { InlineAlert } from '@travauxlib/shared/src/components/DesignSystem/components/InlineAlert';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';
import { RadioButtonsListField } from '@travauxlib/shared/src/components/DesignSystem/components/RadioButtonsList/Field';
import { RemiseType } from '@travauxlib/shared/src/types/api/domain/prodevisfactures/RemiseType';
import { required } from '@travauxlib/shared/src/utils/form';

type Props = {
  hasAtLeastOneRemise: boolean;
  currentRemiseType: RemiseType;
  submitting: boolean;
};

const options = [
  { label: 'Remise en € TTC', value: RemiseType.Montant, inputSuffix: '€' },
  { label: 'Remise en %', value: RemiseType.Pourcentage, inputSuffix: '%' },
];

export const RemiseSelector: React.FC<Props> = ({
  hasAtLeastOneRemise,
  currentRemiseType,
  submitting,
}) => (
  <>
    {hasAtLeastOneRemise ? (
      <InlineAlert className="mb-xs" variant="info">
        {currentRemiseType === RemiseType.Montant
          ? 'La remise en pourcentage est désactivée, car votre première remise ajoutée est en euros. Vous pouvez uniquement ajouter des remises en euros TTC'
          : 'La remise en euros TTC est désactivée, car votre première remise ajoutée est en pourcentage. Vous pouvez uniquement ajouter des remises en pourcentages'}
      </InlineAlert>
    ) : (
      <RadioButtonsListField className="mb-md" options={options} name="type" inline />
    )}
    <div className="flex gap-md mb-lg">
      <InputField
        className="flex-1"
        id="remise-label"
        name="label"
        label="Nom de la remise"
        validate={required}
      />
      <NumberInputField
        className="flex-1"
        id="remise-value"
        name="value"
        label={
          currentRemiseType === 'montant' ? 'Montant de la remise' : 'Pourcentage de la remise'
        }
        suffix={currentRemiseType === 'montant' ? '€' : '%'}
        min={0}
        validate={required}
      />
      <Button className="self-start ml-xs" type="submit" disabled={submitting} loading={submitting}>
        Ajouter
      </Button>
    </div>
  </>
);
