import React from 'react';

import classNames from 'classnames';
import { Link, Route, Routes, useParams } from 'react-router';

import CrossSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/CrossSymbolFilled.svg?react';
import { IconButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { WithTooltip } from '@travauxlib/shared/src/components/DesignSystem/components/WithTooltip';
import { DevisLocation } from '@travauxlib/shared/src/types/api/domain/prodevisfactures/DevisLocation';

import { Body } from 'features/Estimateur/components/Body';
import { Answer, Estimation } from 'features/Estimateur/types';
import { newEstimationBasePath } from 'features/Estimateur/utils/paths';

import { Result } from '../../Result';
import { categories, CategoryWrapper } from '../utils/categories';

type Props = {
  estimation: Estimation;
  organizationName?: string;
  affiliateToken?: string;
  submitAnswer: (answer: Answer, index?: number) => Promise<unknown>;
  submitEstimation: (estimation: Estimation) => Promise<unknown>;
  deleteAnswer: (answerIndex: number) => Promise<unknown>;
  printUrl?: string;
  isClientEstimateur: boolean;
  locations: DevisLocation[];
};

export const oneAnswerForms = ['sols', 'electricite', 'fenetres', 'menuiseries', 'chauffage'];

type CategoryWrapperProps = {
  estimation: Estimation;
  submitAnswer: (answer: Answer, index?: number) => Promise<unknown>;
  locations: DevisLocation[];
};

const CategoryElement: React.FC<CategoryWrapperProps> = ({
  estimation,
  submitAnswer,
  locations,
}) => {
  const { value, '*': rawIndex } = useParams();

  let index: number | undefined;
  if (oneAnswerForms.includes(value!)) {
    index =
      estimation.answers && estimation.answers.findIndex(({ answerType }) => answerType === value);
    index = index === -1 ? undefined : index;
  } else {
    index = rawIndex ? (parseInt(rawIndex, 10) as number | undefined) : undefined;
  }
  const { getInitialValues, Component } = categories.find(c => c.value === value)!;
  const initialValues =
    index !== undefined && estimation.answers?.[index]
      ? estimation.answers[index]
      : getInitialValues && getInitialValues(estimation);

  return (
    <Body className="container my-xl w-full">
      <Component
        submitAnswer={async answer =>
          submitAnswer({ ...answer, gamme: answer.gamme || estimation.context.defaultGamme }, index)
        }
        locations={locations}
        initialValues={{
          answerType: value,
          ...initialValues,
        }}
        gamme={initialValues?.gamme || estimation.context.defaultGamme}
      />
    </Body>
  );
};

export const QuestionRoutes: React.FC<Props> = ({
  estimation,
  organizationName,
  affiliateToken,
  submitAnswer,
  submitEstimation,
  deleteAnswer,
  printUrl,
  isClientEstimateur,
  locations,
}) => (
  <Routes>
    <Route
      path=":value/new/*"
      element={
        <CategoryElement
          estimation={estimation}
          submitAnswer={submitAnswer}
          locations={locations}
        />
      }
    />
    {estimation.result && (
      <Route
        path="result"
        element={
          <Result
            isClientEstimateur={isClientEstimateur}
            printUrl={printUrl}
            estimation={estimation}
            submitAnswer={submitAnswer}
            submitEstimation={submitEstimation}
            deleteAnswer={deleteAnswer}
            organizationName={organizationName}
            affiliateToken={affiliateToken}
          />
        }
      />
    )}
    <Route
      path="add"
      element={
        <div className="w-full">
          <div className="flex flex-wrap !justify-center">
            <Body className="container-sm w-full sm-desktop:max-w-6/12 px-md my-xl">
              <div className="flex justify-between mb-lg">
                <h3>Ajouter une prestation</h3>
                <IconButtonLink to="../result">
                  <CrossSymbol />
                </IconButtonLink>
              </div>
              <div className="flex flex-col items-start">
                {categories.map(category => {
                  const disabledReason = category.getDisabledReason?.(estimation);
                  const index = estimation.answers?.length;
                  const link = (
                    <Link
                      className={classNames(
                        'mb-sm w-full text-decoration-black',
                        !!disabledReason && 'pointer-events-none',
                      )}
                      key={category.value}
                      to={`../${category.value}/${newEstimationBasePath}/${index}`}
                    >
                      <CategoryWrapper className="block text-dark py-sm" category={category}>
                        {category.label}
                      </CategoryWrapper>
                    </Link>
                  );

                  return disabledReason ? (
                    <WithTooltip
                      key={category.value}
                      trigger={<div className="w-full mb-sm">{link}</div>}
                    >
                      <div>{disabledReason}</div>
                    </WithTooltip>
                  ) : (
                    link
                  );
                })}
              </div>
            </Body>
          </div>
        </div>
      }
    />
  </Routes>
);
