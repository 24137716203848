import React from 'react';

import { FileDropzone } from '@travauxlib/shared/src/components/DesignSystem/components/FileDropzone/FileDropZoneWrapper';
import {
  useOpenModal,
  ModalContent,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { ChantierPvView } from '@travauxlib/shared/src/types/api/common/ChantierPvView';
import { noop } from '@travauxlib/shared/src/utils/noop';

type OwnProps = {
  pv: ChantierPvView;
  title: string;
  onlyPreview?: boolean;
};

type Props = OwnProps & {
  handleClose: () => void;
};

const ConsultPvModal: React.FC<Props> = ({ handleClose, pv }) => (
  <ModalContent
    validateAction={{
      label: 'Fermer',
      onClick: handleClose,
    }}
  >
    <FileDropzone
      uploadFiles={noop as any}
      onChange={noop as any}
      onlyPreview
      value={pv.documents}
      multiple
    />
  </ModalContent>
);

export const useOpenConsultPvModal = (): ((props: OwnProps) => void) => {
  const openModal = useOpenModal();

  return (props: OwnProps) =>
    openModal(ConsultPvModal, {
      ...props,
      size: 'md',
      closable: true,
    });
};
