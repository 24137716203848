import React from 'react';

import classNames from 'classnames';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';

import AlertCircle from '@travauxlib/shared/src/components/DesignSystem/assets/AlertCircle.svg?react';
import CheckCircle from '@travauxlib/shared/src/components/DesignSystem/assets/CheckCircle.svg?react';
import ChevronRight from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronRightFilled.svg?react';
import Clock from '@travauxlib/shared/src/components/DesignSystem/assets/Clock.svg?react';
import CrossCircle from '@travauxlib/shared/src/components/DesignSystem/assets/CrossCircle.svg?react';
import { Table } from '@travauxlib/shared/src/components/DesignSystem/components/Table';
import { ColumnConfig } from '@travauxlib/shared/src/components/DesignSystem/components/Table/types';
import { RowAction } from '@travauxlib/shared/src/components/DesignSystem/components/Table/types';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { SuiviChantierCycleHistory } from '@travauxlib/shared/src/features/SuiviChantier/types';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

const statusToColorMap: {
  [k: string]: { icon: React.ReactElement; iconColor: string; text?: string };
} = {
  pending: {
    iconColor: 'text-info-400',
    text: 'text-info-400',
    icon: <Clock />,
  },
  rejected: {
    iconColor: 'text-error-400',
    text: 'text-error-400',
    icon: <CrossCircle />,
  },
  agreed: {
    iconColor: 'text-success-400',
    icon: <CheckCircle />,
  },
  disputed: {
    iconColor: 'text-warning-400',
    icon: <AlertCircle />,
  },
  canceled: {
    iconColor: 'text-error-400',
    icon: <CrossCircle />,
  },
};

const columnConfig = (
  isTabletOrMobile: boolean,
  hasNoRowActions: boolean,
): Array<ColumnConfig<SuiviChantierCycleHistory>> => [
  {
    name: 'Date',
    columnKey: 'createdAt',
    renderValue: (d: string) =>
      isTabletOrMobile ? (
        <>
          <div className="text-sm text-neutral-700">{dayjs(d).get('year')}</div>{' '}
          <div>{dayjs(d).format('MM/DD')}</div>
        </>
      ) : (
        <>{formatFrenchDate(d)}</>
      ),
  },
  {
    name: 'Progression',
    renderValue: (_: unknown, item) => {
      const etapes = item.etapes;
      const valueToDisplay =
        etapes.client?.pourcentage ?? etapes.tp?.pourcentage ?? etapes.pro.pourcentage;
      return <>{valueToDisplay}%</>;
    },
    hideSort: true,
  },
  ...(isTabletOrMobile
    ? []
    : [
        {
          name: 'Montant demandé',
          renderValue: (_: unknown, item: SuiviChantierCycleHistory) => (
            <EURCurrency amount={item.etapes.pro.montantALiberer} />
          ),
          hideSort: true,
        },
      ]),
  {
    name: isTabletOrMobile ? 'Montant' : 'Montant perçu',
    renderValue: (_: unknown, item) => (
      <>
        {isTabletOrMobile && (
          <div className="text-sm text-neutral-700">
            Demandé <EURCurrency amount={item.etapes.pro.montantALiberer} />
          </div>
        )}
        <div className="flex items-center">
          <div
            className={classNames(
              'w-md h-md flex mr-xxs',
              statusToColorMap[item.status]?.iconColor,
            )}
          >
            {statusToColorMap[item.status]?.icon}
          </div>
          <div className={classNames('font-medium', statusToColorMap[item.status]?.text)}>
            {item.status === 'pending' ? (
              <>En attente</>
            ) : item.status === 'canceled' ? (
              <div className="text-error-400">Annulé</div>
            ) : (
              <EURCurrency
                amount={
                  Math.max(
                    item.etapes.client?.montantALiberer ?? 0,
                    0,
                  ) /* Initial rejected ADP will generate negative values */
                }
              />
            )}
          </div>
        </div>
      </>
    ),
    hideSort: true,
  },
  ...(hasNoRowActions
    ? [
        {
          headless: true,
          renderValue: () => (
            <div className="h-lg w-lg">
              <ChevronRight />
            </div>
          ),
        },
      ]
    : []),
];

type Props = {
  suiviChantierHistoryList: SuiviChantierCycleHistory[];
  getRowClickUrl: (suiviChantierUuid: string) => string;
  rowActions?: (item: SuiviChantierCycleHistory) => RowAction[];
};

export const SuiviChantierHistoryTable: React.FC<Props> = ({
  suiviChantierHistoryList,
  getRowClickUrl,
  rowActions,
}) => {
  const isTabletOrMobile = useIsTabletOrMobile(true);
  const navigate = useNavigate();

  return (
    <Table<SuiviChantierCycleHistory>
      columnConfigs={columnConfig(isTabletOrMobile, rowActions === undefined)}
      items={suiviChantierHistoryList}
      defaultSort={{ columnKey: 'createdAt', order: 'desc' }}
      onRowClick={history => navigate(getRowClickUrl(history.uuid))}
      {...(rowActions && { rowActions })}
    />
  );
};
