import React from 'react';

import { Navigate, useSearchParams } from 'react-router';

export const RedirectToNewForm: React.FC = () => {
  const [searchParams] = useSearchParams();
  const oldRouteAffiliateTotken = searchParams.get('affiliateToken') || undefined;

  return <Navigate to={`/partner/${oldRouteAffiliateTotken}/send-lead`} />;
};
