import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import {
  AdminEventsProperties,
  AppFeatures,
  PvReceptionProperties,
  TrackingEvents,
} from '@travauxlib/shared/src/utils/tracking';

class PvReceptionClass extends Tracker<AppFeatures> {
  onValidated(properties: {
    [AdminEventsProperties.ProjetUUID]: string;
    [PvReceptionProperties.PvReceptionAvecReserves]: boolean;
  }): void {
    this.sendTracking({
      event: TrackingEvents.Validated,
      properties,
    });
  }
}

export const PvReception = new PvReceptionClass(AppFeatures.PvReception);
