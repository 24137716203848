import React, { createContext, useContext, useEffect, useState } from 'react';

import { DevisView } from '@travauxlib/shared/src/types/api/client/DevisView';
import { TypeSuivi } from '@travauxlib/shared/src/types/api/common/TypeSuivi';

import { useSetDefaultTypeSuivi } from '../api/useSetDefaultTypeSuivi';

type CurrentDevisContextProps = {
  signe: boolean;
  typeSuivi: TypeSuivi;
  setTypeSuivi: (typeSuivi: TypeSuivi) => void;
};

const CurrentDevisContext = createContext<CurrentDevisContextProps>({
  signe: false,
  typeSuivi: TypeSuivi.CustomerCare,
  setTypeSuivi: () => {},
});

const useCurrentDevis = (): CurrentDevisContextProps => useContext(CurrentDevisContext);

type Props = {
  devis: DevisView;
  children: React.ReactNode;
};

const CurrentDevisProvider: React.FC<Props> = ({ devis, children }) => {
  const { setDefaultTypeSuivi } = useSetDefaultTypeSuivi();
  const [typeSuivi, setTypeSuivi] = useState<TypeSuivi>(
    devis.proContext?.signature?.typeSuivi || devis.modalitesHemea.defaultTypeSuivi,
  );

  useEffect(() => {
    if (devis.modalitesHemea.defaultTypeSuivi !== typeSuivi) {
      setDefaultTypeSuivi({ token: devis.token, typeSuivi });
    }
  }, [devis.modalitesHemea.defaultTypeSuivi, typeSuivi]);

  return (
    <CurrentDevisContext.Provider
      value={{ typeSuivi, setTypeSuivi, signe: !!devis.proContext?.signe }}
    >
      {children}
    </CurrentDevisContext.Provider>
  );
};

export { useCurrentDevis, CurrentDevisProvider };
