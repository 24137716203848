import React from 'react';

import { Form } from 'react-final-form';

import DeleteFilled from '@travauxlib/shared/src/components/DesignSystem/assets/DeleteFilled.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';
import { Table } from '@travauxlib/shared/src/components/DesignSystem/components/Table';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { ProDevisFacturePrime } from '@travauxlib/shared/src/types/api/domain/prodevisfactures/ProDevisFacturePrime';
import { required } from '@travauxlib/shared/src/utils/form';

import { TotalSummary } from './TotalSummary';

type Props = {
  onChange: (primes: ProDevisFacturePrime[]) => void;
  primes: ProDevisFacturePrime[];
  totalTTCAvantPrimes: number;
  totalTTC: number;
};

export const PrimeTab: React.FC<Props> = ({ onChange, primes, totalTTCAvantPrimes, totalTTC }) => (
  <Form<ProDevisFacturePrime>
    onSubmit={async (values, form) => {
      await onChange([...primes, values]);
      setTimeout(form.restart);
    }}
  >
    {({ handleSubmit, submitting }) => (
      <form className="flex flex-col gap-md" onSubmit={handleSubmit}>
        <TotalSummary
          totalTTCLabel="Reste à payer"
          title="Avant prime(s)"
          totalTTC={totalTTCAvantPrimes}
        />
        <div className="flex gap-md mb-xs">
          <InputField
            className="flex-1"
            id="prime-label"
            name="label"
            label="Nom de la prime"
            validate={required}
          />
          <NumberInputField
            className="flex-1"
            id="prime-amount"
            name="amount"
            label="Montant de la prime"
            suffix="€"
            min={0}
            validate={required}
          />
          <Button
            className="self-start ml-xs"
            type="submit"
            disabled={submitting}
            loading={submitting}
          >
            Ajouter
          </Button>
        </div>
        {primes.length !== 0 && (
          <>
            <Table<ProDevisFacturePrime>
              columnConfigs={[
                { name: 'Nom', columnKey: 'label', hideSort: true },
                {
                  name: 'Montant',
                  columnKey: 'amount',
                  renderValue: (amount: number) => <EURCurrency amount={amount} />,
                  hideSort: true,
                },
                {
                  headless: true,
                  renderValue: (_, prime) => (
                    <IconButton
                      className="ml-auto"
                      data-testid={`delete-${prime.label}`}
                      onClick={() => onChange(primes.filter(p => p.label !== prime.label))}
                    >
                      <DeleteFilled />
                    </IconButton>
                  ),
                  hideSort: true,
                },
              ]}
              items={primes}
            />
            <TotalSummary
              totalTTCLabel="Reste à payer"
              title="Après prime(s)"
              totalTTC={totalTTC}
            />
          </>
        )}
      </form>
    )}
  </Form>
);
