import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

const handleLogout = (): Promise<void> => {
  const url = `${APP_CONFIG.apiURL}/client/logout`;
  return request(url, { method: 'POST' });
};

export const useLogout = (): typeof handleLogout => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutateAsync: logout } = useMutation({
    mutationFn: handleLogout,
    onSuccess: () => {
      queryClient.removeQueries();
      navigate('/');
    },
    onError: () => {
      toast.error('Une erreur est survenue lors de la déconnexion');
    },
  });

  return logout;
};
