import React, { useEffect, useState } from 'react';

import { InlineAlert } from '@travauxlib/shared/src/components/DesignSystem/components/InlineAlert';
import {
  Modal,
  ModalContent,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { RadioButtonsList } from '@travauxlib/shared/src/components/DesignSystem/components/RadioButtonsList';

import {
  useClientDeclaredSource,
  useSetClientDeclaredSource,
} from '../api/useClientDeclaredSource';

type Props = {
  projectUuid: string;
};

const declarationValues = [
  {
    label: 'Recommandé par mon entourage',
    value: 'friends_familly',
  },
  {
    label: 'Recommandé par mon agent immobilier ou mon courtier',
    value: 'partner',
  },
  {
    label: 'En cherchant sur internet',
    value: 'web_search',
  },
  {
    label: 'Sur les réseaux sociaux',
    value: 'social_network',
  },
  {
    label: 'Je ne sais plus',
    value: 'idk',
  },
];

export const ClientDeclarativeSourceModal: React.FC<Props> = ({ projectUuid }) => {
  const { isLoading, hasReplied } = useClientDeclaredSource(projectUuid);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [declarationValue, setDeclarationValue] = useState<string | undefined>();

  const setClientDeclaredSource = useSetClientDeclaredSource(projectUuid);

  useEffect(() => {
    if (!isLoading && !hasReplied) {
      setIsModalOpen(true);
    }
  }, [isLoading, hasReplied]);

  return (
    <Modal
      title="Bienvenue sur votre espace client !"
      isOpen={isModalOpen}
      handleClose={() => setClientDeclaredSource('idk').then(() => setIsModalOpen(false))}
      validateAction={{
        label: 'Valider',
        onClick: () => {
          declarationValue
            ? setClientDeclaredSource(declarationValue).then(() => setIsModalOpen(false))
            : setErrorMessage(true);
        },
      }}
    >
      <ModalContent>
        <div className="font-medium">Comment avez vous connu hemea ?</div>
        <RadioButtonsList
          id="declaration"
          value={declarationValue}
          onChange={setDeclarationValue}
          options={declarationValues}
          className="mt-sm mb-xxs"
          optionsClassName="mb-xs"
        />
        {errorMessage && (
          <InlineAlert variant="error">Merci de sélectionner une réponse</InlineAlert>
        )}
      </ModalContent>
    </Modal>
  );
};
