import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router';

import { Person } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

import { Estimation } from 'features/Estimateur/types';

type EstimationContext = {
  estimation: Estimation;
  logoUrl?: string;
  showLogoInPdf: boolean;
  internalAccountManager?: Person;
};

const fetchEstimation = ({
  queryKey: [, { uuid, isClient }],
}: QueryFunctionContext<
  [string, { uuid: string; isClient: boolean }]
>): Promise<EstimationContext> => {
  const url = isClient
    ? `${APP_CONFIG.apiURL}/client/client-estimateur/estimation/${uuid}`
    : `${APP_CONFIG.apiURL}/partners/estimateur/estimation/${uuid}`;
  return request(url);
};

export const useEstimation = ({
  uuid,
  isClient,
}: {
  uuid: string;
  isClient?: boolean;
}): { estimationContext?: EstimationContext; isDevis: boolean } => {
  const { data: estimationContext } = useQuery<EstimationContext>({
    queryKey: ['estimation', { isClient, uuid }],
    queryFn: fetchEstimation,
  });
  const location = useLocation();
  const isDevis = new URLSearchParams(location.search).get('isDevis') === 'true';

  return { estimationContext, isDevis };
};
