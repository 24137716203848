import React from 'react';

import Quote from '@travauxlib/shared/src/components/DesignSystem/assets/Quote.svg?react';
import QuoteAdd from '@travauxlib/shared/src/components/DesignSystem/assets/QuoteAdd.svg?react';
import { Props as TagProps } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { DevisStatuses } from '@travauxlib/shared/src/types';
import { DevisMinimalView } from '@travauxlib/shared/src/types/api/client/DevisMinimalView';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import { DevisCardExtended } from './DevisCardExtended';

type Props = {
  title: string;
  devis: DevisMinimalView;
  isArchitecte: boolean;
  className?: string;
};

const tagByStatus: { [K in DevisStatuses]?: { label: string; variant: TagProps['variant'] } } = {
  [DevisStatuses.sent]: { label: 'Reçu', variant: 'warning' },
  [DevisStatuses.signed]: { label: 'Signé', variant: 'success' },
  [DevisStatuses.canceled]: { label: 'Annulé', variant: 'error' },
};

export const MainDevisCardExtended: React.FC<Props> = ({
  title,
  devis,
  isArchitecte,
  className,
}) => {
  const isTSFromNonArchitecte = !isArchitecte && devis.isTS;
  const date = devis.dateSignature || devis.sentAt;
  const dateWording = devis.dateSignature ? 'Date de signature' : "Date d'envoi";

  return (
    <DevisCardExtended
      className={className}
      icon={isTSFromNonArchitecte ? <QuoteAdd /> : <Quote />}
      title={title}
      version={devis.version || 1}
      tag={tagByStatus[devis.status] ?? tagByStatus[DevisStatuses.sent]!}
      dateSection={
        <>
          <span>{dateWording}</span>
          {formatFrenchDate(date)}
        </>
      }
      prixTotalTTC={devis.prixTotalTTC}
      to={`..//pro/devis/${devis.token}`}
    />
  );
};
