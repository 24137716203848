import React, { useEffect } from 'react';

import { Form } from 'react-final-form';
import { useLocation, useNavigate, useParams } from 'react-router';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { CustomerSatisfactionField } from '@travauxlib/shared/src/components/DesignSystem/components/CustomerSatisfaction/Field';
import { TextareaField } from '@travauxlib/shared/src/components/DesignSystem/components/Textarea/Field';
import { toast } from '@travauxlib/shared/src/components/Notifications';
import { required } from '@travauxlib/shared/src/utils/form';

import { ClientCustomerSatisfactionTracking } from 'utils/tracking/ClientCustomerSatisfactionTracking';

import { useCreateClientCustomerSatisfaction } from '../api/useCreateClientCustomerSatisfaction';
import { useGetClientCustomerSatisfaction } from '../api/useGetClientCustomerSatisfaction';

export const ClientCustomerSatisfactionPage: React.FC = () => {
  const location = useLocation();
  const { uuid } = useParams();
  const navigate = useNavigate();
  const createClientCustomerSatisfaction = useCreateClientCustomerSatisfaction();
  const { clientCustomerSatisfaction, isLoading } = useGetClientCustomerSatisfaction(uuid!);
  const { condition, feature, redirect } = location?.state;

  const hasRepliedToThisStage =
    clientCustomerSatisfaction.filter(
      clientCustomerSatisfaction => clientCustomerSatisfaction.condition === condition,
    ).length > 0;

  useEffect(() => {
    if (!isLoading && hasRepliedToThisStage) {
      navigate(redirect);
    }
  }, [isLoading, hasRepliedToThisStage]);

  return (
    <Form<{ infoComplement: string; score: number }>
      onSubmit={async ({ score, infoComplement }) => {
        try {
          await createClientCustomerSatisfaction({
            score,
            infoComplement,
            scoreType: 'customerSatisfaction',
            projectUuid: uuid!,
            condition,
            feature,
          });
          ClientCustomerSatisfactionTracking.onClientCustomerSatisfactionCompleted({
            'Customer Satisfaction Condition': condition,
            'Customer Satisfaction Score': score,
            'Customer Satisfaction Type': 'customerSatisfaction',
            'Customer Satisfaction Feature': feature,
          });
          navigate(redirect);
        } catch (err) {
          toast.error('Une erreur est survenue');
          throw err;
        }
      }}
    >
      {({ handleSubmit, submitting, values }) => (
        <div>
          <div className="text-h4 text-neutral-800 font-bold mb-lg mt-xs mx-md">
            Enquête de satisfaction client
          </div>
          <div className="bg-neutral-0 p-md rounded">
            <CustomerSatisfactionField
              validate={required}
              name="score"
              id="score"
              containerClassName="mb-md"
            />
            <div className="text-b2 text-neutral-800 font-bold mb-md">
              Dites-nous en plus sur votre expérience jusqu'à maintenant
            </div>
            <TextareaField
              name="infoComplement"
              id="infoComplement"
              placeholder="Décrire les points marquants de mon chantier"
              rows={3}
              helperText="Facultatif"
            />
            <div className="flex gap-md justify-end">
              <Button
                variant="secondary"
                onClick={() => {
                  ClientCustomerSatisfactionTracking.onClientCustomerSatisfactionSkipped({
                    'Customer Satisfaction Condition': condition,
                    'Customer Satisfaction Score': values.score,
                    'Customer Satisfaction Type': 'customerSatisfaction',
                    'Customer Satisfaction Feature': feature,
                  });
                  navigate(redirect);
                }}
              >
                Ignorer
              </Button>
              <Button type="submit" disabled={submitting} onClick={handleSubmit}>
                Envoyer
              </Button>
            </div>
          </div>
        </div>
      )}
    </Form>
  );
};
