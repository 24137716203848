import React from 'react';

import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { DevisFactureCard } from '@travauxlib/shared/src/features/DevisDisplay/components/DevisFactureCard';
import { InsuranceView } from '@travauxlib/shared/src/types/api/client/DevisView';

type Props = {
  insurances: InsuranceView[];
};

const decennalesLabel = ['Décennale + RC pro', 'Décennale'];

export const Assurance: React.FC<Props> = ({ insurances }) => {
  const decennales = insurances.filter(({ label }) => decennalesLabel.includes(label));

  return (
    <DevisFactureCard>
      <div className="text-b2 mb-md">
        <div className="font-bold mb-xxs">Assurance décennale</div>
        <div className="text-neutral-700">
          L'assurance décennale est obligatoire pour un professionnel et vous pouvez résilier votre
          contrat à tout moment à défaut de production de l'attestation d'assurance par l'entreprise
          sous 8 jours suivant votre demande.
        </div>
        {decennales.map(({ file }) => (
          <div key={file}>
            <a target="_blank" rel="noopener noreferrer" href={file}>
              Voir l'assurance
            </a>
          </div>
        ))}
      </div>
      <div className="font-bold text-b2 mb-xxs">Assurance Dommages Ouvrage</div>
      <div className="text-neutral-700 text-b2">
        Vous êtes tenu de souscrire de votre côté à une assurance Dommages Ouvrage. <br />
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.service-public.fr/particuliers/vosdroits/F2032"
        >
          En savoir plus
        </Link>{' '}
        /{' '}
        <Link target="_blank" rel="noopener noreferrer" href="https://travauxlib.batiassure.fr/">
          Obtenir un devis
        </Link>
      </div>
    </DevisFactureCard>
  );
};
