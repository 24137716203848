import { FacebookPixelUserInfo } from '@travauxlib/shared/src/api/tracking';

import { sendEventToTrackingModules } from 'utils/tracking';

import { EstimateurEventAction, EstimateurEvent, Estimateur } from '../utils/constants';
import {
  useIsCreditDuNord,
  useIsEstimateurClient,
  useIsEstimateurPartner,
  useIsEstimateurPublic,
  useIsHosman,
} from '../utils/paths';

export const useEstimateurTracking = (): ((
  eventAction: EstimateurEventAction,
  userInfo?: FacebookPixelUserInfo,
) => void) => {
  const estimateursConfig: {
    [K in Estimateur]: {
      isCurrentEstimateur: boolean;
    };
  } = {
    public: {
      isCurrentEstimateur: useIsEstimateurPublic(),
    },
    cdn: {
      isCurrentEstimateur: useIsCreditDuNord(),
    },
    hosman: {
      isCurrentEstimateur: useIsHosman(),
    },
    client: {
      isCurrentEstimateur: useIsEstimateurClient(),
    },
    partner: {
      isCurrentEstimateur: useIsEstimateurPartner(),
    },
  };

  return (eventAction: EstimateurEventAction, userInfo?: FacebookPixelUserInfo) =>
    Object.values(Estimateur).forEach(estimateur => {
      if (estimateursConfig[estimateur].isCurrentEstimateur) {
        sendEventToTrackingModules({
          event: EstimateurEvent.Estimateur,
          userInfo,
          data: {
            category: estimateur,
            action: eventAction,
            label: `${EstimateurEvent.Estimateur}.${estimateur}.${eventAction}`,
          },
        });
      }
    });
};
