import React from 'react';

import { MultilineParagraph } from '@travauxlib/shared/src/components/MultilineParagraph';
import { DisplayedComment } from '@travauxlib/shared/src/features/DevisDisplay/components/Comments/DisplayedComment';
import { SenderNames } from '@travauxlib/shared/src/types';
import { DevisCommentView } from '@travauxlib/shared/src/types/api/common/DevisCommentView';

type Props = {
  comments: DevisCommentView[];
  senderNames: SenderNames;
  itemNumber: string;
  designation: string;
  description?: string;
};

export const PrintedCommentGroup: React.FC<Props> = ({
  comments,
  senderNames,
  itemNumber,
  designation,
  description,
}) => {
  const commentGroup = comments.map(comment => {
    const senderName = senderNames[comment.senderType];

    return <DisplayedComment senderName={senderName} comment={comment} key={comment.uuid} />;
  });

  return (
    <div className="flex mb-md">
      <span className="text-gray-600 basis-[2.5rem]">{itemNumber}</span>
      <div>
        {designation}
        {description && (
          <div className="mt-xs">
            <MultilineParagraph
              paragraphClassName="mb-0 font-italic text-gray-600"
              text={description}
              hasLinksOpenedInNewWindow
            />
          </div>
        )}
        <div className="mt-xs">{commentGroup}</div>
      </div>
    </div>
  );
};
