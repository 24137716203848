import React, { useState } from 'react';

import { Route, Routes, useSearchParams } from 'react-router';

import { ContentLayout } from '../components/ContentLayout';
import { Layout } from '../components/Layout';
import { ProjectSaved } from '../components/ProjectSaved';
import { RedirectToFirstStep } from '../components/RedirectToFirstStep';
import { Steps } from '../components/Steps';
import { AccountForm } from '../features/AccountForm';
import { FormValues } from '../types';
import { onboardingAccountPath, onboardingProjectSavedPath } from '../utils/constants';
import { projectSteps } from '../utils/steps/projectSteps';

type Props = {
  values: FormValues;
  setIsNavigatingToPastQuestion: (shouldUpdateFormValues: boolean) => void;
  isNavigatingToPastQuestion: boolean;
};

export const OnboardingRoutes: React.FC<Props> = ({
  values,
  setIsNavigatingToPastQuestion,
  isNavigatingToPastQuestion,
}) => {
  const [isEventAlreadyTriggered, setIsEventAlreadyTriggered] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [partenariat] = useState(searchParams.get('partenariat'));

  return (
    <Layout>
      <Routes>
        <Route
          path={onboardingAccountPath}
          element={
            <ContentLayout
              avisImageUrl="https://images.hemea.com/static/onboarding/onboard-3.png"
              avisText="Nous avons été très satisfaits de tous nos échanges avec hemea"
              avisAuteur="Maryse"
            >
              <AccountForm
                isEventAlreadyTriggered={isEventAlreadyTriggered}
                setIsEventAlreadyTriggered={setIsEventAlreadyTriggered}
                partenariat={partenariat}
              />
            </ContentLayout>
          }
        />
        <Route
          path={onboardingProjectSavedPath}
          element={
            <ContentLayout
              avisImageUrl="https://images.hemea.com/static/onboarding/onboard-3.png"
              avisText="Une expérience pour un projet réussi et conforme à nos attentes !"
              avisAuteur="Nicolas"
            >
              <ProjectSaved />
            </ContentLayout>
          }
        />
        <Route
          path=":stepId"
          element={
            <RedirectToFirstStep>
              <Steps
                steps={projectSteps(values)}
                setIsNavigatingToPastQuestion={setIsNavigatingToPastQuestion}
                isNavigatingToPastQuestion={isNavigatingToPastQuestion}
              />
            </RedirectToFirstStep>
          }
        />
      </Routes>
    </Layout>
  );
};
