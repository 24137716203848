import { useQuery } from '@tanstack/react-query';

import { BadgeCountView } from '@travauxlib/shared/src/types/api/client/BadgeCountView';
import { request } from '@travauxlib/shared/src/utils/request';

import { PROJECTS_KEY } from './useProjects';

export const useBadges = (projectUuid: string): { isLoading: boolean; badges: BadgeCountView } => {
  const { isLoading, data } = useQuery<BadgeCountView>({
    queryKey: [PROJECTS_KEY, projectUuid, 'badges'],
    queryFn: () => request(`${APP_CONFIG.apiURL}/client/project/${projectUuid}/badge-counts`),
  });

  return { isLoading, badges: data || { actions: 0, companies: {}, payments: {}, chantiers: 0 } };
};
