import React, { useEffect } from 'react';

import { Navigate, useNavigate, useParams } from 'react-router';

import { UserTracking } from '@travauxlib/shared/src/api/UserTracking';
import ChevronLeft from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronLeftFilled.svg?react';
import PriceBasisDuoColor from '@travauxlib/shared/src/components/DesignSystem/assets/PriceBasisDuoColor.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { FournituresWithTracking } from '@travauxlib/shared/src/features/Fournitures/components/FournituresWithTracking';
import { ClientActionType } from '@travauxlib/shared/src/types';
import { EspaceClientEventProperties } from '@travauxlib/shared/src/utils/tracking';

import { useFournitures } from 'features/EspaceClient3/features/Fournitures/api/useFournitures';
import { ListeFournituresTracking } from 'utils/tracking/ListeFournituresTracking';

import { useValidateAction } from '../Guides/api/useValidateAction';

export const Fournitures: React.FC = () => {
  const { uuid, chantierUuid } = useParams<{ uuid: string; chantierUuid: string }>();
  const { fournitures, isLoading } = useFournitures(uuid!);
  const navigate = useNavigate();

  const { validateAction } = useValidateAction();

  useEffect(() => {
    const hasCompletedFournitureList = fournitures.every(fournitureWithLocation =>
      fournitureWithLocation.fournitures.every(fourniture => !!fourniture.reference),
    );

    if (hasCompletedFournitureList) {
      validateAction({
        projectUuid: uuid!,
        actionType: ClientActionType.CompleteFournitures,
        entityIdentifier: chantierUuid,
      });
    }
  }, [fournitures]);

  if (isLoading) {
    return <Loader />;
  }

  if (fournitures.length === 0) {
    return <Navigate to="../suivi-chantier-recap" />;
  }

  return (
    <div className="h-screen">
      <div className="w-full bg-neutral-0 flex items-center shadow-xs gap-xxs px-md py-xs">
        <IconButton
          className="mr-xs shrink-0"
          onClick={() => navigate(`../suivi-chantier-recap`)}
          aria-label="back-button"
        >
          <ChevronLeft />
        </IconButton>
        <PriceBasisDuoColor className="size-xxl" />
        <div>
          <div className="text-b2 font-medium text-neutral-700">Vous consultez</div>
          <h5 className="text-h5 font-bold text-neutral-800 mb-0">Liste de fournitures</h5>
        </div>
      </div>
      <FournituresWithTracking
        fournitureList={fournitures}
        containerClassName="h-[calc(100dvh-4rem)] sm-desktop:px-[9.25rem] pb-md sm-desktop:pt-md"
        userType="client"
        editOrAddReferenceAction={{
          onClick: (fournitureUuid: string) =>
            navigate(
              `/projets/${uuid}/chantiers/${chantierUuid}/fournitures/${fournitureUuid}/reference`,
            ),
          disabled: false,
        }}
        doTracking={() => {
          ListeFournituresTracking.onFournituresListOpened({
            [EspaceClientEventProperties.ProjectUuid]: uuid!,
          });
          UserTracking.startSessionRecording();
        }}
      />
    </div>
  );
};
