import React, { useEffect } from 'react';

import _last from 'lodash/last';
import { useParams } from 'react-router';

import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { ProjectStep } from '@travauxlib/shared/src/types/api/common/ProjectStep';

import { EspaceClientTracking } from 'utils/tracking/EspaceClientTracking';

import { ActionsSection } from './components/ActionsSection';
import { MyAdvisorSection } from './components/MyAdvisorSection';
import { ProjectCard } from './components/ProjectCard';
import { ProjectNonAddressable } from './components/ProjectNonAddressable';
import { Title } from './components/Title';
import { useOpenCalendlyWatcher } from './hooks/useOpenCalendlyWatcher';

import { useProject } from '../../api/useProject';
import { ClientDeclarativeSourceModal } from '../../components/ClientDeclarativeSourceModal';
import { RecapChantierCard } from '../Chantier/components/RecapChantierCard';

export const Index: React.FC = () => {
  const { uuid } = useParams();
  const { isLoading, project } = useProject(uuid!);
  useEffect(() => {
    if (project) {
      EspaceClientTracking.onEspaceClientHomeViewed({
        'Project Uuid': project.uuid,
        'Project Name': project.title,
      });
    }
  }, [project]);
  useOpenCalendlyWatcher(project);

  if (isLoading) {
    return <Loader />;
  }

  if (!project) {
    return (
      <>
        Votre projet n'a pas été trouvé. <Link to="/">Revenir a l'accueil</Link>
      </>
    );
  }

  if (project && project?.nonAdressableReason) {
    return <ProjectNonAddressable project={project} />;
  }

  const firstChantier = _last(project?.chantiers ?? []);
  const isInChantierStep =
    project?.etape &&
    [ProjectStep.PreChantier, ProjectStep.Chantier, ProjectStep.PostChantier].includes(
      project.etape,
    );

  return (
    <div className="flex flex-col gap-md">
      <div className="mx-md sm-desktop:mx-0">
        <Title title={project.title} typeTravaux={project.typeTravaux} />
        {firstChantier && isInChantierStep ? (
          <RecapChantierCard
            pourcentageAvancement={firstChantier.pourcentageAvancementGlobal}
            projectShortAddress={firstChantier.formattedAddress}
            dateOuverture={firstChantier.dateDebutReelle}
          />
        ) : (
          <ProjectCard projet={project!} />
        )}
      </div>
      <ActionsSection project={project!} />
      <MyAdvisorSection projectUuid={uuid!} />
      {project && <ClientDeclarativeSourceModal projectUuid={project.uuid} />}
    </div>
  );
};
