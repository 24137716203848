import React from 'react';

import { YearlyRevenueItem } from './YearlyRevenueItem';

import { Investissement, Reseau } from '../../types';
import { mapping } from '../../utils/constants';

type Props = {
  tempsInvesti: Investissement;
  reseauApporteur: Reseau;
};

export const YearlyRevenue: React.FC<Props> = ({ reseauApporteur, tempsInvesti }) => {
  const revenue = mapping[tempsInvesti][reseauApporteur];

  return (
    <div className="flex flex-col sm-desktop:flex-row justify-center gap-md w-full py-md my-lg sm-desktop:my-0 mx-md">
      {Object.values(revenue).map((value, index) => (
        <YearlyRevenueItem key={value.commissionSignature} result={value} year={index} />
      ))}
    </div>
  );
};
