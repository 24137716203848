import React from 'react';

import { DatePickerField } from '@travauxlib/shared/src/components/DesignSystem/components/DatePicker/Field';
import { PvDocumentName } from '@travauxlib/shared/src/features/Chantiers/utils/PvDocumentName';
import { ChantierPvType } from '@travauxlib/shared/src/types/api/common/ChantierPvView';
import { required } from '@travauxlib/shared/src/utils/form';
import { parseDateInput } from '@travauxlib/shared/src/utils/time';

export const DateSignatureField: React.FC<{ pvType: ChantierPvType }> = ({ pvType }) => (
  <div>
    <div className="mb-md text-neutral-700 text-b1">
      Quelle était la date de {PvDocumentName[pvType]} ?
    </div>
    <div className="mb-md">
      <DatePickerField
        placeholder="jj/mm/aaaa"
        id="dateSignature"
        name="dateSignature"
        label="Date"
        parse={parseDateInput}
        validate={required}
      />
    </div>
  </div>
);
