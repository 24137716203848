import React from 'react';

import { useField } from 'react-final-form';

import RightChevron from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronRightFilled.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';

type Props = {
  id: string;
  label: string;
  setNextStep: () => void;
  suffix?: string;
};

export const NumberInputQuestion: React.FC<Props> = ({ id, label, setNextStep, suffix }) => {
  const inputField = useField(id);
  const inputValue = inputField.input.value;

  return (
    <div className="flex flex-col">
      <NumberInputField
        className="mb-xl"
        name={id}
        id={id}
        label={label}
        suffix={suffix}
        onKeyPress={event => {
          if (event.key === 'Enter') {
            event.preventDefault();
            return inputValue && setNextStep();
          }
        }}
        autoFocus
      />
      <Button
        className="self-end"
        rightIcon={<RightChevron />}
        onClick={setNextStep}
        disabled={!inputValue}
      >
        Suivant
      </Button>
    </div>
  );
};
