import React, { useState } from 'react';

import classNames from 'classnames';

import Brand from '@travauxlib/shared/src/components/DesignSystem/assets/BrandFilled.svg?react';
import ChevronDown from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronDownFilled.svg?react';
import ChevronUp from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronUpFilled.svg?react';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';

type Props = {
  title: string;
  content: string;
  onClick: () => void;
};

export const ThresholdInfoAccordion: React.FC<Props> = ({ title, content, onClick }) => {
  const [isExpended, setIsExpended] = useState<boolean>(false);
  const Icon = isExpended ? ChevronUp : ChevronDown;

  return (
    <div
      className={classNames(
        'flex gap-xxs px-md border-b bg-neutral-0',
        isExpended ? 'py-xs' : 'py-xxs',
      )}
    >
      <Brand className="text-primary-400 size-lg shrink-0" />
      <div className="flex flex-col gap-xs grow">
        <div className="flex grow items-center justify-between gap-xs text-b1 font-bold text-neutral-800">
          {title}
          <Icon
            className="size-lg shrink-0"
            data-testid="toggle-threshold"
            onClick={() => setIsExpended(previous => !previous)}
          />
        </div>
        {isExpended && (
          <div className="flex flex-col gap-md">
            <div className="text-b2 text-neutral-700">{content}</div>
            <Link className="ml-auto" onClick={onClick} size="lg" inline>
              En savoir plus
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
