import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { PartnerAgencyWithOrganizationAndManager } from 'types';

export const PARTNER_KEY = 'partner';

export const usePartner = (): {
  partner?: PartnerAgencyWithOrganizationAndManager;
  isLoading: boolean;
} => {
  const navigate = useNavigate();
  const { affiliateToken } = useParams();

  const { data, isLoading } = useQuery<{ data: PartnerAgencyWithOrganizationAndManager }>({
    queryKey: [PARTNER_KEY, affiliateToken],
    queryFn: async () => {
      try {
        return await request(`${APP_CONFIG.apiURL}/partners/${affiliateToken}`);
      } catch (err) {
        navigate('/');
        toast.error('Une erreur est survenue pendant le chargement du partenaire');
        throw err;
      }
    },
    staleTime: 10000,
  });

  return { partner: data?.data, isLoading };
};
