import React from 'react';

import { useParams } from 'react-router';

import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { DevisView } from '@travauxlib/shared/src/types/api/client/DevisView';

import { NotFound } from 'features/NotFoundPage';

import { useDevisPro } from '../api/useDevisPro';

export const WithDevisPro = <P extends object>(
  Component: React.ComponentType<P & { devis: DevisView }>,
): React.ComponentType<P> => {
  const Wrapper = (props: P): React.ReactElement => {
    const { token } = useParams();
    const { isLoading, devis } = useDevisPro(token!);
    if (isLoading) {
      return <Loader />;
    }
    if (!devis) {
      return <NotFound />;
    }
    return <Component {...props} devis={devis} />;
  };
  return Wrapper;
};
