import React from 'react';

import {
  Modal,
  ModalContent,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';

import { EstimateurForm } from './EstimateurForm';

import { EstimateurFormValues } from '../types';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  setValues: (values: EstimateurFormValues) => void;
  values?: EstimateurFormValues;
};

export const EstimateurFormModal: React.FC<Props> = ({ isOpen, onClose, setValues, values }) => (
  <Modal isOpen={isOpen} handleClose={onClose} title="Modification des paramètres">
    <ModalContent>
      <EstimateurForm
        initialValues={values}
        onSubmit={values => {
          setValues(values);
          onClose();
        }}
      />
    </ModalContent>
  </Modal>
);
