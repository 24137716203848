import React from 'react';

import classNames from 'classnames';

import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { ColoredCircle } from '@travauxlib/shared/src/components/DesignSystem/components/ColoredCircle';
import { ImgLazy } from '@travauxlib/shared/src/components/Images/ImgLazy';
import { useIsBelowBreakpoint } from '@travauxlib/shared/src/hooks/useIsBelowBreakpoint';

import { ArticleConfig } from '../../types';

export const ArticleCard: React.FC<ArticleConfig> = props => {
  const isSmallDesktop = useIsBelowBreakpoint('md-desktop');
  const { isTipSheet, isWrapped, noBackground, title, content, image, icon, link } = props;
  const imageProps = image?.imageProps;

  const isWrappedClassnames = { 'col-span-2 tablet:col-span-4': isWrapped };
  const linkClassnames = classNames('mt-xs', { 'self-end': link?.position === 'right' });

  if (imageProps) {
    return (
      <div
        className={classNames(
          'col-span-full flex gap-x-md',
          { 'mb-md hidden tablet:block': image.position === 'cover' },
          { 'flex-row-reverse': image.position === 'right' },
          isWrappedClassnames,
          isSmallDesktop ? 'h-full' : 'h-auto',
        )}
      >
        {['left', 'right'].includes(image.position) && (
          <div
            className={classNames(
              'hidden tablet:block',
              { 'basis-[332px] md-desktop:basis-1/3': !image.isLarge },
              { 'basis-1/2 md-desktop:basis-7/12': image.isLarge },
            )}
          >
            {image.withPadding ? (
              <Card
                className="h-full w-full"
                imageVariant="full"
                image={{ ...imageProps, className: '!h-full !object-contain p-xs bg-neutral-0' }}
                withGradient={false}
              />
            ) : (
              <ImgLazy
                sizes="100%"
                url={imageProps.url}
                alt={imageProps.alt}
                className="!h-full w-full rounded object-cover"
              />
            )}
          </div>
        )}
        <Card
          className={classNames('w-full h-full', {
            'flex-1': ['left', 'right'].includes(image.position),
          })}
          imageVariant={
            ['top', 'left', 'right'].includes(image.position)
              ? 'top-full'
              : image.position === 'cover'
                ? 'full'
                : undefined
          }
          image={{
            ...imageProps,
            className: image.isLarge
              ? 'hidden'
              : image.position === 'top'
                ? 'h-[220px] w-full'
                : ['left', 'right'].includes(image.position)
                  ? 'h-[220px] w-full tablet:hidden'
                  : '!h-full',
          }}
          withGradient={false}
          bodyClassNames="grow flex flex-col overflow-auto"
        >
          <div className="mb-xs text-h5 font-bold text-neutral-800">{title}</div>
          <div className="grow text-b2 text-neutral-600 overflow-auto">{content}</div>
          {link?.element && <div className={linkClassnames}>{link.element}</div>}
        </Card>
      </div>
    );
  }

  if (icon && isTipSheet) {
    return (
      <Card
        className={classNames('col-span-full', { 'mb-md': isWrapped }, isWrappedClassnames)}
        bodyClassNames="flex align-start border border-primary rounded-xs"
      >
        <div className="mr-xs h-lg min-w-lg !text-primary">{icon.element}</div>
        <div>
          <div className="text-b1 font-bold text-neutral-800">{title}</div>
          <div className="text-b2 text-neutral-600">
            {content} {link?.element}
          </div>
        </div>
      </Card>
    );
  }

  if (icon) {
    return (
      <Card
        className="col-span-full"
        bodyClassNames={classNames(
          'flex flex-col tablet:flex-row items-center gap-md',
          isWrappedClassnames,
          {
            'tablet:flex-row-reverse': icon.position === 'right',
          },
        )}
      >
        <ColoredCircle size={icon.size} variant="secondary">
          {icon.element}
        </ColoredCircle>
        <div className="flex w-full flex-col">
          <div className="mb-xs text-h5 font-bold text-neutral-800">{title}</div>
          <div className="text-b2 text-neutral-600">{content}</div>
          {link?.element && <div className={linkClassnames}>{link.element}</div>}
        </div>
      </Card>
    );
  }

  // text only, without background
  if (noBackground) {
    return (
      <div className="col-span-full">
        <div className="mb-md text-h5 font-bold text-neutral-800">{title}</div>
        <div className="text-b2 text-neutral-600">{content}</div>
      </div>
    );
  }

  // text only, with background
  return (
    <Card
      className={classNames('col-span-full', { 'mb-md': isWrapped }, isWrappedClassnames)}
      bodyClassNames="flex flex-col"
    >
      <div className="mb-xs text-h5 font-bold text-neutral-800">{title}</div>
      <div className="text-b2 text-neutral-600">{content}</div>
      {link?.element && <div className={linkClassnames}>{link.element}</div>}
    </Card>
  );
};
