import React from 'react';

import classNames from 'classnames';

import { stickyTop } from '../utils/classNames';
import { contentHeightClassName } from '../utils/constants';

export const RightSpaceLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div
    className={classNames(
      'sm-desktop:col-start-10 sm-desktop:col-span-3',
      'flex flex-col sm-desktop:pt-xl',
      stickyTop,
      { 'hidden sm-desktop:flex': !children },
      contentHeightClassName,
    )}
  >
    {children}
  </div>
);
