import React from 'react';

import { ClampedText } from '@travauxlib/shared/src/components/DesignSystem/components/ClampedText';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';

type Props = {
  title: string;
  typeTravaux?: string;
};

export const Title: React.FC<Props> = ({ title, typeTravaux = 'N/A' }) => {
  const isTabletOrMobile = useIsTabletOrMobile();

  return (
    <div className="my-sm">
      {typeTravaux !== 'N/A' && (
        <div className="text-neutral-600 text-b2 font-bold">{typeTravaux}</div>
      )}
      {title && (
        <ClampedText
          className="text-neutral-800 font-bold text-h5"
          maxLines={isTabletOrMobile ? 2 : 1}
          text={title}
          tooltipPosition="bottom"
        />
      )}
    </div>
  );
};
