import React from 'react';

import { DevisFactureCard } from '@travauxlib/shared/src/features/DevisDisplay/components/DevisFactureCard';
import { DevisCustomization } from '@travauxlib/shared/src/types';
import { Prestations } from '@travauxlib/shared/src/types';
import { DevisView } from '@travauxlib/shared/src/types/api/client/DevisView';

import { useCurrentDevis } from 'features/DevisPro/components/CurrentDevisContext';
import { computePrixWithPrestation } from 'utils/computePrixWithPrestations';

import { DevisClientOptions } from './DevisClientOptions';
import { LotRecap } from './LotRecap';
import { MontantsTVA } from './MontantsTVA';
import { Prices } from './Prices';
import { TotalPrices } from './TotalPrices';

export type Props = {
  devis: DevisView;
  className?: string;
  customization?: DevisCustomization;
  isHemea: boolean;
};

export const DevisPrestationsSummary: React.FC<Props> = ({
  devis,
  className,
  customization,
  isHemea,
}) => {
  const { typeSuivi } = useCurrentDevis();
  const getPrixWithPrestations = computePrixWithPrestation(devis, typeSuivi);
  const shouldShowHemeaModalites = isHemea && !devis.proContext?.company.isArchitecte;

  const displayedPrixTotalTTC = shouldShowHemeaModalites
    ? getPrixWithPrestations(devis.prixTotalTTC)
    : devis.prixTotalTTC;
  return (
    <DevisFactureCard
      title="Récapitulatif des lots"
      className={className}
      customization={customization}
    >
      <LotRecap lots={devis.lots} customization={customization} />
      <Prices prestations={devis as unknown as Prestations} />
      <MontantsTVA montantsTVA={devis.montantsTVA} lots={devis.lots} />
      <DevisClientOptions devis={devis} shouldShowHemeaModalites={shouldShowHemeaModalites} />
      <TotalPrices
        displayedPrixTotalTTC={displayedPrixTotalTTC}
        prestations={devis as unknown as Prestations}
      />
    </DevisFactureCard>
  );
};
