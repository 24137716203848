import React from 'react';

import { SuiviView } from '@travauxlib/shared/src/types/api/client/SuiviView';

import { SuiviChantierWrapper } from './components/SuiviChantierWrapper';
import { WithSuiviChantier } from './hocs/WithSuiviChantier';

type Props = {
  suiviChantier: SuiviView;
};

const RawSuiviChantier: React.FC<Props> = ({ suiviChantier }) => (
  <SuiviChantierWrapper suiviChantier={suiviChantier} />
);

export const SuiviChantier = WithSuiviChantier(RawSuiviChantier);
