import { useQuery } from '@tanstack/react-query';

import { GoogleMapsAddress } from '@travauxlib/shared/src/types/api/common/GoogleMapsAddress';
import { request } from '@travauxlib/shared/src/utils/request';

import { useIsCreditDuNord } from '../utils/paths';

const checkIsLocationAddressable = (
  googleMapsAddress?: GoogleMapsAddress,
  isCreditDuNord: boolean = false,
): Promise<{ ok: boolean }> => {
  const requestUrl = `${APP_CONFIG.apiURL}/lead/addressability/check`;
  return request(requestUrl, {
    method: 'POST',
    body: {
      googleMapsAddress,
      addressableProduct: isCreditDuNord ? 'credit_du_nord' : 'leads_hemea',
    },
  });
};

export const ADDRESSABILITY_KEY = 'addressability';

export const useIsLocationAddressable = (
  googleMapsAddress?: GoogleMapsAddress,
): boolean | undefined => {
  const isCreditDuNord = useIsCreditDuNord();

  const { data } = useQuery<{ ok: boolean }>({
    queryKey: [ADDRESSABILITY_KEY, googleMapsAddress?.formatted_address],
    queryFn: () => checkIsLocationAddressable(googleMapsAddress, isCreditDuNord),
    staleTime: Infinity,
  });

  return data?.ok;
};
