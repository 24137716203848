import React from 'react';

import { useNavigate } from 'react-router';

import ChevronRight from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronRightFilled.svg?react';
import PriceBasisDuoColor from '@travauxlib/shared/src/components/DesignSystem/assets/PriceBasisDuoColor.svg?react';
import { ListItem } from '@travauxlib/shared/src/components/DesignSystem/components/ListItem';

export const GoToFournituresListCard: React.FC = () => {
  const navigate = useNavigate();

  return (
    <ListItem
      className="mb-md !rounded-none sm-desktop:!rounded-xxs"
      bodyClassName="py-xs"
      left={{
        type: 'jsx',
        children: <PriceBasisDuoColor className="size-xl" />,
      }}
      middle={{
        title: 'Liste de fournitures',
      }}
      right={{
        type: 'iconButton',
        icon: <ChevronRight />,
        onClick: () => navigate('../fournitures'),
      }}
    />
  );
};
