import React from 'react';

import dayjs from 'dayjs';
import { useParams } from 'react-router';

import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';

import { useGetDevisFactures } from 'features/EspaceClient3/api/useGetDevisFactures';
import { useProject } from 'features/EspaceClient3/api/useProject';

import { EmptyDevisFactures } from './components/EmptyDevisFactures';
import { LastFactures } from './components/LastFactures';

import { MainDevisCard } from '../../components/MainDevis/MainDevisCard';
import { Documents } from '../Documents';
import { FinDeChantier } from '../FinDeChantier';

export const DevisFactures: React.FC = () => {
  const { uuid, proCompanyUuid } = useParams<{ uuid: string; proCompanyUuid: string }>();
  const { devisFactures, isLoading } = useGetDevisFactures(uuid!);
  const { project } = useProject(uuid!);
  const devisFacturesForCompany = devisFactures?.proCompanies.find(
    proCompany => proCompany.uuid === proCompanyUuid,
  );

  if (isLoading) {
    return <Loader />;
  }

  const chantier = project?.chantiers.find(c => c.proCompanyUuid === proCompanyUuid);
  const allFactures = Object.values(devisFacturesForCompany?.devisByProjectUuid || {})
    .flatMap(({ factures }) => factures)
    .sort((f1, f2) => dayjs(f2.sentAt).diff(dayjs(f1.sentAt)));

  return (
    <>
      <div className="flex justify-between my-sm mx-md sm-desktop:mx-0">
        <div className="text-h5 font-bold">Mes devis</div>
        {devisFacturesForCompany && <Link to="./devis">Voir tous mes devis</Link>}
      </div>
      <div className="mb-md">
        {devisFacturesForCompany ? (
          <div className="flex flex-col gap-md">
            <div className="flex flex-col gap-sm mx-md sm-desktop:mx-0">
              {Object.values(devisFacturesForCompany.devisByProjectUuid)
                .sort((devisBlock1, devisBlock2) =>
                  dayjs(devisBlock2.mainDevis.sentAt).diff(dayjs(devisBlock1.mainDevis.sentAt)),
                )
                .map(devisListInProject => (
                  <MainDevisCard
                    key={devisListInProject.mainDevis.token}
                    title={devisListInProject.title}
                    devis={devisListInProject.mainDevis}
                    isArchitecte={devisFacturesForCompany.companyType === 'architecte'}
                  />
                ))}
            </div>
            {chantier && <FinDeChantier chantier={chantier} />}
            {allFactures.length > 0 && (
              <div>
                <LastFactures factures={allFactures} />
              </div>
            )}
          </div>
        ) : (
          <>
            <EmptyDevisFactures />
            {chantier && <FinDeChantier chantier={chantier} />}
          </>
        )}
      </div>
      <Documents />
    </>
  );
};
