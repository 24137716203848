import React from 'react';

import CheckSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/CheckSymbolFilled.svg?react';
import CrossSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/CrossSymbolFilled.svg?react';
import Gift from '@travauxlib/shared/src/components/DesignSystem/assets/Gift.svg?react';
import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { LotIcon } from '@travauxlib/shared/src/features/DevisDisplay/components/LotIcon';
import { SuiviLotContainer } from '@travauxlib/shared/src/features/SuiviChantier/components/SuiviLotContainer';
import { LigneStatus, SuiviLigneStatus } from '@travauxlib/shared/src/types';

import { AvancementInfo } from './AvancementInfo';

import { SuiviLot } from '../../types';

type Props = {
  suiviLots: SuiviLot[];
};

export const SuiviClientReadOnlyByLots: React.FC<Props> = ({ suiviLots }) => (
  <div className="flex flex-col sm-desktop:gap-lg gap-md mb-md sm-desktop:mb-3xl">
    {suiviLots.map(lot => (
      <SuiviLotContainer
        key={lot.uuid}
        label={lot.label}
        Icon={() => <LotIcon lotLabel={lot.label} />}
        lignes={lot.lignes}
        validateActionOrTag={ligne => (
          <>
            {ligne.suiviStatus === SuiviLigneStatus.Agreed && (
              <Tag size="md" variant="success" icon={<CheckSymbol />} label="Validé" />
            )}
            {ligne.suiviStatus === SuiviLigneStatus.Rejected && (
              <Tag size="md" variant="error" icon={<CrossSymbol />} label="Modifié" />
            )}
          </>
        )}
        ligneContent={ligne => (
          <>
            {ligne.status === LigneStatus.Free && (
              <Tag label="Offert" variant="success" size="sm" icon={<Gift />} />
            )}
            <AvancementInfo
              ligne={ligne}
              namePrefix={`${ligne.lotUuid}.lignes[${ligne.indexInLot}]`}
            />
          </>
        )}
      />
    ))}
  </div>
);
