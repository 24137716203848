import React from 'react';

import classNames from 'classnames';

import { MultilineParagraph } from '@travauxlib/shared/src/components/MultilineParagraph';
import { DevisDisplay } from '@travauxlib/shared/src/features/DevisDisplay';
import { DevisCustomization } from '@travauxlib/shared/src/types';
import { ManualAddress } from '@travauxlib/shared/src/types/api/common/ManualAddress';

import { useIsAuthor } from 'hooks/useIsAuthor';
import { Facture } from 'types';

import { FactureActions } from './FactureActions';
import { PaidFacture } from './FacturePayee';

type Props = {
  facture: Facture;
  header: React.ReactNode;
  children?: React.ReactNode;
  customizationData?: DevisCustomization;
  chantierAddress?: ManualAddress;
  customerName: string;
  proCompanyName: string;
  isPrint?: boolean;
};

export const FactureLayout: React.FC<Props> = ({
  facture,
  header,
  children,
  customizationData,
  chantierAddress,
  customerName,
  proCompanyName,
  isPrint,
}) => {
  const {
    token,
    title,
    lots,
    headerText,
    introductionLetter,
    montantRestantAPayer,
    showFournitures,
  } = facture;

  const isAuthor = useIsAuthor();
  const printFactureUrl = `${APP_CONFIG.apiURL}/client/factures/${token}/pdf`;

  const isFacturePaid = montantRestantAPayer === 0;

  return (
    <div className={classNames({ 'container-sm py-lg sm-desktop:pt-xxl': !isPrint })}>
      {header}
      {!isPrint && (
        <FactureActions
          printFactureUrl={printFactureUrl}
          facture={facture}
          isFacturePaid={isFacturePaid}
          isAuthor={isAuthor}
          proContext={facture.proContext}
        />
      )}
      <h1 className={classNames(customizationData?.titleFontStyle)}>{title}</h1>
      {chantierAddress && (
        <div className="mb-lg">
          {chantierAddress.address} {chantierAddress.postalCode} {chantierAddress.city}
        </div>
      )}
      <div className="mb-3xl flex flex-wrap sm-desktop:flex-nowrap gap-lg">
        <div className={classNames('basis-full', { 'sm-desktop:basis-7/12': isFacturePaid })}>
          <MultilineParagraph text={introductionLetter} />{' '}
        </div>
        {isFacturePaid && (
          <div className="basis-full text-center sm-desktop:basis-5/12">
            <PaidFacture
              className={classNames({
                '!fixed !w-[400px] left-1/3 !top-[350px] text-center translate-y-[-200px] rotate-[-25deg]':
                  isPrint,
              })}
            >
              Facture Payée
            </PaidFacture>
          </div>
        )}
      </div>
      <DevisDisplay
        isAuthor={isAuthor}
        lots={lots}
        headerText={headerText}
        locations={[]}
        showOnlyFacturable
        customization={customizationData}
        customerName={customerName}
        proCompanyName={proCompanyName}
        showFournitures={showFournitures}
        showTotalTravaux={facture.isCloture && !facture.isSituation}
        token={facture.token}
      />
      {children}
    </div>
  );
};
