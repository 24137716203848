import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router';

import { request } from '@travauxlib/shared/src/utils/request';

import { Estimation } from 'features/Estimateur/types';

const ESTIMATIONS_KEY = 'estimations';

type SubmitEstimationPayload = {
  estimation: Estimation;
  preventRedirect?: boolean;
};

const handleSubmitEstimation = async (
  payload: SubmitEstimationPayload,
): Promise<SubmitEstimationPayload> => ({
  estimation: await request(`${APP_CONFIG.apiURL}/client/client-estimateur/estimation`, {
    method: 'POST',
    body: { ...payload.estimation, ...payload.estimation.context },
  }),
  preventRedirect: payload.preventRedirect,
});

export const useEstimations = (): {
  isLoading: boolean;
  estimations?: Estimation[];
  submitEstimation: typeof handleSubmitEstimation;
  deleteEstimation: (estimationUuid: string) => Promise<void>;
} => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { isLoading, data } = useQuery<{ data: Estimation[] }>({
    queryKey: [ESTIMATIONS_KEY],
    queryFn: () => request(`${APP_CONFIG.apiURL}/client/client-estimateur/estimation`),
    staleTime: Infinity,
  });

  const { mutateAsync: submitEstimation } = useMutation({
    mutationFn: handleSubmitEstimation,
    onSuccess: ({ estimation, preventRedirect }: SubmitEstimationPayload) => {
      queryClient.invalidateQueries({ queryKey: [ESTIMATIONS_KEY] });
      if (!preventRedirect) {
        const url = `/client/estimateur/${estimation.uuid}/result`;
        navigate(url);
      }
    },
  });

  const { mutateAsync: deleteEstimation } = useMutation({
    mutationFn: (estimationUuid: string) =>
      request(`${APP_CONFIG.apiURL}/client/client-estimateur/estimation/${estimationUuid}`, {
        method: 'DELETE',
      }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [ESTIMATIONS_KEY] }),
  });

  return {
    isLoading,
    estimations: data?.data,
    submitEstimation,
    deleteEstimation,
  };
};
