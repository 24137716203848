import React, { useState } from 'react';

import classNames from 'classnames';
import { Form } from 'react-final-form';

import { AutoSubmitForm } from '@travauxlib/shared/src/components/AutoSubmitForm';
import ChevronDown from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronDownFilled.svg?react';
import ChevronUp from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronUpFilled.svg?react';
import InfoCircleFilled from '@travauxlib/shared/src/components/DesignSystem/assets/InfoCircleFilled.svg?react';
import VerifiedFilled from '@travauxlib/shared/src/components/DesignSystem/assets/VerifiedFilled.svg?react';
import { Banner } from '@travauxlib/shared/src/components/DesignSystem/components/Banner';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { Checkbox } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox';
import { LayoutGrid } from '@travauxlib/shared/src/components/DesignSystem/components/Layout';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { Header } from '@travauxlib/shared/src/features/SuiviChantier/components/Header';
import {
  FormData,
  SuiviChantierClient,
  SuiviChantierComputed,
  SuiviLot,
} from '@travauxlib/shared/src/features/SuiviChantier/types';
import {
  getAvancementForLots,
  parseFormDataToGetLots,
} from '@travauxlib/shared/src/features/SuiviChantier/utils';
import { computeSuiviChantierAmounts } from '@travauxlib/shared/src/features/SuiviChantier/utils/computeSuiviChantierAmounts';

import { ConfirmationSuiviChantierModal } from './components/ConfirmationSuiviChantierModal';
import { MainSuivi } from './components/MainSuivi';

import { SuiviFooter } from '../../components/SuiviFooter';
import { ThresholdInfo } from '../../components/ThresholdInfo';
import { useOpenThresholdModal } from '../../hooks/useOpenThresholdModal';
import { computeThreshold } from '../../utils/threshold';

type Props = {
  suiviChantier: SuiviChantierClient;
  initialValues: FormData;
  initialValuesCreatedByProStep?: FormData;
  handleSubmit: ({
    lots,
    montantALibererTTC,
    montantGele,
    globalPourcentageAvancement,
    initialMontantDemande,
  }: {
    lots: SuiviLot[];
    montantALibererTTC: number;
    montantGele: number;
    globalPourcentageAvancement: number;
    initialMontantDemande: number;
  }) => void;
  onBack: () => void;
  intervenant: 'tp' | 'client';
  onSaveDraft?: (formValues: FormData) => Promise<void>;
  soldeCompteSequestre?: number;
  trackingEnSavoirPlus?: () => void;
};

export const SuiviChantier: React.FC<Props> = ({
  suiviChantier,
  initialValues,
  initialValuesCreatedByProStep,
  handleSubmit,
  onBack,
  intervenant,
  onSaveDraft,
  soldeCompteSequestre,
  trackingEnSavoirPlus,
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const openThresholdModal = useOpenThresholdModal();

  const {
    proCompanyName,
    devisList,
    montantRemise,
    montantDejaPaye,
    hasTPIntervenant,
    totalMontantTVA,
    receptionChantierThreshold,
    leveeReservesThreshold,
    isReceptionChantierPvValidated,
    isLeveeReservesPvValidated,
    hasReserves,
  } = suiviChantier;

  const remiseMapByDevisToken = devisList.reduce(
    (acc, devis) => ({
      ...acc,
      [devis.token]: devis.remises,
    }),
    {},
  );

  const suiviChantierAmounts = (lots: FormData, currentThreshold?: number): SuiviChantierComputed =>
    computeSuiviChantierAmounts({
      lots: parseFormDataToGetLots(lots),
      remisesMapByDevisToken: remiseMapByDevisToken,
      montantRemise: montantRemise,
      montantDejaPaye: montantDejaPaye,
      totalMontantTVA: totalMontantTVA,
      threshold: currentThreshold,
    });

  const { currentThreshold: initialCurrentThreshold } = computeThreshold({
    lots: parseFormDataToGetLots(initialValuesCreatedByProStep || initialValues),
    receptionChantierThreshold,
    leveeReservesThreshold,
    isReceptionChantierPvValidated,
    isLeveeReservesPvValidated,
    hasReserves,
    isPro: false,
  });

  const initialComputed = suiviChantierAmounts(
    initialValuesCreatedByProStep || initialValues,
    initialCurrentThreshold,
  );

  return (
    <Form<FormData>
      onSubmit={async values => {
        const lots = parseFormDataToGetLots(values);
        const { currentThreshold } = computeThreshold({
          lots: parseFormDataToGetLots(values),
          receptionChantierThreshold,
          leveeReservesThreshold,
          isReceptionChantierPvValidated,
          isLeveeReservesPvValidated,
          hasReserves,
          isPro: false,
        });
        const { montantALibererTTC, montantGele } = suiviChantierAmounts(values, currentThreshold);

        return handleSubmit({
          lots,
          montantALibererTTC,
          montantGele,
          globalPourcentageAvancement: getAvancementForLots(lots),
          initialMontantDemande: initialComputed.montantALibererTTC + initialComputed.montantGele,
        });
      }}
      initialValues={initialValues}
    >
      {({ handleSubmit, invalid, values }) => {
        const { currentThreshold, thresholdInfo } = computeThreshold({
          lots: parseFormDataToGetLots(values),
          receptionChantierThreshold,
          leveeReservesThreshold,
          isReceptionChantierPvValidated,
          isLeveeReservesPvValidated,
          hasReserves,
          isPro: false,
        });

        const isSoldeCompteSequestreNotEnough =
          soldeCompteSequestre !== undefined &&
          suiviChantierAmounts(values, currentThreshold).montantALibererTTC > soldeCompteSequestre;

        return (
          <form className="flex grow flex-col overflow-auto" onSubmit={handleSubmit}>
            {onSaveDraft && <AutoSubmitForm debounce={1500} onSubmit={() => onSaveDraft(values)} />}
            <Header
              onClick={onBack}
              title="Chantier réalisé par"
              subTitle={proCompanyName}
              pourcentageAvancement={getAvancementForLots(parseFormDataToGetLots(values))}
            />
            {isSoldeCompteSequestreNotEnough && (
              <Banner
                level="warning"
                title="Vous n'avez pas assez d'argent sur votre compte séquestre, veuillez provisionner celui-ci pour continuer."
              />
            )}
            <LayoutGrid className="overflow-auto h-full !mx-0 grid-rows-[max-content]">
              <div className="col-span-full sm-desktop:col-start-2 md-desktop:col-start-3 m-md sm-desktop:my-xl sm-desktop:mx-0">
                <div className="text-h4 sm-desktop:text-h3 font-bold text-neutral-800">
                  Avancement de chantier
                </div>
                <div className="text-h5 sm-desktop:text-h4 font-medium text-neutral-700">
                  {hasTPIntervenant
                    ? 'Veuillez confirmer les prestations vérifiées par le travaux planner'
                    : "Veuillez vérifier l'état d'avancement des prestations"}
                </div>
              </div>
              <div className="col-span-full sm-desktop:col-start-2 sm-desktop:col-span-6 md-desktop:col-start-3 md-desktop:col-span-5">
                <MainSuivi />
              </div>
              <SuiviFooter
                lots={parseFormDataToGetLots(values)}
                computed={suiviChantierAmounts(values, currentThreshold)}
                action={{
                  label: "Valider l'avancement",
                  disabled:
                    invalid || (hasTPIntervenant && !isChecked) || isSoldeCompteSequestreNotEnough,
                  disabledMessageTooltip: isSoldeCompteSequestreNotEnough
                    ? "Vous n'avez pas assez d'argent sur votre compte séquestre"
                    : 'Vous devez d’abord confirmer l’avancement de toutes les prestations avant de pouvoir valider la libération',
                  onClick: () => setShowConfirmationModal(true),
                }}
                intervenant={intervenant}
                thresholdInfo={
                  thresholdInfo && (
                    <ThresholdInfo
                      title={`Paiements plafonnés à ${currentThreshold}%`}
                      content={thresholdInfo.thresholdCard}
                      onClick={() => {
                        openThresholdModal({
                          leveeReservesThreshold: leveeReservesThreshold,
                          receptionChantierThreshold: receptionChantierThreshold,
                          isForPro: false,
                        });
                        trackingEnSavoirPlus?.();
                      }}
                    />
                  )
                }
              >
                {({ showDetails, setShowDetails }) => {
                  const numberOfPrestationsToCheck = parseFormDataToGetLots(values)
                    .flatMap(lot => lot.lignes)
                    .filter(ligne => ligne.hasChanged && ligne.suiviStatus === undefined).length;
                  const pluralSuffix = numberOfPrestationsToCheck > 1 ? 's' : '';

                  return (
                    <div>
                      {hasTPIntervenant ? (
                        <Checkbox
                          id="confirmPrestationsAreVerified"
                          label="En cochant cette case, je confirme avoir vérifié les avancements de toutes les prestations"
                          onChange={setIsChecked}
                          checked={isChecked}
                        />
                      ) : (
                        <>
                          {intervenant === 'tp' && (
                            <div className="flex items-center justify-between text-h5 font-bold mb-xs">
                              <div className="flex items-center gap-xxs">
                                Montant à libérer
                                <IconButton
                                  className="inline-block sm-desktop:hidden"
                                  dataTestId="show-details"
                                  onClick={() => setShowDetails(!showDetails)}
                                >
                                  {showDetails ? <ChevronUp /> : <ChevronDown />}
                                </IconButton>
                              </div>
                              <EURCurrency
                                className={classNames({
                                  'text-warning-800':
                                    suiviChantierAmounts(values, currentThreshold)
                                      .montantALibererTTC <= 0,
                                })}
                                amount={
                                  suiviChantierAmounts(values, currentThreshold).montantALibererTTC
                                }
                              />
                            </div>
                          )}
                          <div className="flex items-center gap-xxs">
                            {numberOfPrestationsToCheck > 0 ? (
                              <div className="text-info h-lg w-lg flex items-center">
                                <InfoCircleFilled />
                              </div>
                            ) : (
                              <div className="text-success h-lg w-lg flex items-center">
                                <VerifiedFilled />
                              </div>
                            )}
                            <div className="text-b1 text-neutral-800 font-bold">
                              {numberOfPrestationsToCheck} prestation{pluralSuffix}{' '}
                            </div>{' '}
                            restante{pluralSuffix} à vérifier
                          </div>
                        </>
                      )}
                    </div>
                  );
                }}
              </SuiviFooter>
              <ConfirmationSuiviChantierModal
                lots={parseFormDataToGetLots(values)}
                isOpen={showConfirmationModal}
                handleClose={() => setShowConfirmationModal(false)}
                proCompanyName={proCompanyName}
                currentThreshold={currentThreshold}
                computed={suiviChantierAmounts(values, currentThreshold)}
                initialComputed={initialComputed}
              />
            </LayoutGrid>
          </form>
        );
      }}
    </Form>
  );
};
