import React from 'react';

import { FormSpy } from 'react-final-form';

import { ButtonGroupField } from '@travauxlib/shared/src/components/DesignSystem/components/ButtonGroup/Field';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { FormLabel } from '@travauxlib/shared/src/components/FormLabel';
import { PlacesAutocompleteField } from '@travauxlib/shared/src/components/PlacesAutocomplete/Field';
import { GoogleMapsAddress } from '@travauxlib/shared/src/types/api/common/GoogleMapsAddress';
import { gammeOptions } from '@travauxlib/shared/src/utils/constants';
import { required, requiredValidGoogleMapAddress } from '@travauxlib/shared/src/utils/form';

import { useIsEstimateurClient, useIsEstimateurPartner } from 'features/Estimateur/utils/paths';

const logementTypeOptions = [
  {
    label: 'Maison',
    value: 'maison',
  },
  {
    label: 'Appartement',
    value: 'appartement',
  },
];

type Props = {
  valid: boolean;
  formattedAddress?: string;
  setUserAddress?: (address: GoogleMapsAddress) => void;
};

export const SelectTypeDeBien: React.FC<Props> = ({ valid, formattedAddress, setUserAddress }) => {
  const isEstimateurPartner = useIsEstimateurPartner();
  const isEstimateurClient = useIsEstimateurClient();

  return (
    <>
      <div className="mb-md">
        <h2 className="mb-lg">Votre projet de rénovation</h2>
        {isEstimateurClient && (
          <div className="mb-md">
            <InputField label="Titre" autoFocus id="title" name="title" />
          </div>
        )}
        {!isEstimateurPartner && (
          <div className="mb-md">
            <PlacesAutocompleteField
              initialInputValue={formattedAddress}
              label="Adresse du bien à rénover"
              id="estimation-address"
              name="googleMapsAddress"
              validate={requiredValidGoogleMapAddress('postal_code')}
              googleApiKey={APP_CONFIG.googleApiKey}
            />
            <FormSpy
              subscription={{ values: true }}
              onChange={({ values }) =>
                window.setTimeout(() => setUserAddress?.(values.googleMapsAddress))
              }
            />
          </div>
        )}
        <div className="mb-md sm-desktop:w-1/2">
          <FormLabel label="Type de bien à rénover" />
          <ButtonGroupField
            fullwidth
            name="typeLogement"
            options={logementTypeOptions}
            validate={required}
          />
        </div>
        <div>
          <FormLabel label="Niveau de gamme de matériaux et finitions (optionnel)" />
          <ButtonGroupField name="defaultGamme" fullwidth options={gammeOptions} />
        </div>

        <div className="text-right mt-xl">
          <Button disabled={!valid} type="submit">
            Suivant
          </Button>
        </div>
      </div>
    </>
  );
};
