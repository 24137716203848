import { useQuery } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { ProCompaniesWithDevisFacturesView } from '@travauxlib/shared/src/types/api/client/ProCompaniesWithDevisFacturesView';
import { request } from '@travauxlib/shared/src/utils/request';

export const DOCUMENTS_KEY = 'documents';

export const useGetDevisFactures = (
  projectUuid: string,
): { devisFactures?: ProCompaniesWithDevisFacturesView; isLoading: boolean } => {
  const { data, isLoading } = useQuery<ProCompaniesWithDevisFacturesView>({
    queryKey: [DOCUMENTS_KEY],
    queryFn: async () => {
      try {
        const requestURL = `${APP_CONFIG.apiURL}/client/projects/${projectUuid}/devis-factures`;
        return await request(requestURL);
      } catch (err) {
        toast.error('Impossible de récupérer les documents');
        throw err;
      }
    },
  });

  return { devisFactures: data || undefined, isLoading };
};
