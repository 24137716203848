import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import {
  AdminEventsProperties,
  AppFeatures,
  PvLeveeReservesProperties,
  TrackingEvents,
} from '@travauxlib/shared/src/utils/tracking';

class PvLeveeReservesClass extends Tracker<AppFeatures> {
  onValidated(properties: {
    [AdminEventsProperties.ProjetUUID]: string;
    [PvLeveeReservesProperties.NbOfDaysSinceReception]: number;
  }): void {
    this.sendTracking({
      event: TrackingEvents.Validated,
      properties,
    });
  }
}

export const PvLeveeReserves = new PvLeveeReservesClass(AppFeatures.PvLeveeReserves);
