import React from 'react';

import { DevisView } from '@travauxlib/shared/src/types/api/client/DevisView';

import { LegacyServicesOptionnels } from './components/LegacyServicesOptionnels';
import { OfferDetails } from './components/OfferDetails';
import { ServicesHemea } from './components/ServicesHemea';

type Props = {
  devis: DevisView;
  isReadOnly: boolean;
};

export const ModalitesHemea: React.FC<Props> = ({ devis, isReadOnly }) => (
  <>
    {devis.prixTotalTTC >= 0 && (
      <ServicesHemea
        prixServiceHemeaTTC={devis.modalitesHemea.prixServiceHemeaTTC}
        tauxServiceHemea={devis.modalitesHemea.tauxServiceHemea}
        montantProtectionHemea={devis.modalitesHemea.montantProtectionHemea}
        hasServiceHemeaIncluded={devis.modalitesHemea.hasServiceHemeaIncluded}
      />
    )}
    {devis.modalitesHemea.hasServiceHemeaIncluded ? (
      <OfferDetails
        selectedTypeSuivi={devis.modalitesHemea.defaultTypeSuivi}
        prixTravauxPlannerTTC={devis.modalitesHemea.prixTravauxPlannerTTC}
        tauxTravauxPlanner={devis.modalitesHemea.tauxTravauxPlanner}
        isTS={!!devis.proContext?.isTS}
        hasBeenSigned={!!devis.proContext?.dateSignature}
      />
    ) : (
      <LegacyServicesOptionnels isReadOnly={isReadOnly} devis={devis} />
    )}
  </>
);
