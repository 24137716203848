import dayjs from 'dayjs';
import jsCookie from 'js-cookie';

import { Cookie, defaultCookieAttributes, sessionCookieAttributes } from './constants';

export const extractGclidQueryString = (qs: string): string | null => {
  const matched = qs.match(/(&|\?)gclid=(.+?)(&.*|$)/);
  return matched && matched.length === 4 ? decodeURIComponent(matched[2]) : null;
};

const setAnalyticsCookies = (): void => {
  const cookieIsoDate = dayjs().toISOString();

  const deviceFirstPageCookie = jsCookie.get(Cookie.DeviceFirstPage);
  const deviceFirstPageReferrerCookie = jsCookie.get(Cookie.DeviceFirstPageReferrer);
  const deviceFirstPageDateCookie = jsCookie.get(Cookie.DeviceFirstPageDate);

  jsCookie.set(
    Cookie.DeviceFirstPage,
    deviceFirstPageCookie || window.location.href,
    defaultCookieAttributes,
  );
  jsCookie.set(
    Cookie.DeviceFirstPageReferrer,
    deviceFirstPageReferrerCookie || document.referrer,
    defaultCookieAttributes,
  );
  jsCookie.set(
    Cookie.DeviceFirstPageDate,
    deviceFirstPageDateCookie || cookieIsoDate,
    defaultCookieAttributes,
  );

  if (!jsCookie.get(Cookie.SessionFirstPage)) {
    jsCookie.set(Cookie.SessionFirstPage, window.location.href, sessionCookieAttributes);
    jsCookie.set(Cookie.SessionFirstPageReferrer, document.referrer, sessionCookieAttributes);
    jsCookie.set(Cookie.SessionFirstPageDate, cookieIsoDate, sessionCookieAttributes);
  }

  const conversionButton = new URLSearchParams(window.location.search).get('customer_need');

  if (conversionButton) {
    jsCookie.set(Cookie.ConversionButton, conversionButton, defaultCookieAttributes);
  }
};

const setAdCookie = (): void => {
  const gclidsCookie = jsCookie.get(Cookie.Gclids);
  const cglids = gclidsCookie ? gclidsCookie.split(/\s*,\s*/) : [];
  const currentGclid = extractGclidQueryString(window.location.search);

  const updatedGclid =
    currentGclid && !cglids.includes(currentGclid) ? [...cglids, currentGclid] : cglids;
  jsCookie.set(Cookie.Gclids, updatedGclid.join(','), defaultCookieAttributes);
};

export const initAcquisitionCookies = ({
  isAdConsentGranted,
}: {
  isAdConsentGranted: boolean;
}): void => {
  setAnalyticsCookies();
  if (isAdConsentGranted) {
    setAdCookie();
  }
};
