import React, { ReactNode } from 'react';

import classNames from 'classnames';

import { allIcons } from '@travauxlib/shared/src/features/DevisDisplay/components/LotIcon';

import { Category } from 'features/Estimateur/types';

import { Chauffage } from '../components/Chauffage';
import { Cloisonnement } from '../components/Cloisonnement';
import { Cuisine } from '../components/Cuisine';
import { Elec } from '../components/Elec';
import { Fenetres } from '../components/Fenetres';
import { Menuiseries } from '../components/Menuiseries';
import { Peinture } from '../components/Peinture';
import { Sdb } from '../components/Sdb';
import { Sols } from '../components/Sols';

export const Container: React.FC<{
  children: ReactNode;
  onClick?: () => void;
  className: string;
  withUnderscoreOnHover?: boolean;
  hideBorder?: boolean;
}> = ({ children, onClick, className, withUnderscoreOnHover, hideBorder }) => (
  <div
    className={classNames(
      {
        'border border-light rounded rounded-lg': !hideBorder,
        'hover:underline': withUnderscoreOnHover,
      },
      'text-black pl-sm bg-white',
      className,
    )}
    onClick={onClick}
  >
    {children}
  </div>
);

const categoryIconStyle = {
  zIndex: 10,
  top: '0.8rem',
  display: 'inline-block',
  flexShrink: 0,
  left: '8px',
  height: '1.5rem',
  width: '1.3rem',
  marginRight: '0.5rem',
};

export const CategoryWrapper: React.FC<{
  category?: Category;
  hideBorder?: boolean;
  className?: string;
  onClick?: () => void;
  withUnderscoreOnHover?: boolean;
  children: React.ReactNode;
}> = ({ category, className = '', hideBorder, onClick, withUnderscoreOnHover, children }) => {
  const Icon = category ? category.icon : () => null;
  return (
    <Container
      withUnderscoreOnHover={withUnderscoreOnHover}
      onClick={onClick}
      className={className}
      hideBorder={hideBorder}
    >
      <Icon
        //eslint-disable-next-line
        style={categoryIconStyle}
      />
      {children}
    </Container>
  );
};

export const categories: Category[] = [
  {
    value: 'cloisonnement',
    label: 'Murs et cloisonnement',
    Component: Cloisonnement,
    getInitialValues: () => ({
      mursADemolir: [],
      mursARajouter: [],
    }),
    icon: allIcons.platrerie_iti,
  },
  {
    value: 'sols',
    label: 'Sols',
    Component: Sols,
    icon: allIcons.revetement_sol,
  },
  {
    value: 'salle_de_bain',
    label: 'Salle de bain / WC',
    Component: Sdb,
    getInitialValues: ({ context: { locations } }) => ({
      location: [
        ...locations.filter(l => ["Salle d'eau", 'Salle de bain', 'WC'].includes(l.typeLocation)),
        locations[0],
      ][0].uuid,
    }),
    icon: allIcons.plomberie,
  },
  {
    value: 'cuisine',
    label: 'Cuisine',
    Component: Cuisine,
    getInitialValues: ({ context: { locations } }) => ({
      credenceAPoser: 'yes',
      location: [...locations.filter(l => l.typeLocation === 'Cuisine'), locations[0]][0].uuid,
    }),
    getDisabledReason: ({ context: { locations } }) =>
      locations.find(l =>
        ['Cuisine', 'Séjour', 'Salon', 'Pièce principale'].includes(l.typeLocation),
      ) === undefined
        ? 'La configuration de la cuisine est désactive, veuillez sélectionner une cuisine.'
        : undefined,
    icon: allIcons.cuisine,
  },
  {
    value: 'electricite',
    label: 'Électricité',
    Component: Elec,
    icon: allIcons.electricite,
  },
  {
    value: 'chauffage',
    label: 'Chauffage',
    Component: Chauffage,
    icon: allIcons.chauffage_ventilation_climatisation,
  },
  {
    value: 'portes_et_fenetres',
    label: 'Portes et fenêtres',
    Component: Fenetres,
    getInitialValues: () => ({
      petitesFenetres: 0,
      moyennesFenetres: 0,
      grandesFenetres: 0,
      voletsRoulantsFenetres: 0,
      grandeHauteurAndPortesFenetres: 0,
      voletsRoulantsPortesFenetres: 0,
      baiesvitrees: 0,
      voletsRoulantsBaiesVitrees: 0,
      porteEntree: {},
      portesInterieur: 0,
      portesInterieurCoulissantes: 0,
      verrieres: [],
    }),
    icon: allIcons.menuiseries_exterieures,
  },
  {
    value: 'peinture',
    label: 'Peinture',
    Component: Peinture,
    getInitialValues: ({ context: { locations } }) => ({
      rooms: locations.map(l => l.label),
    }),
    icon: allIcons.peinture,
  },
  {
    value: 'menuiseries',
    label: 'Menuiseries',
    Component: Menuiseries,
    icon: allIcons.menuiseries,
  },
];
